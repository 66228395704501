import React, { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// hearder, footer, modalsearch, modalmenu, scrollup
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'

function Layout(props) {
  const [isLogin, setIsLogin] = useState(false)
  const location = useLocation()

  const pageId = useMemo(() => {
    if (!location)
      return document.location.pathname === '/'
        ? 'home'
        : document.location.pathname

    return location.pathname === '/' ? 'home' : location.pathname.substr(1)
  }, [location.pathname])

  // Note: Snackbar (Alert) should be here and passed through props at this point, since all the props are passed down when something nested requires it.
  // I am sure there is a better way, just noting the case here as a way to have it "global" which could be in a "Store" with a better global state control of course.

  return (
    <div className="main" id={`page-${pageId}`}>
      <Header
        {...props}
        onLogin={() => setIsLogin(true)}
        onLogout={() => setIsLogin(false)}
        isLogin={isLogin}
      />
      {props.children}
      <Footer />
      <ModalSearch />
      <Scrollup />
    </div>
  )
}

export default Layout
