import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'

import NFT from '../Item/NftActivity'

import './activity.scss'

const Activity = (props: {
  author?: any
  displayFilters?: boolean
  pageLimit?: number
}) => {
  const { search } = useLocation()
  const history = useHistory()

  const filterParam = new URLSearchParams(search)?.get('filter')

  const [filter, setFilter] = useState(getFilterByName(filterParam))
  const [filterKey, setFilterKey] = useState(filterParam ?? 'All')
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(props?.pageLimit ?? 10)
  const [noItems, setNoItems] = useState(false)
  const filterItems = [
    { title: 'All' },
    { title: 'Mint' },
    { title: 'Listing' },
    { title: 'Delisting' },
    { title: 'Auction' },
    { title: 'Bids' },
    { title: 'Purchase' },
  ]

  useEffect(() => {
    setLoading(true)

    let query = `/activities?filter=${filter}&page=${page}&pageLimit=${pageLimit}`

    if (props?.author) query += `author=${props?.author}`

    axios
      .get(query)
      .then((result) => {
        if (!result.data.activities) {
          setItems([])
          setNoItems(true)
          setLoading(false)
          return
        }

        if (result.data.final) setNoItems(true)

        if (page === 1) setItems(result.data.activities)
        else setItems(items.concat(result.data.activities))

        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setNoItems(true)
      })
  }, [filter, page])

  function getFilterByName(filterKey: string | null) {
    let filter = [-1]

    switch (filterKey?.toLowerCase()) {
      case 'mint':
        filter = [0]
        break
      case 'listing':
        filter = [1]
        break
      case 'delisting':
        filter = [2]
        break
      case 'auction':
        filter = [4, 5]
        break
      case 'bids':
        filter = [7, 8, 9]
        break
      case 'purchase':
        filter = [3, 6]
        break
    }

    return filter
  }

  const changeFilter = (filterKey: string) => {
    const filter = getFilterByName(filterKey)

    setPage(1)
    setItems([])
    setFilter(filter)
    setFilterKey(filterKey)
    history.push({ search: `?filter=${filterKey}` })
  }

  function loadMore() {
    if (!loading) {
      setPage(page + 1)
    }
  }

  return (
    <section id="activities" className="activity-area">
      <div className="container">
        <div className="activities">
          {props?.displayFilters !== false && (
            <div className="filters mb-4">
              <h2 className="title my-0 mb-2">Filters</h2>
              <span className="widget-content">
                <span className="widget-content filter-widget-items">
                  {filterItems.map((item, idx) => (
                    <button
                      key={`fd_${idx}`}
                      className={`badge tag ${item.title === filterKey ? 'active' : ''}`}
                      onClick={() => changeFilter(item.title)}
                    >
                      {item.title}
                    </button>
                  ))}
                </span>
              </span>
            </div>
          )}

          <div className="nft-activity split">
            {items.map((item: { [key: string]: any }, index) => (
              <NFT key={index} data={item} displayOwner={false} />
            ))}
          </div>

          {!noItems && (
            <button
              onClick={() => loadMore()}
              className="load-more btn branded mt-3 mt-sm-4 mx-auto d-block"
              style={{ gridColumn: '1/-1' }}
            >
              {loading ? 'Loading...' : 'Load more'}
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Activity
