import type { Actions } from '@web3-react/types'

import axios from 'axios'
import { JsonRpcSigner } from 'ethers'
import { initializeConnector, Web3ReactHooks } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'

import { APP_KEYS, CHAIN_KEYS, NETWORK_ID } from './constants'
import { getUser, loginUser } from '../context/authContext'
import { JoyIdConnector } from './JoyIdConnector'

import { ReactComponent as MetamaskIcon } from '../assets/img/wallets/metamask.svg'
import { ReactComponent as MetamaskIconBadge } from '../assets/img/wallets/metamask-icon.svg'
import { ReactComponent as JoyIdIcon } from '../assets/img/wallets/joyid-passkey.svg'
import { ReactComponent as JoyIdIconBadge } from '../assets/img/wallets/joyid-passkey-mini.svg'

export const CONNECTOR_STORAGE_KEY = 'iNation:walletConnection'

const [metaMaskConnection, metamaskHooks] = initializeConnector<MetaMask>(
  (actions: Actions) => new MetaMask({ actions }),
)
const [joyIdConnection, joyIdHooks] = initializeConnector<JoyIdConnector>(
  (actions: Actions) => new JoyIdConnector({ actions }),
)

export interface IConnector {
  icon: React.JSX.Element
  badge: React.JSX.Element
  connection: JoyIdConnector | MetaMask
  hooks: Web3ReactHooks
  key: (typeof APP_KEYS)[keyof typeof APP_KEYS]
}

export const connectors: IConnector[] = [
  {
    icon: JoyIdIcon,
    badge: JoyIdIconBadge,
    connection: joyIdConnection,
    hooks: joyIdHooks,
    key: APP_KEYS.joyid,
  },
  {
    icon: MetamaskIcon,
    badge: MetamaskIconBadge,
    connection: metaMaskConnection,
    hooks: metamaskHooks,
    key: APP_KEYS.metamask,
  },
]

export const connectToProvider = async (
  connector: IConnector,
  dispatch: React.Dispatch<{ type: string; payload: any }>,
) => {
  try {
    const connection = connector.connection

    await connection?.activate()

    let account
    let chain = CHAIN_KEYS.godwoken

    if (connection instanceof JoyIdConnector) {
      account = connection.account
      chain = CHAIN_KEYS.ckb
    } else if (connection?.provider && 'isConnected' in connection.provider) {
      // @ts-ignore
      account = connection.provider?.selectedAddress
    }

    if (!account) throw new Error('Address not found!')

    await loginUser(dispatch, { account, appId: connector.key, chain }, true)
  } catch (error) {
    console.error('Could not connect to provider::', error)
    throw error
  }
}

export const tryConnectToProvider = async (
  connector: IConnector,
  dispatch: React.Dispatch<{ type: string; payload: any }>,
) => {
  try {
    // @ts-ignore
    if (connector?.key === APP_KEYS.joyid)
      await connector.connection?.activate(true)
    if (window?.ethereum?.selectedAddress)
      await connector.connection?.activate()

    let account
    if ((connector.connection as JoyIdConnector)?.isJoyId) {
      account = (connector.connection as JoyIdConnector).account
      account = account?.address ?? account
    } else {
      // @ts-ignore
      account = connector.connection?.provider?.selectedAddress
    }

    if (!account) return

    await loginUser(dispatch, { account, appId: connector.key })
  } catch (error) {
    console.info('Problem with attempted silent connection', error)
  }
}

export const disconnectProvider = async (
  connector: IConnector,
  dispatch: React.Dispatch<{ type: string; payload: any }>,
) => {
  dispatch({ type: 'LOGOUT', payload: null })
}

export async function verifyUser(
  connector: IConnector['connection'],
  signer?: JsonRpcSigner,
) {
  try {
    let provider = connector?.provider ?? connector
    let address

    if (!provider) provider = connector?.customProvider as any

    if (provider && 'selectedAddress' in provider) {
      address = provider.selectedAddress
    } else if (connector && 'selectedAddress' in connector) {
      address = connector.selectedAddress
    }

    if (!address || !provider) return

    let signedMessage
    if (signer && 'signChallenge' in signer) {
      // @ts-ignore
      signedMessage = await signer.signChallenge('Verify Identity', address)
    } else if (signer) {
      signedMessage = await signer.signMessage('Verify Identity')
    } else {
      throw new Error('Invalid signer')
    }

    let account: any = address
    if ('account' in connector) {
      account = connector.account
    }

    return (
      (await axios.post(`/user/verify/${address}`, { account, signedMessage }))
        .data?.verified ?? false
    )
  } catch {
    return false
  }
}
