import React, { useState, useEffect } from 'react'
import axios from 'axios'
import NFT from '../Item/NftCard'

const Explore = (props) => {
  const { isHome } = props
  const [items, setItems] = useState([])
  const [noItems, setNoItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(8)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/items/random?page=${page}&pageLimit=${pageLimit}&batch=${!isHome}`)
      .then((res) => {
        if (page === 1) setItems(res.data.items)
        else setItems(items.concat(res.data.items))

        setLoading(false)

        if (!res.data?.items?.length || res?.data?.final) setNoItems(true)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setItems([...items])
        setNoItems(true)
      })
  }, [page])

  function loadMore() {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }
  return (
    <section className="explore-area">
      {items?.length > 0 ? (
        <article className="container">
          {isHome && (
            <div className="intro">
              <div className="intro-content">
                <span>EXCLUSIVE NFTs</span>
                <h3 className="mt-1 mb-2">Explore Listed Assets</h3>
              </div>
              <div className="intro-btn mb-2">
                <a
                  className="btn content-btn text-left"
                  href="/explore/listed-items"
                >
                  View All <i className="fa-solid fa-caret-right" />
                </a>
              </div>
            </div>
          )}
          <div className="grid items">
            {items.map((item, idx) => {
              return (
                <NFT
                  {...props}
                  item={item}
                  displayPrice={true}
                  key={`exf_${idx}`}
                />
              )
            })}
          </div>
          {!isHome && (
            <div
              className="load-more"
              style={{ display: noItems ? 'none' : '', textAlign: 'center' }}
            >
              <button
                onClick={() => loadMore()}
                className="btn branded mt-3 mt-sm-4"
              >
                {loading ? 'Loading...' : 'Load more'}
              </button>
            </div>
          )}
        </article>
      ) : !isHome ? (
        <article className="text-center mb-4">
          <p>{!loading ? 'No Items Currently Listed' : 'Loading...'}</p>
        </article>
      ) : null}
    </section>
  )
}

export default Explore
