import React, { useMemo } from 'react'
import MediaWrapper from '../Media/MediaWrapper'
import { CHAIN_KEYS } from '../../utils/constants'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'
import SaveNft from '../Item/Save'
import Analytics from '../Analytics/Analytics'
import DOMPurify from 'dompurify'

interface GroupedCollectionDetailsProps {
  collection: any
  props: any
}

const GroupedCollectionDetails: React.FC<GroupedCollectionDetailsProps> = ({
  collection,
  props,
}) => {
  const renderDescription = useMemo(() => {
    if (!collection?.description) return null

    let description = collection?.description
    let parsedDescription

    // Attempt to parse the description as JSON
    try {
      parsedDescription = JSON.parse(description)
    } catch (err) {
      // If parsing fails, it's not a JSON string
      parsedDescription = null
    }

    // If parsedDescription is an object and has a description key, use it
    if (parsedDescription && parsedDescription.description) {
      description = parsedDescription.description
    }

    // Render the description
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description.replace(/\r\n/g, '<br>')),
        }}
      ></p>
    )
  }, [collection])

  return (
    <section id="GroupedCollectionDetails" className="collection-details">
      {collection.bannerImage && (
        <MediaWrapper
          src={collection.bannerImage}
          className="banner"
          alt={`${collection.name} on imagiNation by Nervos Nation built on Nervos CKB`}
        />
      )}

      <div className="container">
        <div
          className={`details ${collection.image ? 'has-image' : ''}`.trimEnd()}
        >
          <div className="title-wrapper">
            {collection.isFeatured && (
              <span className="featured-flag">
                <StarTwoToneIcon /> <span>Featured</span>
              </span>
            )}

            <h1 className="title">{collection.name}</h1>
            <SaveNft {...props} data={collection} />
          </div>

          {collection.image && (
            <MediaWrapper
              src={collection.image}
              alt={`${collection.name} on imagiNation by Nervos Nation built on Nervos CKB`}
            />
          )}

          {renderDescription}

          <div className="socials">
            <h5>Share:</h5>
            <a
              href={`https://x.com/intent/tweet?url=${window.location.href}&text=Check out this Digital Collection ${collection.name} on @imagiNation_mkt!`}
              target="_blank"
              rel="noopener"
            >
              <svg
                style={{ width: '25px', height: '25px', color: '#000' }}
                width="1200"
                height="1227"
                viewBox="0 0 1200 1227"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                  fill="#000"
                />
              </svg>
            </a>
          </div>

          <Analytics data={collection} />
        </div>

        <h2 className="text-center">
          Explore {collection?.name ?? 'Collection Items'}
        </h2>
      </div>
    </section>
  )
}

export default GroupedCollectionDetails
