import React, { useMemo, useState } from 'react'
import SearchInput from './SearchInput'

const SearchSidebar = (props: { [key: string]: any }) => {
  const { formValues, onFormChange } = props
  const maxRangeDate = useMemo(() => new Date().toISOString().slice(0, 10), [])

  return (
    <div id="SearchSidebar">
      <h3>Search Term</h3>
      <SearchInput
        showSubmit={false}
        onSearchTermChange={onFormChange}
        formValue={formValues?.term}
      />

      <span className="grid">
        <h3>Marketplace</h3>

        <span className="flex">
          <input
            type="checkbox"
            id="listed"
            name="marketStatus"
            value="listing"
            checked={
              new RegExp(`\\blisting\\b`).test(formValues?.marketStatus) ||
              false
            }
            onChange={(event) =>
              onFormChange('marketStatus-listing', event.target.checked)
            }
          />
          <label htmlFor="listing">Listed</label>
        </span>

        <span className="flex">
          <input
            type="checkbox"
            id="delisting"
            name="marketStatus"
            value="delisting"
            checked={formValues?.marketStatus?.includes('delisting') ?? false}
            onChange={(event) =>
              onFormChange('marketStatus-delisting', event.target.checked)
            }
          />
          <label htmlFor="delisting">Delisted</label>
        </span>

        <span className="grid">
          <span className="flex">
            <input
              type="checkbox"
              id="auction"
              name="marketStatus"
              value="auction"
              checked={formValues?.marketStatus?.includes('auction') ?? false}
              onChange={(event) =>
                onFormChange('marketStatus-auction', event.target.checked)
              }
            />
            <label htmlFor="auction">Auctions</label>
          </span>

          <span className="flex child">
            <input
              type="checkbox"
              id="auction_active"
              name="marketStatus"
              value="auction_active"
              checked={
                formValues?.marketStatus?.includes('auction_active') ?? false
              }
              onChange={(event) =>
                onFormChange(
                  'marketStatus-auction_active',
                  event.target.checked,
                )
              }
            />
            <label htmlFor="auction_active">Active</label>
          </span>
        </span>

        <span className="flex">
          <input
            type="checkbox"
            id="bid"
            name="marketStatus"
            value="bid"
            checked={formValues?.marketStatus?.includes('bid') ?? false}
            onChange={(event) =>
              onFormChange('marketStatus-bid', event.target.checked)
            }
          />
          <label htmlFor="bid">Bids</label>
        </span>

        <span className="flex">
          <input
            type="checkbox"
            id="purchase"
            name="marketStatus"
            value="sold"
            checked={formValues?.marketStatus?.includes('sold') ?? false}
            onChange={(event) =>
              onFormChange('marketStatus-sold', event.target.checked)
            }
          />
          <label htmlFor="sold">Purchase</label>
        </span>
      </span>

      <span className="form-group">
        <label htmlFor="priceRange">
          <h3>Price Range</h3>
        </label>
        <span className="ranges">
          <input
            type="number"
            id="minPrice"
            name="minPrice"
            placeholder="Min"
            onChange={(event) => onFormChange('minPrice', event.target.value)}
            defaultValue={formValues?.minPrice}
          />
          <hr />
          <input
            type="number"
            id="maxPrice"
            name="maxPrice"
            placeholder="Max"
            onChange={(event) => onFormChange('maxPrice', event.target.value)}
            defaultValue={formValues?.maxPrice}
          />
        </span>
      </span>
      <span className="form-group">
        <label htmlFor="mintedDate">
          <h3>Minted</h3>
        </label>
        <span className="ranges">
          <input
            type="date"
            id="mintedDateStart"
            name="mintedDateStart"
            max={maxRangeDate}
            onBlur={(event) =>
              onFormChange('mintedDateStart', event.target.value, event)
            }
            defaultValue={formValues?.mintedDateStart || ''}
          />
          <hr />
          <input
            type="date"
            id="mintedDateEnd"
            name="mintedDateEnd"
            defaultValue={formValues?.mintedDateEnd ?? maxRangeDate}
            max={maxRangeDate}
            onBlur={(event) =>
              onFormChange('mintedDateEnd', event.target.value, event)
            }
          />
        </span>
      </span>
      <span className="form-group">
        <label htmlFor="lastActivityDate">
          <h3>Last Activity</h3>
        </label>
        <span className="ranges">
          <input
            type="date"
            id="lastActivityDateStart"
            name="lastActivityDateStart"
            max={maxRangeDate}
            onBlur={(event) =>
              onFormChange('lastActivityDateStart', event.target.value, event)
            }
            defaultValue={formValues?.lastActivityDateStart || ''}
          />
          <hr />
          <input
            type="date"
            id="lastActivityDateEnd"
            name="lastActivityDateEnd"
            defaultValue={formValues?.lastActivityDateEnd ?? maxRangeDate}
            max={maxRangeDate}
            onBlur={(event) =>
              onFormChange('lastActivityDateEnd', event.target.value, event)
            }
          />
        </span>
      </span>
    </div>
  )
}

export default SearchSidebar
