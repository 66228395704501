import type { Actions, Provider, RequestArguments } from '@web3-react/types'
import type { CkbDappConfig, ConnectResponseData } from '@joyid/ckb'

import { Connector } from '@web3-react/types'
import { Address, CellDep, HexString, HashType } from '@ckb-lumos/base'
import { createJoyIDScriptInfo, getDefaultConfig } from '@ckb-lumos/joyid'
import { registerCustomLockScriptInfos } from '@ckb-lumos/lumos/common-scripts/common'
import {
  Aggregator,
  initConfig as initCkbConfig,
  getConfig,
  connect as connectCkb,
  signChallenge,
  signTransaction,
  signCotaNFTTx,
  signRawTransaction,
} from '@joyid/ckb'

import { initConfig as initEvmConfig } from '@joyid/evm'
import { EthereumProvider, EvmConfig } from '@joyid/ethereum-provider'
import { BrowserProvider } from 'ethers'

import { NETWORK, NETWORK_ID, EVM_NETWORK_NAME, NODES } from './constants'
import CkbController from './CKB/CkbController'
import { getJoyIdScriptInfo } from './CKB/JoyId'
import { CONNECTOR_STORAGE_KEY } from './connectors'

interface JoyIdProvider extends Provider {
  selectedAddress?: string
  getConfig: typeof getConfig
  connectCkb: typeof connectCkb
  signChallenge: typeof signChallenge
  signTransaction: typeof signTransaction
  signCotaNFTTx: typeof signCotaNFTTx
  signRawTransaction: typeof signRawTransaction
}

interface IJoyIdSetup {
  actions: Actions
  options?: { chainId: number }
  onError?: (error: Error) => void
}

export interface JoyIdConnection {
  pubkey: string // Hex without 0x
  address: Address // ckb address
  keyType: 'main_key' | 'sub_key'
}

export const JOYID_CKB_STORAGE_KEY = 'joyid:ckb::authData'
export const JOYID_EVM_STORAGE_KEY = 'joyid:ethereum::address'

export enum ProviderKeys {
  ckb = 'CKB',
  evm = 'EVM',
}

export class JoyIdConnector extends Connector {
  public isJoyId = true
  public defaultProvider: {
    CKB: JoyIdProvider
    EVM?: BrowserProvider
  }

  readonly #chainId: number = NETWORK_ID
  #account?: ConnectResponseData

  constructor({ actions, options, onError }: IJoyIdSetup) {
    super(actions, onError)

    this.defaultProvider = {
      CKB: {
        getConfig,
        connectCkb: connectCkb,
        signChallenge,
        signTransaction,
        signCotaNFTTx,
        signRawTransaction,
        selectedAddress: '',
        request: async (args: RequestArguments) => {},
        on: (eventName: string | symbol, listener: (...args: any[]) => void) =>
          this.defaultProvider.CKB,
        removeListener: (
          eventName: string | symbol,
          listener: (...args: any[]) => void,
        ) => this.defaultProvider.CKB,
      },
    }
  }

  private async init(ckbConfig: CkbDappConfig, ethAddress?: string) {
    for (const key of Object.keys(ckbConfig)) {
      //@ts-ignore
      if (!ckbConfig[key]) delete ckbConfig[key]
    }

    const evmConfig: EvmConfig = {
      ...ckbConfig,
      network: {
        chainId: NETWORK_ID,
        name: EVM_NETWORK_NAME,
      },
      rpcURL: NODES[0],
    }

    initEvmConfig(evmConfig)

    const evmProviderConfig = {
      id: NETWORK_ID,
      name: EVM_NETWORK_NAME,
      nativeCurrency: {
        name: 'pCKB',
        symbol: 'pCKB',
        decimals: 18,
      },
      rpcUrls: {
        default: { http: [evmConfig?.rpcURL ?? ''] },
        public: { http: [evmConfig?.rpcURL ?? ''] },
      },
      network: EVM_NETWORK_NAME,
    }

    const ethProvider = new BrowserProvider(
      new EthereumProvider([evmProviderConfig], evmConfig),
    )

    this.defaultProvider.EVM = ethProvider
    this.customProvider = ethProvider

    if (ethAddress) this.actions.update({ accounts: [ethAddress] })
  }

  public getProvider(
    chain: ProviderKeys,
  ): JoyIdProvider | BrowserProvider | undefined {
    return this.defaultProvider[chain]
  }

  activate = async (connectEagerly: boolean = false): Promise<void> => {
    try {
      const ckbConfig: CkbDappConfig = {
        name: `imagiNation ${process.env?.REACT_APP_NETWORK}`,
        logo: 'https://imagination.to/imagiNation-symbol.svg',
        joyidAppURL:
          NETWORK === 'mainnet'
            ? 'https://app.joy.id'
            : 'https://testnet.joyid.dev',
      }

      initCkbConfig(ckbConfig)

      this.connectEagerly(ckbConfig)

      if (this.account && this.account?.keyType) {
        CkbController.registerCustomScript(
          createJoyIDScriptInfo(
            this.account,
            getDefaultConfig(NETWORK === 'mainnet'),
          ),
        )
      } else if (
        !this.account ||
        (localStorage.getItem(CONNECTOR_STORAGE_KEY) && connectEagerly)
      ) {
        const authData = await connectCkb()

        this.#account = authData

        localStorage.setItem(JOYID_EVM_STORAGE_KEY, authData.ethAddress)
        localStorage.setItem(JOYID_CKB_STORAGE_KEY, JSON.stringify(authData))

        CkbController.registerCustomScript(
          createJoyIDScriptInfo(
            authData,
            getDefaultConfig(NETWORK === 'mainnet'),
          ),
        )

        this.init(ckbConfig, authData?.ethAddress)
      }

      console.info('JoyID Activated')
    } catch (error) {
      console.error(error)
      this.deactivate() // Clean up anything possibly lingering
    }
  }

  public connectEagerly(ckbConfig: CkbDappConfig) {
    const storedAccount = window.localStorage?.getItem(JOYID_CKB_STORAGE_KEY)
    const account = this?.account
      ? this.account
      : storedAccount
        ? JSON.parse(storedAccount)
        : null

    if (!!account) this.#account = account
    else if (account?.ethAddress) this.init(ckbConfig, account.ethAddress)
  }

  public deactivate() {
    localStorage.removeItem(JOYID_CKB_STORAGE_KEY)
    localStorage.removeItem(JOYID_EVM_STORAGE_KEY)
    localStorage.removeItem(CONNECTOR_STORAGE_KEY)

    this.defaultProvider.EVM = undefined
    this.customProvider = undefined
  }

  public getChainId(): number {
    return this.#chainId
  }

  // Allow account to be privately set
  public set account(_) {}

  public get account(): ConnectResponseData | undefined {
    return this.#account
  }

  public get selectedAddress(): string | undefined {
    return this.account?.address
  }
}
