import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { getRenderReadyRoyalty, prettyCommaFormat } from '../../utils/helpers'
import { Analytics as IconAnalytics } from '@styled-icons/material/Analytics'
import { Storefront } from '@styled-icons/material/Storefront'
import AuthorAvatar from '../Author/AuthorAvatar'
import { CHAIN_KEYS, SYMBOL_KEYS } from '../../utils/constants'

import './analytics.scss'

const Analytics = (props: { data: any; type?: string }) => {
  const { data, type } = props

  const title = useMemo(
    () => (data?.tokenId ? 'Item' : 'Collection'),
    [data?.tokenId],
  )
  const decimals = useMemo(
    () => (data?.chain === CHAIN_KEYS.ckb ? 8 : 18),
    [data?.chain],
  )
  const symbol = useMemo(
    () =>
      data?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken,
    [data?.chain],
  )

  const [analyticsState, setAnalyticsState] = useState({
    totalVolume: undefined,
    lowestPriceMarket: undefined,
    lowestPriceAuction: undefined,
    highestPriceMarket: undefined,
    highestPriceAuction: undefined,
    listedPercent: 0,
    listedItems: undefined,
    totalItemCount: undefined,
    ownerCount: undefined,
    uniqueOwnerCount: 0,
    soldCount: 0,
    activeSales: { market: 0, auction: 0 },
    totalItemsMinted: 0,
    nftStandard: '',
    marketActive: false,
  })

  const royalty = useMemo(() => {
    if (!data) return

    if (data?.royalty && data?.royalty > 0) {
      const royalty = getRenderReadyRoyalty(data)
      return (
        <span>
          <dt>{title} Royalty</dt>
          <dd>{royalty}%</dd>
        </span>
      )
    }
    return null
  }, [data, title])

  useEffect(() => {
    if (!data) return

    setAnalyticsState((prevState) => ({
      ...prevState,
      nftStandard: data?.standard ?? '',
    }))

    function checkMarketStats(analyticsData: { [key: string]: any }) {
      const valuesToCheck = [
        BigInt(analyticsData.activeSales?.auction ?? 0),
        BigInt(analyticsData.activeSales?.market ?? 0),
        BigInt(analyticsData?.lowestPrice?.market ?? 0),
        BigInt(analyticsData?.lowestPrice?.auction ?? 0),
        BigInt(analyticsData?.highestPrice?.market ?? 0),
        BigInt(analyticsData?.highestPrice?.auction ?? 0),
        BigInt(analyticsData?.listedItems ?? 0),
        BigInt(analyticsData?.soldCount ?? 0),
        BigInt(analyticsData?.totalVolume ?? 0),
        BigInt(parseFloat(analyticsData?.listedPercent ?? 0).toFixed(0) || 0),
      ]

      const stats = valuesToCheck.some((value) => value > 0n)

      setAnalyticsState((prevState) => ({
        ...prevState,
        marketActive: stats,
      }))
    }

    async function fetchAnalytics() {
      try {
        let collectionAddress = data?.itemCollection ?? data?.address

        if (collectionAddress && Array.isArray(collectionAddress))
          collectionAddress = collectionAddress.join(',')
        else if (data?.address && Array.isArray(data?.address))
          collectionAddress = data.address.join(',')

        const queryParams = new URLSearchParams(
          `collectionAddress=${collectionAddress}`,
        )

        if (data?.tokenId) queryParams.set('tokenId', data.tokenId)

        const { data: analyticsData } = await axios.get(
          `/analytics/collection?${queryParams.toString()}`,
        )

        setAnalyticsState((prevState) => ({
          ...prevState,
          totalVolume: analyticsData?.totalVolume,
          lowestPriceMarket: analyticsData?.lowestPrice?.market,
          lowestPriceAuction: analyticsData?.lowestPrice?.auction,
          highestPriceMarket: analyticsData?.highestPrice?.market,
          highestPriceAuction: analyticsData?.highestPrice?.auction,
          listedItems: analyticsData?.listedItems,
          totalItemCount: analyticsData?.totalItems,
          listedPercent: analyticsData?.listedPercent,
          ownerCount: analyticsData?.ownerCount,
          uniqueOwnerCount: analyticsData?.uniqueOwnerCount,
          soldCount: analyticsData?.soldCount,
          activeSales: {
            auction: analyticsData?.activeSales?.auction,
            market: analyticsData?.activeSales?.market,
          },
          totalItemsMinted: analyticsData?.totalUniqueItems,
        }))

        checkMarketStats(analyticsData)
      } catch (error) {
        console.error('Error fetching analytics', error)
      }
    }

    fetchAnalytics()
  }, [data])

  return (
    <div id="Analytics">
      {analyticsState.marketActive && (
        <div className="market">
          <IconAnalytics
            size={50}
            color="var(--primary-color)"
            className="icon"
          />
          <h4>Market Stats</h4>

          <dl>
            {(analyticsState.activeSales.market > 0 ||
              analyticsState.activeSales.auction > 0) && (
              <span>
                <dt>
                  Active Sale
                  {analyticsState.activeSales.market +
                    analyticsState.activeSales.auction >
                  1
                    ? 's'
                    : ''}
                </dt>
                {analyticsState.activeSales.market > 0 && (
                  <dd>{analyticsState.activeSales.market} (Sale)</dd>
                )}
                {analyticsState.activeSales.auction > 0 && (
                  <dd>{analyticsState.activeSales.auction} (Auction)</dd>
                )}
              </span>
            )}

            {(analyticsState.lowestPriceMarket ||
              analyticsState.lowestPriceAuction) && (
              <span>
                <dt>
                  Lowest Price
                  {analyticsState.lowestPriceMarket &&
                  analyticsState.lowestPriceAuction
                    ? 's'
                    : ''}
                </dt>
                {analyticsState.lowestPriceMarket && (
                  <dd>
                    {prettyCommaFormat(
                      analyticsState.lowestPriceMarket,
                      decimals,
                    )}{' '}
                    {symbol} (Sale)
                  </dd>
                )}
                {analyticsState.lowestPriceAuction && (
                  <dd>
                    {prettyCommaFormat(
                      analyticsState.lowestPriceAuction,
                      decimals,
                    )}{' '}
                    {symbol} (Auction)
                  </dd>
                )}
              </span>
            )}

            {analyticsState.highestPriceMarket && (
              <span>
                <dt>Highest Price (Sale)</dt>
                <dd>
                  {prettyCommaFormat(
                    analyticsState.highestPriceMarket,
                    decimals,
                  )}{' '}
                  {symbol}
                </dd>
              </span>
            )}

            {analyticsState.highestPriceAuction && (
              <span>
                <dt>Highest Price (Auction)</dt>
                <dd>
                  {prettyCommaFormat(
                    analyticsState.highestPriceAuction,
                    decimals,
                  )}{' '}
                  {symbol}
                </dd>
              </span>
            )}

            {analyticsState.listedItems > 0 && (
              <span>
                <dt>Listed Items</dt>
                <dd>{analyticsState.listedItems}</dd>
              </span>
            )}

            {analyticsState.totalItemCount > 0 && (
              <span>
                <dt>Total Items</dt>
                <dd>{analyticsState.totalItemCount}</dd>
              </span>
            )}

            {analyticsState.ownerCount > 0 && (
              <span>
                <dt>Owners</dt>
                <dd>{analyticsState.ownerCount}</dd>
              </span>
            )}

            {analyticsState.uniqueOwnerCount > 0 && (
              <span>
                <dt>Unique Owners</dt>
                <dd>{analyticsState.uniqueOwnerCount}</dd>
              </span>
            )}

            {analyticsState.soldCount > 0 && (
              <span>
                <dt>Sold Count</dt>
                <dd>{analyticsState.soldCount}</dd>
              </span>
            )}

            {analyticsState.totalVolume > 0 && (
              <span>
                <dt>Total Volume</dt>
                <dd>
                  {prettyCommaFormat(analyticsState.totalVolume, decimals)}{' '}
                  {symbol}
                </dd>
              </span>
            )}

            {analyticsState.listedPercent > 0 && (
              <span>
                <dt>Listed Percent</dt>
                <dd>{analyticsState.listedPercent.toFixed(2)}%</dd>
              </span>
            )}

            {analyticsState.totalItemsMinted > 0 && (
              <span>
                <dt>Total Items Minted</dt>
                <dd>{analyticsState.totalItemsMinted}</dd>
              </span>
            )}
          </dl>
        </div>
      )}

      <div className="nft">
        <Storefront size={50} color="var(--primary-color)" />
        <h4>{title} Statistics</h4>

        <dl>
          {royalty}

          {analyticsState.uniqueOwnerCount > 0 && (
            <span>
              <dt>Owners (Unique)</dt>
              <dd>
                {analyticsState.ownerCount} ({analyticsState.uniqueOwnerCount})
              </dd>
            </span>
          )}

          {analyticsState.totalItemsMinted > 0 && (
            <span>
              <dt>Total Items Minted</dt>
              <dd>{analyticsState.totalItemsMinted}</dd>
            </span>
          )}

          {analyticsState.nftStandard !== '' && (
            <span>
              <dt>Contract Standard</dt>
              {/* @ts-ignore */}
              <dd>{analyticsState.nftStandard}</dd>
            </span>
          )}

          {data?.creator && (
            <span>
              <dt>{title} Minter</dt>
              <dd>
                <AuthorAvatar author={data?.creator} displayImage={false} />
              </dd>
            </span>
          )}

          {!!data?.owner && (
            <span>
              <dt>{title} Owner</dt>
              <dd>
                <AuthorAvatar author={data?.owner} displayImage={false} />
              </dd>
            </span>
          )}
        </dl>
      </div>
    </div>
  )
}

export default Analytics
