import NationERC721V1 from './EVM/contract-abis/V1/NationERC721.json'
import NationERC1155V1 from './EVM/contract-abis/V1/NationERC1155.json'
import NationAuctionV1 from './EVM/contract-abis/V1/NationAuction.json'
import NationMarketV1 from './EVM/contract-abis/V1/NationMarket.json'
import NationNFTV1 from './EVM/contract-abis/V1/NationNFT.json'

import NationERC721V2 from './EVM/contract-abis/V2/NationERC721.json'
import NationERC1155V2 from './EVM/contract-abis/V2/NationERC1155.json'
import NationFactoryV2 from './EVM/contract-abis/V2/NationFactory.json'
import NationMarketV2 from './EVM/contract-abis/V2/NationMarket.json'
import NationAuctionV2 from './EVM/contract-abis/V2/NationAuction.json'
import NationNFTV2 from './EVM/contract-abis/V2/NationNFT.json'

import pCKB from './EVM/contract-abis/pCKB.json'

export const CONTRACTS_BY_NETWORK: { [key: string]: any } = {
  // Godwoken Testnet v1
  // 71401: {
  testnet: {
    COMMON_NATION: {
      address: '0x2b020a1efdebeee5e8c0c74692ec80b7592910cb'.toLowerCase(),
      sporeAddress:
        '0x66f4718d56763e6c5a59f1dcebe46b6bf9ccbb5e57f931ce159203058cef5795'.toLowerCase(),
    },
    ERC721Factory: {
      address: '0x9525446402fD6256298e51e993f1DfB73c91b105',
      abi: NationFactoryV2,
    },
    ERC1155Factory: {
      address: '0x608176E4F4f56843df5B55Eb67726b7Dbf0e5Abb',
      abi: NationFactoryV2,
    },
    NationNFT: {
      abi: NationNFTV1,
    },
    NationNFTV2: {
      abi: NationNFTV2,
    },
    NationERC721: {
      abi: NationERC721V1,
    },
    NationERC721V2: {
      abi: NationERC721V2,
    },
    NationERC1155V2: {
      abi: NationERC1155V2,
    },
    NationERC1155: {
      abi: NationERC1155V1,
    },
    NationMarketV0_1: {
      address: '0xeff57aacae6cf06e73c88381a49ba654726ef7e3',
      abi: NationMarketV1,
    },
    NationMarketV0_2: {
      address: '0x40e4355601b26eee458eb27c0758a443adcc53bd',
      abi: NationMarketV1,
    },
    NationMarketV1_1: {
      address: '0xf157A576e6969931201E2Fce4466d8fE64596662',
      abi: NationMarketV1,
    },
    NationMarketV1_2: {
      address: '0x2e026ecb63b569d912edd3e0e89712003b306fa9',
      abi: NationMarketV1,
    },
    NationMarketV2: {
      address: '0x8035c247e29563f5aefd7519e84c9608eb315097',
      abi: NationMarketV2,
    },
    NationAuctionV0: {
      address: '0xac1c84d470ef33eef32df7b2954fa4a17f8bcb11',
      abi: NationAuctionV1,
    },
    NationAuctionV1: {
      address: '0xa656c5fc1ec29c8943af6633a2f56594c4725aee',
      abi: NationAuctionV1,
    },
    NationAuctionV2: {
      address: '0xf3c73e0347e6836b3362560b811331a9e7ef5611',
      abi: NationAuctionV2,
    },

    pCKB: {
      address: '0xe05d380839f32bc12fb690aa6fe26b00bd982613',
      abi: pCKB,
    },
  },
  // Godwoken Mainnet v1
  // 71402: {
  mainnet: {
    COMMON_NATION: {
      address: '0xEAcf8aD9a88B6012d847D2c3804cb774bE0ca68e',
    },
    ERC721Factory: {
      address: '0x290941afd41416a7334bea7a2b2c82ef627a2a38',
      abi: NationFactoryV2,
    },
    ERC1155Factory: {
      address: '0xaF1d0dDe358B6f1D269544e584Cb784FC108c180',
      abi: NationFactoryV2,
    },
    NationNFT: {
      abi: NationNFTV1,
    },
    NationNFTV2: {
      abi: NationNFTV2,
    },
    NationERC721: {
      abi: NationERC721V1,
    },
    NationERC721V2: {
      abi: NationERC721V2,
    },
    NationERC1155V2: {
      abi: NationERC1155V2,
    },
    NationERC1155: {
      abi: NationERC1155V1,
    },
    NationMarketV0: {
      address: '0x904bcc39a1629a20104aae54ef2e62d30ca2af19',
      abi: NationMarketV1,
    },
    NationMarketV1: {
      address: '0xa7e568afd214f7855dd321ecbef5d8e482a87420',
      abi: NationMarketV1,
    },
    NationMarketV2: {
      address: '0xd1d80445a2d5865de1ef4fd261988c5e883294b3',
      abi: NationMarketV2,
    },
    NationAuctionV1: {
      address: '0xb4c41ab2f7620ac43b8d9030bd512fab29aa82c6',
      abi: NationAuctionV1,
    },
    NationAuctionV2: {
      address: '0x12339562d3805bf334756cfa220de161c5bcb5b1',
      abi: NationAuctionV2,
    },
    pCKB: {
      address: '0x7538c85cae4e4673253ffd2568c1f1b48a71558a',
      abi: pCKB,
    },
  },
}
