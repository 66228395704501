import React, { useState, useEffect, useRef, useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useAuth } from '../../context/authContext'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { CircularProgress } from '@mui/material'
import { COMMON_NATION_ADDRESSES } from '@imagination/common'

import NFT from '../Item/NftCard'
import SaveNft from '../Item/Save'
import MediaWrapper from '../Media/MediaWrapper'
import GroupedCollectionDetails from './GroupedCollectionDetails'
import {
  ItemUiMetadata,
  NERVAPE_GEAR_CLUSTER_IDS,
  NETWORK,
} from '../../utils/constants'

import NervapeBanner from '../../assets/img/spotlight/nervape-gear-nervos-ckb-layer-1.webp'

function getCollectionAddressFromUri(collectionName: string): string[] {
  switch (collectionName) {
    case 'nervape-gear':
      return NERVAPE_GEAR_CLUSTER_IDS?.[NETWORK]
    case 'common-nation':
      return ['common-nation', ...COMMON_NATION_ADDRESSES[NETWORK]]
    default:
      return ['0x0']
  }
}

function getName(collectionName: string) {
  switch (collectionName) {
    case 'nervape-gear':
      return 'Nervape Gear'
    case 'common-nation':
      return 'Common Nation'
    default:
      return ''
  }
}

function getDescription(collectionName: string) {
  switch (collectionName) {
    case 'nervape-gear':
      return 'Accessorize and create your unique Nervape today with Nervape Gear!<br /><br />Check out the <a href="https://www.nervape.com/nervapes" target="_blank">Nervape Gallery</a> to view the entire Nervape collection with a filtered breakdown.'
    default:
      return ''
  }
}

function getBannerImage(collectionName: string) {
  switch (collectionName) {
    case 'nervape-gear':
      return NervapeBanner
    default:
      return ''
  }
}

function getDetailsColor(collectionName: string) {
  switch (collectionName) {
    case 'nervape-gear':
      return 'rgb(255, 208, 0)'
    case 'common-nation':
      return ''
    default:
      return ''
  }
}

const GroupedCollections = (props: any) => {
  const { collection: collectionParam } = useParams<{ collection: string }>()
  const { state } = useAuth()
  const { account } = useWeb3React()

  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)

  const [collections, setCollections] = useState<Array<{ [key: string]: any }>>(
    [],
  )
  const [collection, setCollection] = useState<null | { [key: string]: any }>(
    null,
  )
  const [items, setItems] = useState<any[]>([])
  const [noMoreItems, setNoMoreItems] = useState(false)
  const [loading, setLoading] = useState(false)

  const separateCollections = useRef(collectionParam === 'nervape-gear')

  const addressesToFetch = useMemo(
    () => getCollectionAddressFromUri(collectionParam),
    [collectionParam],
  )

  const name = useRef(getName(collectionParam))
  const bannerImage = useRef(getBannerImage(collectionParam))
  const description = useRef(getDescription(collectionParam))
  const detailsColor = useRef(getDetailsColor(collectionParam))

  useEffect(() => {
    if (!collectionParam || !addressesToFetch.length) return

    const xlScreen = window.innerWidth >= 1620
    const pageLimit = separateCollections.current ? (xlScreen ? 6 : 4) : 8

    async function fetchCollections() {
      try {
        const queryParams = new URLSearchParams({
          page: page.toString(),
          pageLimit: pageLimit.toString(),
        })

        if (separateCollections.current) {
          let allCollections = []
          for (const address of addressesToFetch) {
            const { data } = await axios.get(
              `/collection/${address}?${queryParams.toString()}`,
            )
            allCollections.push(data.collection)
          }

          setCollections(allCollections)
          // setCollections(data.collections)
        } else {
          const { data } = await axios.get(
            `/collection/${collectionParam}?${queryParams.toString()}`,
          )

          setCollection(data.collection)
          setItems((prevItems) => [...prevItems, ...data.collection.items])

          if (data.final) setNoMoreItems(true)
        }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching collections', error)
        setLoading(false)
        setCollections([])
      }
    }

    fetchCollections()
  }, [page, pageLimit, collectionParam, addressesToFetch])

  function loadMore() {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  return (
    <article
      id="GroupedCollections"
      className={`collection-details${separateCollections.current ? ' separate' : ''}`}
      style={{ backgroundColor: detailsColor.current || 'transparent' }}
    >
      {bannerImage.current && (
        <div className="banner">
          <MediaWrapper
            src={bannerImage.current}
            alt={`${name.current} on imagiNation by Nervos Nation built on Nervos CKB`}
          />

          {name.current && <h1 className="sr-only">{name.current}</h1>}
        </div>
      )}

      {separateCollections.current ? (
        <>
          <div className="details">
            <SaveNft {...props} address={collectionParam} />

            {description.current && (
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    description.current.replace(/\r\n/g, '<br>'),
                  ),
                }}
              />
            )}

            <div className="share">
              <h5 className="sr-only">Share</h5>
              <a
                className="btn"
                href={`https://x.com/intent/tweet?url=${window.location.href}&text=Check out this Project: ${name.current || ''} on @imagiNation_mkt!`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  style={{ width: '40px', height: '40px' }}
                  width="1200"
                  height="1227"
                  viewBox="0 0 1200 1227"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="#fff"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div className="collections">
            <h2>
              Explore{' '}
              {!separateCollections.current
                ? collections[0]?.name
                : name.current || 'Collections'}
            </h2>

            {collections.map((collection, index) => (
              <div key={index} className="collection">
                <h3 className="text-center" style={{ color: '#000' }}>
                  {collection?.name?.split(' ').slice(1).join(' ')}
                </h3>

                <div
                  className="grid items explore-items"
                  id={`collection_${index}`}
                >
                  {collection.items
                    .slice(0, pageLimit)
                    .map(
                      (
                        item: ItemUiMetadata,
                        itemIndex: React.Key | null | undefined,
                      ) => (
                        <NFT
                          {...props}
                          className="item"
                          item={item}
                          key={itemIndex}
                          displayPrice={true}
                        />
                      ),
                    )}
                </div>

                <NavLink
                  to={`/collection/${collection.address}`}
                  className="btn branded btn-block mt-3"
                >
                  {collection?.name
                    ? `Explore All ${collection.name}`
                    : 'Explore Entire Collection'}
                </NavLink>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="collection">
          {collection && (
            <GroupedCollectionDetails
              collection={collection}
              props={{ account }}
            />
          )}

          <div className="container grid items">
            {items?.map(
              (
                item: ItemUiMetadata,
                itemIndex: React.Key | null | undefined,
              ) => (
                <NFT
                  {...props}
                  className="item"
                  item={item}
                  key={itemIndex}
                  displayPrice={true}
                />
              ),
            )}
          </div>

          {loading ? (
            <CircularProgress
              style={{ width: '16px', height: '16px', color: 'white' }}
            />
          ) : !noMoreItems ? (
            <button
              onClick={loadMore}
              className="btn branded mt-3 d-block mx-auto"
            >
              Load More
            </button>
          ) : null}
        </div>
      )}
    </article>
  )
}

export default GroupedCollections
