import { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import MainNav from './MainNav'
import ModalMenu from '../Modal/ModalMenu'
import UserPopover from './UserPopover'

import './header.scss'

const Header = (props) => {
  const headerRef = useRef(null)
  const [useInvertedLogo, setUseInvertedLogo] = useState(false)

  useEffect(() => {
    // Get the current class list of the header element
    if (!headerRef) return

    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type !== 'attributes') return

        if (
          window.location.pathname === '/' &&
          !mutation.target?.classList?.contains('sticky')
        ) {
          setUseInvertedLogo(true)
        } else {
          setUseInvertedLogo(false)
        }
      }
    })

    // observer.observe(headerRef.current, { attributes: true })

    if (
      window.location.pathname === '/' &&
      !headerRef?.classList?.contains('sticky')
    ) {
      setUseInvertedLogo(true)
    }

    // return () => observer.disconnect()
    const alertBar = document.querySelector('#AlertBar')
    if (!alertBar) return

    alertBar.addEventListener('click', () => {
      alertBar.querySelector('.text').innerText = ''
      alertBar.classList.remove(
        'show',
        'alert-success',
        'alert-danger',
        'alert-warning',
      )
    })
  }, [])

  const renderLogo = useMemo(() => {
    // if (useInvertedLogo) return <img className='logo' src="/imagiNation-logo-black.svg" alt='imagiNation Marketplace - Black Logo' />

    return (
      <img
        className="logo"
        src="/imagiNation-logo-white.svg"
        alt="imagiNation Marketplace - White Logo"
      />
    )
  }, [useInvertedLogo])

  return (
    <header
      ref={headerRef}
      data-aos="zoom-out"
      data-aos-delay={800}
      id="header"
    >
      <div id="AlertBar" className="alert alert-dismissible fade">
        <span className="text" />

        <button type="button" className="close" aria-label="Close">
          <span>&times;</span>
        </button>
      </div>
      {/* Navbar */}
      <nav className="navbar navbar-expand container">
        {/* Navbar Brand */}
        <NavLink to="/" exact={true}>
          {renderLogo}
        </NavLink>

        {/* Navbar */}
        <MainNav />

        <UserPopover {...props} />

        {/* Navbar Toggler */}
        <button className="btn toggle" data-toggle="modal" data-target="#menu">
          <i className="fa-solid fa-bars toggle-icon m-0" />
        </button>
      </nav>

      <ModalMenu />
    </header>
  )
}

export default Header
