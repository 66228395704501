import type {
  FileObject,
  IpfsObject,
  ItemUiMetadata,
} from '../../utils/constants'

import React, { useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { CircularProgress } from '@mui/material'
import PlusOne from '@mui/icons-material/Add'
import MinusOne from '@mui/icons-material/Remove'
import { getStorageStyleByType } from '@imagination/common'
import axios from 'axios'

import { useHistory } from 'react-router-dom'
import {
  getIpfsHashFromJson,
  getIpfsHashFromFile,
} from '../../utils/storage/ipfs'
import { mintItems as mintEvmItems } from '../../utils/EVM/EvmService'
import { mintSpore, mintCotaItems } from '../../utils/CKB/CkbService'
import {
  CHAIN_KEYS,
  MIN_CKB_FEE,
  CREATE_ITEM_STORE_NAME,
  EVM_FEE,
  NFT_STANDARDS,
  StorageStyle,
  StorageType,
} from '../../utils/constants'
import { getContractConvertedRoyalty } from '../../utils/helpers'
import MediaWrapper from '../Media/MediaWrapper'
import { LOCAL_STORAGE_KEY as COLLECTION_LOCAL_STORAGE_KEY } from './Collections'
import NationDB from '../../utils/IndexedDB'
import { calculateCkbfsCellSize } from '../../utils/storage/ckbfs'
import CkbController from '../../utils/CKB/CkbController'

import * as S from './styles'
import './create.scss'

const Items = (props: {
  user: any
  connectAccount: React.MouseEventHandler<HTMLButtonElement> | undefined
  selectedCollection: { [key: string]: any } | null
  setSnackBarMessage: (arg0: string) => void
  setOpenSnackbar: (arg0: boolean) => void
  chain: string
}) => {
  const { provider } = useWeb3React()
  const history = useHistory()

  const emptyItem: ItemUiMetadata = {
    amount: 1,
    mediaType: '',
    traits: [],
    category: 'Arts',
    chain: '',
    creator: '',
    description: '',
    file: null,
    image: '',
    name: '',
    royalty: '5.00',
    standard: '',
    version: 2,
    storageType: StorageType.IPFS,
    storageStyle: StorageStyle.IPFSContent,
    unlockStorageStyle: false,
    isNation: true,
  }

  const [itemCount, setItemCount] = useState<number>(1)
  const [categories, setCategories] = useState<Object[]>([])
  const [creatingItem, setCreatingItem] = useState(false)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [items, setItems] = useState<ItemUiMetadata[]>([emptyItem])
  const [store, setStore] = useState<typeof NationDB | null>(null)
  const [cotaItems, setCotaItems] = useState<{
    [key: number]: { [key: string]: string | number } | string | null
  }>({})
  const [cotaItemIndex, setCotaItemIndex] = useState(0)
  const [cotaIssueTotal, setCotaIssueTotal] = useState(0)
  const [ckbfsCapacity, setCkbfsCapacity] = useState('')
  const [renderEstimatedCapacity, setRenderEstimatedCapacity] = useState('')
  const [ckbfsCells, setCkbfsCells] = useState(0)

  async function setStoreData(initialStore: typeof NationDB) {
    let storedItems = await initialStore.getAllStoreData<ItemUiMetadata>()

    if (storedItems?.length > 0) {
      storedItems = storedItems.map((item: ItemUiMetadata) => {
        if (item?.file) {
          item.image = URL.createObjectURL(
            new Blob([item.file.buffer], {
              type: item.file.mimeType as string,
            }),
          )
          if (item.mediaType === 'audio') item.coverImage = true
        }

        if (item?.coverImageFile) {
          item.coverImage = URL.createObjectURL(
            new Blob([item.coverImageFile.buffer], {
              type: item.coverImageFile.mimeType as string,
            }),
          )
        }

        if (item?.attributes) {
          item.traits = item.attributes.map((attribute) => [
            attribute.trait_type,
            attribute.value,
          ])
        }
        if (!item?.traits) item.traits = []

        return item
      })

      setItems(storedItems)
    }
    setItemCount(
      storedItems
        ?.map((item: ItemUiMetadata) => item.amount || 1)
        .reduce(
          (previousValue: number, currentValue: number) =>
            Number(previousValue) + Number(currentValue),
          0,
        ) ?? 0,
    )
    setCurrentItemIndex(storedItems?.length - 1 || 0)
  }

  useEffect(() => {
    function fetchCategories() {
      axios
        .get('/categories')
        .then((res) => {
          if (res.data.categories.length > 1) {
            setCategories(res.data.categories)
          }
        })
        .catch((err) => {
          console.error('Error fetching categories: ', err.message)
          setCategories([])
        })
    }

    if (categories.length === 0) fetchCategories()
  }, [categories])

  useEffect(() => {
    let isMounted = true

    async function setupStore() {
      if (!isMounted) return

      const newStore = await NationDB.initDB(CREATE_ITEM_STORE_NAME)

      setStoreData(newStore)
      setStore(newStore)
    }

    if (!store) setupStore()

    return () => {
      isMounted = false
    }
  }, [store])

  const isSpore = useMemo(
    () =>
      props?.selectedCollection?.[props?.chain]?.standard ===
      NFT_STANDARDS.spore,
    [props?.chain, props?.selectedCollection],
  )

  const isCota = useMemo(
    () =>
      props?.selectedCollection?.[props?.chain]?.standard ===
      NFT_STANDARDS.cota,
    [props?.chain, props?.selectedCollection],
  )

  const isCkb = useMemo(
    () => props?.selectedCollection?.[props?.chain]?.chain === CHAIN_KEYS.ckb,
    [props?.chain, props?.selectedCollection],
  )

  const titleText = useMemo(() => {
    if (!props.selectedCollection?.[props.chain]?.standard) return 'New Item'

    return `New ${props.selectedCollection?.[props.chain].standard} Item`
  }, [props])

  const mintItemText = useMemo(() => {
    if (isCota) return 'Mint CoTA Collection Items'
    if (!items || !itemCount || !props.selectedCollection?.[props.chain])
      return 'Mint NFT*'

    if (
      props.selectedCollection?.[props.chain].standard === NFT_STANDARDS.erc1155
    ) {
      return `Mint ${items.length} item${items.length > 1 ? 's' : ''}* (${itemCount} unit${itemCount > 1 ? 's' : ''})`
    }

    return `Mint ${itemCount} item${itemCount > 1 ? 's' : ''}*${ckbfsCells > 0 ? `(${itemCount + ckbfsCells} Transactions***)` : ''}`
  }, [itemCount, items, props.chain, props.selectedCollection])

  const hasAmountInput = useMemo(() => {
    if (!props?.selectedCollection?.[props.chain]?.standard) return false

    return (
      props?.selectedCollection[props.chain]?.standard ===
        NFT_STANDARDS.erc1155 ||
      (props?.selectedCollection[props.chain]?.standard ===
        NFT_STANDARDS.erc721 &&
        props.selectedCollection.version !== 1)
    )
  }, [props.chain, props.selectedCollection])

  const getMaxFileSize = (storageType: StorageType) => {
    let size = '5mb'

    if (storageType !== StorageType.IPFS) {
      size = '500kb'
    }

    return size
  }

  const renderItemFee = useMemo(() => {
    let ticker = 'pCKB'
    let fee = EVM_FEE

    if (props?.chain === 'CKB') {
      ticker = 'CKB'
      fee = MIN_CKB_FEE
    }
    return `${fee} ${ticker}`
  }, [props?.chain])

  useEffect(() => {
    if (!props?.selectedCollection || !items?.length) return

    let ckbfsCapacity = 0n
    let ckbfsCellCount = 0
    let estimatedCapacity = 0n

    items.forEach((item) => {
      if (item?.file && item.storageType === StorageType.CKBFS) {
        const creator = props.user.addresses.find(
          (addressObject: any) => addressObject.chain === CHAIN_KEYS.ckb,
        )?.address

        const ckbfsCellSize = calculateCkbfsCellSize(creator, item.file)

        ckbfsCapacity += ckbfsCellSize
        ckbfsCellCount++
      }

      if (item.storageType === StorageType.CellData) {
        estimatedCapacity += BigInt(item.file?.buffer.byteLength ?? 0)
      } else if (item?.file) {
        const creator = props.user.addresses.find(
          (addressObject: any) => addressObject.chain === CHAIN_KEYS.ckb,
        )?.address

        const sporeCellSize = CkbController.calculateSporeCapacity(
          creator,
          item,
          item?.standard === NFT_STANDARDS.spore &&
            props?.selectedCollection?.[props?.chain].address !== '0x1',
        )

        estimatedCapacity += sporeCellSize / 10n ** 8n
      }
    })

    setCkbfsCapacity((ckbfsCapacity / 10n ** 8n).toString())
    setCkbfsCells(ckbfsCellCount)
    setRenderEstimatedCapacity(estimatedCapacity.toString())
  }, [items, props])

  async function handleMainFile(
    event: { target: { files: any[] } },
    index: number,
  ) {
    const file = event.target.files[0]
    const updatedItems = [...items]

    const selectedCollection = props?.selectedCollection?.[props.chain]

    if (
      selectedCollection.standard === NFT_STANDARDS.spore &&
      updatedItems[index].storageType !== StorageType.IPFS &&
      file.size > 500 * 1000
    ) {
      // Not using 1024 to account for storing IPFS Hash in contentType
      // @TODO Get accurate size for Max size image upload
      console.error('File size exceeds 500kb limit for Spore NFTs.')

      props.setSnackBarMessage('File size exceeds 500kb limit for Spore NFTs.')
      props.setOpenSnackbar(true)

      return
    } else if (file.size > 5000 * 1024) {
      console.error('File size exceeds 5mb limit.')

      props.setSnackBarMessage('File size exceeds 5mb limit.')
      props.setOpenSnackbar(true)

      return
    }

    const fileType = file.type
    const mediaType = fileType.split('/')[0]

    // function hexToBinaryString(hex) {
    //   var binaryString = '';
    //   for (var i = 0; i < hex.length; i += 2) {
    //     binaryString += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    //   }
    //   return binaryString;
    // }

    // function base64ToSVG(base64) {
    //   return Buffer.from(base64, 'base64').toString('utf-8');
    // }

    // const fileAsObjectUri = URL.createObjectURL(file)
    const fileObject: FileObject = {
      buffer: await file.arrayBuffer(),
      name: file.name,
      mimeType: fileType,
    }

    updatedItems[index].mediaType = mediaType
    updatedItems[index].image = URL.createObjectURL(
      new Blob([file], { type: fileType || 'image/*' }),
    )
    updatedItems[index].file = fileObject

    if (mediaType === 'audio') {
      updatedItems[index].coverImage = true
    } else if (updatedItems[index].coverImageFile) {
      delete updatedItems[index].coverImageFile
    }

    store?.updateData(index, updatedItems[index])
    setItems(updatedItems)
  }

  function removeMainFile(index: number) {
    const updatedItems = [...items]

    updatedItems[index].image = ''
    updatedItems[index].audio = ''
    updatedItems[index].image = ''
    updatedItems[index].file = null

    setItems(updatedItems)
  }

  async function handleCoverImageUpload(
    event: { target: { files: any[] } },
    index: number,
  ) {
    const fileType = event.target.files[0].type
    const file = await event.target.files[0].arrayBuffer()

    if (!file) return

    const mediaType = fileType.split('/')[0]
    if (mediaType !== 'image' && mediaType !== 'video') {
      props.setSnackBarMessage(`File type is not accepted! Found: ${mediaType}`)
      props.setOpenSnackbar(true)

      return
    }

    const updatedItems = [...items]
    const fileObject: FileObject = {
      buffer: await file.arrayBuffer(),
      name: file.name,
      mimeType: fileType,
    }

    updatedItems[index].coverImageFile = fileObject

    // Spore check
    const uploadedFileSize = updatedItems[index]?.file?.buffer?.byteLength ?? 0
    const maxSporeSize = 500 * 1000 // rounding
    const allocatedFileSize = maxSporeSize - uploadedFileSize

    // Combining the video/audio file with a cover image in the same Spore "Native" style with 500kb size limit
    if (isSpore && file.size > allocatedFileSize) {
      // Not using 1024 to account for storing IPFS Hash in contentType
      // @TODO Get accurate size for Max size image upload
      console.error('File size exceeds limit for Spore NFTs.')

      props.setSnackBarMessage(
        'File size exceeds combined limit for Spore NFTs.',
      )
      props.setOpenSnackbar(true)

      return
    } else if (file.size > 5000 * 1024) {
      console.error('File size exceeds 5mb limit.')

      props.setSnackBarMessage('File size exceeds 5mb limit.')
      props.setOpenSnackbar(true)

      return
    }

    store?.updateData(index, updatedItems[index])

    setItems(updatedItems)
  }

  function removeCoverImage(index: number) {
    const updatedItems = [...items]

    delete updatedItems[index].coverImage
    delete updatedItems[index].coverImageFile

    setItems(updatedItems)
  }

  function validateAmount(value: string) {
    value = value.replace(/^0/, '').replace(/\D/g, '')
    if (parseInt(value) > 1_000_000_000) return ''

    const formattedValue = BigInt(value)

    if (
      props.selectedCollection?.[props.chain]?.standard ===
        NFT_STANDARDS.erc721 &&
      formattedValue > 140
    ) {
      return String(140)
    } else if (formattedValue > 100_000_000) {
      return String(100_000_000)
    }

    return formattedValue.toString()
  }

  function validateRoyalty(value: string) {
    // value = value.replace(/^0/, '').replace(/\D/g, '')
    if (parseFloat(value) > 50) return items[currentItemIndex].royalty
    const splitCheck = value.split('.')
    if (splitCheck.length > 1) {
      value = `${splitCheck[0]}.${splitCheck[1].substring(0, 2)}`
    }

    const returnCheck =
      splitCheck[0].length > 1 && splitCheck[0].startsWith('0')

    return returnCheck ? value.slice(1) : value
  }

  async function prepareEvmItem(item: ItemUiMetadata, ipfsObject: IpfsObject) {
    try {
      if (!item?.file || !item?.file.mimeType)
        throw new Error('No file present.')

      ipfsObject.image = `ipfs://${await getIpfsHashFromFile(item.file)}`

      if (item?.coverImageFile && item?.coverImageFile.mimeType) {
        ipfsObject.coverImage = `ipfs://${await getIpfsHashFromFile(item.coverImageFile)}`
      }

      // Format traits as attributes for EVM legacy support
      if (ipfsObject?.traits && !ipfsObject?.attributes) {
        ipfsObject.attributes = ipfsObject.traits.map(
          (trait: [string, string]) => ({
            trait_type: trait[0],
            value: trait[1],
          }),
        )
      }

      return {
        tokenURI: 'ipfs://' + (await getIpfsHashFromJson(ipfsObject)),
        royalty: getContractConvertedRoyalty({
          version: item?.collectionData?.version ?? item?.version,
          royalty: ipfsObject.royalty,
        }),
        amount: item.amount,
      }
    } catch (error) {
      throw new Error('Error preparing items for EVM mint!')
    }
  }

  async function mintLayer2Items(itemsToMintArray: any[], signer: any) {
    try {
      if (itemsToMintArray.length === 0)
        throw new Error('Error processing Items for Mint!')
      if (!provider) throw new Error('No wallet detected for Transaction!')

      const mintedIds =
        (await mintEvmItems(
          props.selectedCollection?.[props.chain],
          itemsToMintArray,
          signer,
        )) || []

      if (mintedIds.length > 0) {
        for await (const tokenId of mintedIds) {
          await axios.post(
            `/sync/Godwoken/item/${props.selectedCollection?.[props.chain]?.address}/${tokenId}`,
          )
        }
      }

      return mintedIds
    } catch (error) {
      throw new Error('Error with minting EVM Items')
    }
  }

  async function onMintItems() {
    try {
      const selectedCollection = props?.selectedCollection?.[props.chain]
      const signer = props.user?.useLumos ? await provider?.getSigner() : null

      if (!selectedCollection) {
        throw new Error('Please Select Collection!')
      }

      if (isCota && Object.entries(cotaItems).length < 1) {
        throw new Error('No Items found for issuing!')
      } else if (!isCota) {
        if (items.filter((item) => item.amount === 0).length > 0) {
          throw new Error('Please specify amount more than 0 for All Items!')
        } else if (items.filter((item) => !item.name).length > 0) {
          throw new Error('Please specify Title for All Items!')
        } else if (items.filter((item) => !item.description).length > 0) {
          throw new Error('Please specify Description for All Items!')
        } else if (
          items.filter(
            (item) =>
              isNaN(Number(item.royalty)) ||
              Number(item.royalty) > 50 ||
              Number(item.royalty) < 5,
          ).length > 0
        ) {
          throw new Error('Please Input Royalty Correctly for All Items!')
        } else if (items.filter((item) => !item.category).length > 0) {
          throw new Error('Please Select Category for All Items!')
        } else if (items.filter((item) => !item.file).length > 0) {
          throw new Error('Please Upload Main file for All Items!')
        } else if (
          items.filter(
            (item) =>
              ['audio', 'video'].includes(item.file?.mimeType as string) &&
              !item.coverImageFile,
          ).length > 0
        ) {
          throw new Error(
            'Please Upload cover image for All Audio/Video Items!',
          )
        }
      }

      setCreatingItem(true)

      const mintedIds = []

      try {
        if (isCota) {
          mintedIds.push(
            await mintCotaItems(
              selectedCollection?.address,
              props.user,
              cotaItems,
              signer,
            ),
          )
        } else {
          // ASYNC for loop for all entered items
          try {
            const creator = props.user.addresses.findLast(
              (addressObject: { chain: any }) =>
                addressObject.chain ===
                (selectedCollection?.chain ?? CHAIN_KEYS.ckb),
            )?.address
            const itemsToMintArray = []
            const selectedStandard =
              selectedCollection?.standard ?? NFT_STANDARDS.spore

            let itemIndex = 0
            for await (const item of items) {
              const ipfsObject: IpfsObject = {
                name: item.name,
                description: item.description,
                creator,
                royalty: item.royalty,
                fileType: item.file!.mimeType as string,
                category: item.category,
                chain: CHAIN_KEYS.ckb,
                standard: selectedStandard,
                isNation: true,
              }

              if (item.coverImageFile)
                ipfsObject.coverImage = `ipfs://${await getIpfsHashFromFile(item.coverImageFile)}`

              item.traits = item?.traits?.filter((attribute: string[]) => {
                if (!attribute[0] && !attribute[1]) {
                  return false
                }

                return true
              })

              if (item?.traits && item?.traits?.length > 0) {
                ipfsObject.traits = item.traits
              }

              // Mint Spore(s)
              if (selectedStandard === NFT_STANDARDS.spore) {
                // IPFS Compact = 243.00000000 CKB
                // IPFS Content = 303.00000000 CKB
                // IPFS Object = 551.00000000 CKB

                mintedIds.push(
                  await mintSpore(creator, item.storageStyle, {
                    ipfsObject,
                    file: item.file!,
                    address: creator,
                    useLumos: props.user?.useLumos ?? false,
                    activeWallet: props?.user?.activeWallet,
                    provider: signer,
                    clusterId: selectedCollection?.address,
                  }),
                )

                removeItemAtIndex(itemIndex)
                itemIndex += 1
              } else if (props.chain === CHAIN_KEYS.godwoken) {
                // Prepare EVM for Mint
                ipfsObject.version =
                  (item?.version ?? selectedCollection.version) || 2
                ipfsObject.chain = CHAIN_KEYS.godwoken

                itemsToMintArray.push(await prepareEvmItem(item, ipfsObject))
              }
            }
            // End Item Mint LOOP
          } catch (error: any) {
            throw new Error(error?.message ?? 'Unknown')
          }
        }
      } catch (error) {
        throw error
      }

      if (mintedIds.length > 0) {
        console.info('Items Minted, Token IDs: ', mintedIds)
        props.setSnackBarMessage('Mint Successful! Redirecting to Profile.')

        setTimeout(
          () => history.push(`/account/${props.user.address}?tab=created`),
          2500,
        )

        store?.clearDatabase()
        window.localStorage.removeItem(COLLECTION_LOCAL_STORAGE_KEY)
      } else {
        throw new Error('Failed Transaction')
      }
    } catch (error: any) {
      console.error('Error during mint:: ', error.message)
      props.setSnackBarMessage(error.message)
    } finally {
      props.setOpenSnackbar(true)
      setCreatingItem(false)
    }
  }

  function updateItemField(
    field: keyof ItemUiMetadata | string,
    value: any,
    index: number,
    traitIndex?: number,
  ) {
    const updatedItems = [...items]
    const currentItem: ItemUiMetadata = { ...updatedItems[index] }

    if (traitIndex !== undefined && field.startsWith('traits-')) {
      const arrayIndex = Number(field.split('-')[1])

      if (!currentItem?.traits?.[traitIndex]) {
        currentItem.traits = [...(currentItem?.traits ?? [])]
        currentItem.traits[traitIndex] = ['', '']
      }

      const tempItemTraits: [string, string] = [
        ...currentItem.traits[traitIndex],
      ]

      tempItemTraits[arrayIndex] = value

      currentItem.traits[traitIndex] = tempItemTraits
    } else {
      // @ts-ignore
      currentItem[field] = value
    }

    if (field === 'storageType') {
      const storageStyle = getStorageStyleByType(
        currentItem[field] as StorageType,
      )
      if (storageStyle && currentItem[field] !== StorageType.CellData) {
        currentItem['storageStyle'] = storageStyle[1]
      } else if (storageStyle) {
        currentItem['storageStyle'] = storageStyle[0]
      }

      currentItem['file'] = null
    }

    // Update the state with the new currentItem
    updatedItems[index] = currentItem

    store?.updateData(index, updatedItems[index])
    setItems(updatedItems)
  }

  function removeCurrentItem() {
    removeItemAtIndex(currentItemIndex)
  }

  function removeItemAtIndex(index: number) {
    if (items.length === 1) {
      // Do not remove the last item
      return
    }

    store?.deleteData(index)

    const updatedItems = [...items]
    updatedItems.splice(index, 1)

    setItems(updatedItems)

    // Adjust currentItemIndex to focus on the last item
    setCurrentItemIndex((prevIndex) => Math.max(0, prevIndex - 1))
    setItemCount(itemCount - 1)
  }

  function addNewItem() {
    // Initialize with default values
    const updatedItems = [...items, emptyItem]

    setItems(updatedItems)

    // Set currentItemIndex to focus on the newly added item
    setCurrentItemIndex(updatedItems.length - 1)
    setItemCount(itemCount + 1)

    store?.updateData(itemCount, emptyItem)
  }

  function toggleTraitItem(
    type: string,
    itemIndex: number,
    attributeIndex: number,
  ) {
    // Clone the items array to avoid modifying the state directly
    const updatedItems = [...items]

    if (type === 'remove') {
      // Remove the property at the specified index from the current item
      updatedItems[itemIndex]?.traits?.splice(attributeIndex, 1)
    } else if (type === 'add') {
      // Add a new empty property to the current item
      updatedItems[itemIndex]?.traits?.splice(attributeIndex + 1, 0, ['', ''])
    }

    // Update the state with the new items array
    setItems(updatedItems)
  }

  const updateCotaItem = (index: number, updatedValue: any) => {
    setCotaItems((prevItems) => {
      const updatedItems = { ...prevItems }
      if (!updatedItems[index]) {
        updatedItems[index] = null // Initialize empty key-value pair
      }

      updatedItems[index] = updatedValue // Update value
      return updatedItems
    })
  }

  useEffect(() => {
    if (cotaItemIndex === 0 || cotaIssueTotal === 0) return

    if (Object.keys(cotaItems).length < cotaIssueTotal) {
      setCotaItems((prevItems) => ({ ...prevItems, [cotaItemIndex]: null }))
    }
  }, [cotaIssueTotal, cotaItemIndex, cotaItems])

  const renderCotaItemInputs = useMemo(() => {
    if (!isCota) return null

    const removeCharacteristic = () => {
      if (Object.keys(cotaItems).length > 1) {
        const updatedItems = { ...cotaItems }
        delete updatedItems[Object.keys(updatedItems).length - 1]
        setCotaItems(updatedItems)
      }
    }
    const selectedCollection = props?.selectedCollection?.[props?.chain]
    const totalToIssue = selectedCollection.total - selectedCollection.issued
    const startingIndex = selectedCollection.issued + 1

    setCotaIssueTotal(totalToIssue)

    if (totalToIssue === 0) {
      return (
        <h4 className="text-center">
          All items in the collection have been issued!
        </h4>
      )
    } else if (Object.keys(cotaItems).length === 0) {
      setCotaItemIndex(startingIndex)
    }

    const generateInput = (index: number) => (
      <div className="form-group characteristic" key={index}>
        <label>Item #{index}</label>
        <textarea
          className="form-control"
          placeholder="Item Characteristics (e.g. t:fire;h:100;d:50)"
          onChange={(e) => {
            const textValue = e.target.value

            if (textValue.length > 40) {
              props.setSnackBarMessage(
                'Characteristics limit is 40 characters in total',
              )
              props.setOpenSnackbar(true)
            } else {
              updateCotaItem(index, textValue)
            }
          }}
        />
        <span className="text-center">
          Character Count <br />{' '}
          <strong>
            {JSON.stringify(cotaItems[index]).replace(/\s+/g, '').length}
          </strong>
        </span>
      </div>
    )

    const itemInputs = Object.keys(cotaItems).map((index) =>
      generateInput(Number(index)),
    )

    return (
      <div id="Create--traits">
        <h4 className="title mb-0">Item Characteristics</h4>
        <h5 className="m-0 mb-3 text-center">
          <small>
            <em>
              Each characteristic is user defined, but is{' '}
              <strong>limited to 40 characters</strong> so using letters for
              keys is recommended.
            </em>
          </small>
        </h5>
        {itemInputs}
        {Object.keys(cotaItems).length < totalToIssue && (
          <button onClick={() => setCotaItemIndex(cotaItemIndex + 1)}>+</button>
        )}
        {Object.keys(cotaItems).length > 1 && (
          <button onClick={removeCharacteristic}>-</button>
        )}
      </div>
    )
  }, [isCota, props, cotaItems, cotaItemIndex])

  return (
    <article id="Create--Item">
      <header>
        <h3 className="title">{titleText}</h3>

        {props.selectedCollection &&
          props.selectedCollection[props.chain]?.address && (
            <div className="selected-collection">
              <strong>Selected Collection Address</strong>{' '}
              <em>{props.selectedCollection[props.chain].address}</em>
            </div>
          )}
      </header>
      {isCota
        ? renderCotaItemInputs
        : items &&
          items.length > 0 &&
          items.map((item, index) => (
            <div className="item" key={index}>
              <div className="grid">
                {item.coverImageFile && (
                  <div className="upload-wrapper cover">
                    <div
                      className="upload"
                      style={{
                        display: item.coverImage === true ? '' : 'none',
                      }}
                    >
                      <S.UploadCaption>
                        Add cover Image for your audio file (JPG, PNG, GIF,
                        WEBP) [<strong>Max 2MB</strong> -{' '}
                        <em>Stored on IPFS</em>)]
                      </S.UploadCaption>
                      <S.ChooseFileBtn>
                        Choose File
                        <S.FileInput
                          type="file"
                          value=""
                          accept="audio/*,video/*,image/*"
                          onChange={async (
                            event: any & { target: { files: any[] } },
                          ) => await handleCoverImageUpload(event, index)}
                        />
                      </S.ChooseFileBtn>

                      {isSpore && (
                        <div style={{ fontStyle: 'italic' }}>
                          *500kb is <strong>combined</strong> with main file
                          when using Spores, Spore splitting may come in the
                          future.
                        </div>
                      )}
                    </div>

                    {item.coverImage && (
                      <div
                        className={`preview-wrapper ${item.coverImage !== true ? 'has-file' : ''}`}
                        style={{
                          display: item.coverImage !== true ? '' : 'none',
                        }}
                      >
                        <span
                          className="close-icon"
                          style={{
                            display: item.coverImage !== true ? '' : 'none',
                          }}
                          onClick={() => removeCoverImage(index)}
                        >
                          &#9760;
                        </span>
                        <div className="preview-file">
                          <MediaWrapper
                            src={URL.createObjectURL(
                              new Blob([item.coverImageFile.buffer], {
                                type: item.coverImageFile!.mimeType as string,
                              }),
                            )}
                            assetType={
                              (item.coverImageFile?.mimeType as string)?.split(
                                '/',
                              )[0]
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="details">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Item Name"
                      value={item.name}
                      onChange={(event) =>
                        updateItemField('name', event.target.value, index)
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Item Description"
                      cols={30}
                      rows={3}
                      defaultValue={item.description}
                      onChange={(event) =>
                        updateItemField(
                          'description',
                          event.target.value,
                          index,
                        )
                      }
                      required
                    />
                  </div>
                  {hasAmountInput && (
                    <div className="form-group">
                      <label>
                        {props.selectedCollection?.[props.chain]?.standard ===
                        NFT_STANDARDS.erc1155
                          ? 'Item Quantity'
                          : 'Batch Amount**'}
                      </label>
                      <S.Input
                        className="form-control"
                        type="number"
                        min="1"
                        step="1"
                        max={
                          props.selectedCollection?.[props.chain]?.standard ===
                          NFT_STANDARDS.erc721
                            ? 140
                            : 'none'
                        }
                        value={item.amount}
                        onChange={(event: { target: { value: any } }) =>
                          updateItemField(
                            'amount',
                            validateAmount(event.target?.value),
                            index,
                          )
                        }
                        required
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>Item Royalty</label>
                    <S.Input
                      className="form-control"
                      type="number"
                      min="5.00"
                      max="50.00"
                      step="0.01"
                      value={item?.royalty}
                      onChange={(event: { target: { value: any } }) =>
                        updateItemField(
                          'royalty',
                          validateRoyalty(event.target.value),
                          index,
                        )
                      }
                      required
                    />
                    <small>
                      <strong>
                        <em>Suggested: 10%, Minimum is 5%, Maximum is 50%</em>
                      </strong>
                    </small>
                  </div>

                  <div className="form-group">
                    <label>Item Category</label>
                    <select
                      className="form-control"
                      value={item.category}
                      onChange={(event) =>
                        updateItemField('category', event.target.value, index)
                      }
                      style={{ height: 50, padding: '0 10' }}
                    >
                      {categories.map(
                        (
                          categoryItem: { [key: string]: any },
                          index: number,
                        ) => {
                          return (
                            <option
                              key={index}
                              value={categoryItem.name}
                              title={categoryItem.description}
                            >
                              {categoryItem.name}
                            </option>
                          )
                        },
                      )}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Storage Type</label>
                    <select
                      className="form-control"
                      value={item.storageType}
                      onChange={(event) =>
                        updateItemField(
                          'storageType',
                          event.target.value,
                          index,
                        )
                      }
                      style={{ height: 50, padding: '0 10' }}
                    >
                      {Object.values(StorageType).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>

                  {getStorageStyleByType(item.storageType)?.length > 1 && (
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          id={`unlockStorageStyle-${index}`}
                          className="form-check-input"
                          type="checkbox"
                          checked={!!item.unlockStorageStyle}
                          onChange={(event) =>
                            updateItemField(
                              'unlockStorageStyle',
                              event.target.checked,
                              index,
                            )
                          }
                        />
                        <label htmlFor={`unlockStorageStyle-${index}`}>
                          Advanced Storage [
                          <a
                            href="https://guide.imagination.to/resources/storage-types#storage-styles"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                            <i
                              className="fas fa-external-link ml-1"
                              aria-hidden="true"
                            />
                          </a>
                          ]
                        </label>
                      </div>
                    </div>
                  )}

                  {item?.unlockStorageStyle && item?.storageType && (
                    <div className="form-group">
                      <label>Storage Style</label>
                      <div className="info-group">
                        <select
                          className="form-control"
                          value={item.storageStyle}
                          onChange={(event) =>
                            updateItemField(
                              'storageStyle',
                              event.target.value,
                              index,
                            )
                          }
                          style={{ height: 50, padding: '0 10' }}
                          required
                        >
                          {getStorageStyleByType(item.storageType)?.map(
                            (style) => (
                              <option key={style} value={style}>
                                {style}
                              </option>
                            ),
                          )}
                        </select>
                        <i
                          className="icon icon-info"
                          title="Select the style for the chosen storage type."
                          onClick={() => {
                            const storageInfoTextWrapper: HTMLElement | null =
                              document.querySelector('#StorageInfo')

                            if (!storageInfoTextWrapper) return
                            else if (
                              storageInfoTextWrapper.style.maxHeight === '0px'
                            ) {
                              storageInfoTextWrapper.style.transition =
                                'max-height 0.5s ease-in-out'
                              storageInfoTextWrapper.style.maxHeight = `${storageInfoTextWrapper.scrollHeight}px`
                            } else {
                              storageInfoTextWrapper.style.transition =
                                'max-height 0.5s ease-in-out'
                              storageInfoTextWrapper.style.maxHeight = '0px'
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* end .details */}

                <span
                  id="StorageInfo"
                  style={{ maxHeight: 0, overflow: 'hidden' }}
                >
                  Adjusts the way data is stored in the Spore [
                  <a
                    href="https://guide.imagination.to/spores#storage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More{' '}
                    <i className="fas fa-external-link" aria-hidden="true" />
                  </a>
                  ]
                </span>

                <div
                  className={`upload-wrapper${item.coverImageFile ? ' has-cover' : ''}`}
                  style={{ position: 'relative' }}
                >
                  <div
                    className="upload"
                    style={{ display: item.file ? 'none' : '' }}
                  >
                    <S.UploadCaption>
                      Image/Audio/Video Files{' '}
                      <strong>(Max {getMaxFileSize(item.storageType)})</strong>
                    </S.UploadCaption>
                    <S.ChooseFileBtn>
                      Choose File
                      <S.FileInput
                        type="file"
                        value=""
                        accept="audio/*,video/*,image/*"
                        onChange={async (
                          event: any & { target: { files: any[] } },
                        ) => await handleMainFile(event, index)}
                      />
                    </S.ChooseFileBtn>
                  </div>
                  <div
                    className={`preview-wrapper ${item.file ? 'has-file' : ''}`}
                    style={{ display: item.file ? '' : 'none' }}
                  >
                    <div className="preview-file">
                      {item.file && (
                        <div
                          className="preview-wrapper"
                          onClick={() => removeMainFile(index)}
                        >
                          {item.file && (
                            <MediaWrapper
                              src={item.image}
                              assetType={item.mediaType}
                              fileType={item.file.mimeType}
                            />
                          )}
                        </div>
                      )}

                      <span
                        className="close-icon"
                        style={{ display: item.file ? '' : 'none' }}
                        onClick={() => removeMainFile(index)}
                      >
                        &#9760;
                      </span>
                    </div>
                  </div>
                </div>

                <div id="Create--traits">
                  <h4 className="title">
                    Item Traits <br />
                    <small>[Optional]</small>
                  </h4>
                  {Array.isArray(item?.traits) &&
                    item.traits.map(
                      (trait: [string, string], traitIndex: number) => (
                        <div className="traits-wrapper" key={traitIndex}>
                          <div className="form-group" style={{ margin: 0 }}>
                            <input
                              className="form-control"
                              value={trait[0]}
                              placeholder="Key"
                              onChange={(event) =>
                                updateItemField(
                                  'traits-0',
                                  event.target.value,
                                  index,
                                  traitIndex,
                                )
                              }
                            />
                          </div>
                          <div className="form-group" style={{ margin: 0 }}>
                            <input
                              className="form-control"
                              value={trait[1]}
                              placeholder="Value"
                              onChange={(event) =>
                                updateItemField(
                                  'traits-1',
                                  event.target.value,
                                  index,
                                  traitIndex,
                                )
                              }
                            />
                          </div>

                          <div
                            className="toggle-traits remove"
                            onClick={() =>
                              toggleTraitItem('remove', index, traitIndex)
                            }
                          >
                            <span style={{ fontSize: 32 }}>-</span>
                          </div>

                          <div
                            className="toggle-traits add"
                            onClick={() =>
                              toggleTraitItem('add', index, traitIndex)
                            }
                          >
                            <span style={{ fontSize: 32 }}>+</span>
                          </div>
                        </div>
                      ),
                    )}

                  {Array.isArray(item?.traits) &&
                    item?.traits?.length === 0 && (
                      <div
                        className="toggle-traits add solo"
                        onClick={() => toggleTraitItem('add', index, 0)}
                      >
                        <span style={{ fontSize: 32 }}>+</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}

      <hr />

      <section className="footer">
        {!isCota ? (
          props.selectedCollection?.[props.chain]?.version === 1 ? (
            <p>
              <strong>Contract must be Version 2 to use Batch Minting</strong>
            </p>
          ) : (
            <>
              <div className="button-wrapper">
                <div className="title">
                  <h3>New Item</h3>
                  <h4>
                    <small>
                      <em>Create another item in the same collection</em>
                    </small>
                  </h4>
                </div>

                <button
                  className="btn btn-danger remove"
                  onClick={() => removeCurrentItem()}
                  disabled={
                    props.selectedCollection?.[props.chain]?.version === 1
                  }
                >
                  <MinusOne />
                </button>
                <button
                  className="btn btn-success add"
                  onClick={() => addNewItem()}
                  disabled={
                    props.selectedCollection?.[props.chain]?.version === 1
                  }
                >
                  <PlusOne />
                </button>
              </div>

              <hr />
            </>
          )
        ) : null}

        {isCkb && items.length > 0 && (
          <dl className="ckb-cost">
            <dt>
              <h4>Estimated CKB Required for Capacity</h4>
            </dt>

            {Number(ckbfsCapacity) > 0 && (
              <dd>
                CKBFS Capacity: <strong>{ckbfsCapacity} CKB</strong>{' '}
                <small>
                  ({ckbfsCells} Cell{ckbfsCells > 1 ? 's' : ''})
                </small>
              </dd>
            )}

            <dd>
              Total: <strong>{renderEstimatedCapacity} CKB</strong>{' '}
              <small>
                ({items.length + ckbfsCells} Cell
                {items.length + ckbfsCells > 1 ? 's' : ''})
              </small>
            </dd>
          </dl>
        )}

        <button
          id="Create--submit"
          className="btn btn-lg btn-solid-green btn-block"
          type="submit"
          onClick={() => onMintItems()}
          disabled={creatingItem}
        >
          {creatingItem ? (
            <CircularProgress
              style={{ width: '16px', height: '16px', color: 'white' }}
            />
          ) : (
            mintItemText
          )}
        </button>

        <div className="disclaimer">
          {!isCota && (
            <>
              <p>
                <em>
                  Items being prepared to Mint are stored in your browsers{' '}
                  <a
                    href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API"
                    target="_blank"
                    rel="noopener"
                  >
                    IndexedDB
                  </a>{' '}
                  so if you have to leave the page or refresh for any reason
                  your items should remain in-tact as long as this feature is{' '}
                  <a
                    href="https://caniuse.com/indexeddb"
                    target="_blank"
                    rel="noopener"
                  >
                    supported by your browser
                  </a>
                  .
                </em>
              </p>
              <p>
                *{renderItemFee} fee applied to each {isSpore ? 'Spore' : 'NFT'}{' '}
                minted.
              </p>
            </>
          )}

          {props.selectedCollection?.[props.chain]?.standard ===
            NFT_STANDARDS.erc721 &&
            props.selectedCollection?.[props.chain].version !== 1 && (
              <p>
                ** Mints multiple ERC721 NFTs at the same time with a current{' '}
                <strong>Max of 140</strong>, all item details are the same. Each
                item will contain a unique ID, increased by 1 for each one
                minted. Batch minting will stack this logic, but{' '}
                <strong>requires a transaction for every unique item</strong>.
                Leave amount on 1 for standard unique 721 minting. E.G. Minting
                3 new items with amounts set to 5, 1, 10 respectively. This will
                trigger 3 transactions, the first one will mint the same ERC721
                5 times, than mint 1 item and finally a third transaction that
                mints 10. 5 items now have the same details, 1 item has it's own
                unique details, 10 items share the same details.
              </p>
            )}

          {Number(ckbfsCells) > 0 && (
            <p>
              ***CKBFS Files are uploaded to their own Cell and requires it's
              own Transaction. Batch File transactions will be introduced in a
              future update.
            </p>
          )}
        </div>
      </section>
    </article>
  )
}

export default Items
