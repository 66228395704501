import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'

import { useAuth } from '../../context/authContext'
import CkbSync from '../../utils/CKB/CkbSync'
import { APP_KEYS, HAS_PRIVILEGES, NFT_STANDARDS } from '../../utils/constants'
import NotFound from '../NotFound'
import { setCotaIssuer } from '../../utils/CKB/CkbService'

import './admin.scss'

const AdminDashboard = () => {
  const { state } = useAuth()
  const { user } = state
  const { connector, provider } = useWeb3React()

  const [wallet, setWallet] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [collectionAddress, setCollectionAddress] = useState('')
  const [loadingButton, setLoadingButton] = useState('')
  const [txHash, setTxHash] = useState('')
  const [hasPrivileges, setHasPrivileges] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [forceSync, setForceSync] = useState(false)
  const [syncSpores, setSyncSpores] = useState(false)

  const renderForceSyncElement = useMemo(() => {
    return (
      <span className="input-group">
        <label htmlFor="forceSync" className="mr-2">
          Force Sync?
        </label>
        <input
          id="forceSync"
          name="forceSync"
          type="checkbox"
          checked={forceSync}
          onChange={(event: any) => {
            setForceSync(event.target.checked)
          }}
        />
      </span>
    )
  }, [forceSync])

  const syncItems = useCallback(
    async (action: string) => {
      setIsSuccess(false)
      setHasError(false)
      setIsSyncing(true)

      let loading = ''
      let message = ''
      let callback

      switch (action) {
        case 'market':
          loading = 'marketSync'
          message = 'Market Sync Complete!'
          callback = CkbSync.runMarketSync()

          break

        case 'user':
          loading = 'userSync'
          message = 'User Sync Complete!'
          callback = CkbSync.runUserSync(wallet, forceSync)

          break
        case NFT_STANDARDS.spore:
          loading = 'sporeSync'
          message = 'Spore Sync Complete!'
          callback = CkbSync.runSporeSync(tokenId, collectionAddress, forceSync)
          break

        case 'cluster':
          loading = 'clusterSync'
          message = 'Cluster Sync Complete!'
          callback = axios.post(`/sync/CKB/collection/${collectionAddress}`, {
            standard: NFT_STANDARDS.spore,
            forceSync,
            syncSpores,
          })
          break

        case `${NFT_STANDARDS.cota}-User`:
          loading = 'cotaSyncUser'
          message = 'CoTA Sync Complete!'
          callback = CkbSync.runCotaSyncUser(wallet)
          break
        case `${NFT_STANDARDS.cota}-ID`:
          loading = 'cotaSyncId'
          message = 'CoTA Sync Complete!'
          callback = CkbSync.runCotaSyncId(tokenId, txHash)
          break
      }

      setLoadingButton(loading)
      try {
        await callback

        console.info(message)
        setIsSuccess(true)
      } catch (error) {
        console.error('Error running sync!')
        setHasError(true)
      } finally {
        setLoadingButton('')
        setIsSyncing(false)
        setForceSync(false)
      }
    },
    [collectionAddress, forceSync, syncSpores, tokenId, txHash, wallet],
  )

  useEffect(() => {
    if (!user?.address) return

    setHasPrivileges(HAS_PRIVILEGES?.includes(user?.address))
  }, [user?.address])

  if (!hasPrivileges) return <NotFound />

  return (
    <div id="AdminDashboard" className="container">
      <h1>Super Basic Dashboard</h1>

      {hasError && (
        <div className="alert alert-danger" role="alert">
          Error syncing data!{' '}
          <button
            className="close"
            onClick={() => setHasError(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {isSuccess && (
        <div className="alert alert-success" role="alert">
          Successfully synced data!{' '}
          <button
            className="close"
            onClick={() => setIsSuccess(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {isSyncing && (
        <div className="alert alert-warning" role="alert">
          Syncing Data, please stay on page until it has completed!{' '}
          <button
            className="close"
            onClick={() => setIsSyncing(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <h2>Sync</h2>

      <div className="action-group">
        <h3>Sync Market Lock Cells</h3>

        <button
          className={`btn branded ${loadingButton === 'marketSync' ? 'loading' : ''}`}
          onClick={async () => syncItems('market')}
        >
          Sync Market Spores
        </button>
      </div>

      <div className="action-group">
        <h3>Sync User Lock Cells</h3>

        <span className="input-group">
          <label>Wallet Address</label>
          <input
            type="text"
            value={wallet}
            onChange={(event) => setWallet(event.target.value)}
          />
        </span>

        {renderForceSyncElement}

        <button
          className={`btn branded ${loadingButton === 'userSync' ? 'loading' : ''}`}
          onClick={async () => syncItems('user')}
        >
          Submit
        </button>
      </div>

      <hr />

      <div className="action-group">
        <h3>Sync Cluster by ID</h3>

        <span className="input-group">
          <label>Collection Address(es)</label>
          <textarea
            value={collectionAddress}
            onChange={(event) => setCollectionAddress(event.target.value)}
            placeholder="<0xTokenId1>,<0xTokenId2>"
          />
        </span>

        {renderForceSyncElement}
        <span className="input-group">
          <label htmlFor="syncSpores" className="mr-2">
            Sync Spores?
          </label>
          <input
            id="syncSpores"
            name="syncSpores"
            type="checkbox"
            checked={syncSpores}
            onChange={(event: any) => {
              setSyncSpores(event.target.checked)
            }}
          />
        </span>

        <button
          className={`btn branded ${loadingButton === 'clusterSync' ? 'loading' : ''}`}
          onClick={async () => syncItems('cluster')}
        >
          Submit
        </button>
      </div>

      <hr />

      <div className="action-group">
        <h3>Sync Cell by Type Args (Token/Type ID)</h3>

        <span className="input-group">
          <label>Token ID</label>
          <textarea
            value={tokenId}
            onChange={(event) => setTokenId(event.target.value)}
            placeholder="<0xCollectionId1>,<0xCollectionId2>"
          />
        </span>

        {renderForceSyncElement}

        <button
          className={`btn branded ${loadingButton === 'sporeSync' ? 'loading' : ''}`}
          onClick={async () => syncItems(NFT_STANDARDS.spore)}
        >
          Submit
        </button>
      </div>

      <h3>CoTA</h3>

      <div className="action-group">
        <h4>By User</h4>
        <span className="input-group">
          <label>Wallet Address</label>
          <input
            type="text"
            value={wallet}
            onChange={(event) => setWallet(event.target.value)}
            placeholder="<ck1234abc...>"
          />
        </span>

        <span className="input-group">
          <label>Token ID (optional)</label>
          <input
            type="text"
            value={tokenId}
            onChange={(event) => setTokenId(event.target.value)}
            placeholder="<0xd3d1417...>"
          />
        </span>

        <button
          className={`btn branded ${loadingButton === 'cotaSyncUser' ? 'loading' : ''}`}
          onClick={async () => syncItems(`${NFT_STANDARDS.cota}-User`)}
        >
          Submit
        </button>
      </div>

      <div className="action-group">
        <h4>By ID</h4>
        <span className="input-group">
          <label>ID</label>
          <input
            type="text"
            value={tokenId}
            onChange={(event) => setTokenId(event.target.value)}
            placeholder="<0xd3d1417...>"
          />
        </span>

        <span className="input-group">
          <label>Tx Hash</label>
          <input
            type="text"
            value={txHash}
            onChange={(event) => setTxHash(event.target.value)}
            placeholder="<0xd3d1417...>"
          />
        </span>

        <button
          className={`btn branded ${loadingButton === 'cotaSyncId' ? 'loading' : ''}`}
          onClick={async () => syncItems(`${NFT_STANDARDS.cota}-ID`)}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default AdminDashboard
