import { useState, useMemo, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AuthorAvatar from './AuthorAvatar'
import MediaWrapper from '../Media/MediaWrapper'
import { CHAIN_KEYS } from '../../utils/constants'
import DOMPurify from 'dompurify'

const AuthorProfileHeader = (props: { author: any; account: any }) => {
  const { author } = props

  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }
  const handleCopy = () => {
    setSnackBarMessage('Copied!')
    setOpenSnackbar(true)
  }

  const renderCopyableAddresses = useMemo(() => {
    if (!author) return

    let addresses = []
    if (author?.addresses?.length > 0) {
      addresses.push(...author.addresses)
    } else {
      addresses.push({
        address: author.address,
        chain: 'Copy Address',
      })
    }

    return addresses
      .filter((obj) => obj.chain === 'CKB')
      .concat(addresses.filter((obj) => obj.chain !== 'CKB'))
      .map((addressObject: { [key: string]: any }) => (
        <div className="form-group" key={addressObject.address}>
          <label
            style={{ display: 'block', textAlign: 'center', fontWeight: 700 }}
          >
            {isNaN(addressObject.chain)
              ? addressObject.chain
              : Object.values(CHAIN_KEYS)[addressObject.chain]}
          </label>

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={`${addressObject.address.slice(0, 9)}...${addressObject.address.slice(addressObject.address.length - 9)}`}
              alt={addressObject.address}
              title={addressObject.address}
              readOnly
              style={{ textAlign: 'center' }}
            />
            <CopyToClipboard
              text={addressObject.address}
              onCopy={() => handleCopy()}
              children={
                <button>
                  <i className="icon-docs" />
                </button>
              }
            />
          </div>
        </div>
      ))
  }, [author])

  return (
    <header>
      {author && (
        <div className="content container card no-hover">
          <MediaWrapper
            src={author.profileCover}
            alt={`Banner for ${author.name}`}
            className="banner"
          />

          <div className="title-wrapper">
            <MediaWrapper
              src={author.profilePic ?? author?.profilePic}
              alt={`Profile Picture for ${author.name}`}
            />
            {/* <AuthorAvatar className="profilePic" author={author} hasFrame={true} src={author.profilePic} displayName={false} /> */}

            <h4 className="title">{`${author.name}`}</h4>
          </div>

          {/* Card Body */}
          <div className="body mt-3">
            {/* Render Bio*/}
            <p
              className="content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  author?.bio?.replace(/\n/g, '<br />') || '',
                ),
              }}
            />

            <h3>Address{author?.addresses?.length > 1 ? 'es' : ''}</h3>
            <div className="addresses">{renderCopyableAddresses}</div>

            {/* Social Icons */}
            <div className="social-icons d-flex justify-content-center my-3">
              {author?.facebookLink &&
                author.facebookLink.startsWith('https://facebook.com/') && (
                  <a
                    className="facebook"
                    href={author.facebookLink}
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fab fa-facebook-f" />
                    <i className="fab fa-facebook-f" />
                  </a>
                )}
              {author?.xLink &&
                author.xLink.startsWith('https://twitter.com/') && (
                  <a
                    className="twitter"
                    href={author.xLink}
                    target="_blank"
                    rel="noopener"
                  >
                    <svg
                      style={{ width: '25px', height: '100%', color: '#000' }}
                      width="1200"
                      height="1227"
                      viewBox="0 0 1200 1227"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                        fill="#fff"
                      />
                    </svg>
                    <svg
                      style={{ width: '25px', height: '100%' }}
                      width="1200"
                      height="1227"
                      viewBox="0 0 1200 1227"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                )}
            </div>
          </div>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <span onClick={(event) => handleClose(event, 'close')}>
            <IconButton size="small" aria-label="close" color="inherit">
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        }
      />
    </header>
  )
}

export default AuthorProfileHeader
