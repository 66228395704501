import type { Address, Cell, CellDep, OutPoint } from '@ckb-lumos/lumos'
import type { CkbfsData } from '@imagination/common'

import {
  blockchain,
  bytes,
  option,
  table,
  vector,
} from '@ckb-lumos/lumos/codec'
import { helpers, utils } from '@ckb-lumos/lumos'
import { common } from '@ckb-lumos/lumos/common-scripts'
import adler32 from 'adler-32'

import {
  CKBFS_CODE_HASH,
  FileObject,
  NETWORK,
  SporeMintArgs,
} from '@imagination/common'
import CkbController from '../CKB/CkbController'

const CKBFS_CELL_DEP: CellDep =
  NETWORK === 'mainnet'
    ? // Mainnet
      {
        depType: 'depGroup',
        outPoint: {
          txHash:
            '0xbb432a40e9e29f9c3b802d9f6ab36ec11cbb6468b9b2681f46a87379ea85b2b0',
          index: '0x0',
        },
      }
    : // Testnet
      {
        depType: 'depGroup',
        outPoint: {
          txHash:
            '0x469af0d961dcaaedd872968a9388b546717a6ccfa47b3165b3f9c981e9d66aaa',
          index: '0x0',
        },
      }

const Indexes = vector(blockchain.Uint32)
const BackLink = table(
  {
    tx_hash: blockchain.Bytes,
    index: Indexes,
    checksum: blockchain.Uint32,
  },
  ['tx_hash', 'index', 'checksum'],
)
const CKBFSData = table(
  {
    index: Indexes,
    checksum: blockchain.Uint32,
    content_type: blockchain.Bytes,
    filename: blockchain.Bytes,
    backlinks: vector(BackLink),
  },
  ['index', 'checksum', 'content_type', 'filename', 'backlinks'],
)

// TypeID
// testnet: 0x88ef4d436af35684a27edda0d44dd8771318330285f90f02d13606e095aea86f

function generateTypeId(
  outPoint: OutPoint,
  outputIndex: string | number | bigint,
) {
  if (!outPoint || !outputIndex) {
    throw new Error('Cannot generate TypeId without valid arguments!')
  }

  const script = utils.generateTypeIdScript(
    {
      previousOutput: outPoint,
      since: '0x0',
    },
    '0x'.concat(BigInt(outputIndex).toString(16)),
  )

  if (!script?.args) throw new Error('Error generating Type ID Script!')

  return script.args
}

export function generateCkbfsCell(
  userAddress: Address,
  file: FileObject,
): {
  cell: Cell
  ckbfsWitnesses: string[]
} {
  const textEncoder = new TextEncoder()
  const MAX_FILENAME_LIMIT = 44

  let fileName = file?.name ?? ''

  const fileExtMatch = fileName.split('.')
  const fileExt = '.'.concat(fileExtMatch[fileExtMatch.length - 1])

  if (fileName?.length > MAX_FILENAME_LIMIT) {
    fileName = fileName?.slice(0, MAX_FILENAME_LIMIT - fileExt.length) + fileExt
  }

  const chunkSize = 30 * 1024
  const fileChunks: ArrayBuffer[] = []
  const fileArrayBuffer = file.buffer
  const checksum = adler32.buf(new Uint8Array(bytes.bytify(fileArrayBuffer)))
  const ckbfsWitnessStart = bytes
    .hexify(textEncoder.encode('CKBFS'))
    .concat('00')
  for (let i = 0; i < fileArrayBuffer.byteLength; i += chunkSize) {
    fileChunks.push(fileArrayBuffer.slice(i, i + chunkSize))
  }

  const ckbfsWitnesses = fileChunks.map((chunk) =>
    ckbfsWitnessStart.concat(bytes.hexify(chunk).slice(2)),
  )

  const ckbfsData = {
    content_type: bytes.hexify(textEncoder.encode(file.mimeType as string)),
    filename: bytes.hexify(textEncoder.encode(fileName)),
    checksum: checksum < 0 ? checksum >>> 0 : checksum,
    backlinks: [],
    index: Array.from({ length: ckbfsWitnesses.length }, (_, i) => 1 + i),
  }

  // const textDecoder = new TextDecoder()
  // console.info('ckbfsData', {
  //   ...ckbfsData,
  //   filename: textDecoder.decode(bytes.bytify(ckbfsData.filename)),
  //   content_type: textDecoder.decode(bytes.bytify(ckbfsData.content_type)),
  // })

  return {
    cell: {
      cellOutput: {
        capacity: '0x0',
        lock: helpers.parseAddress(userAddress),
        type: {
          codeHash: CKBFS_CODE_HASH,
          hashType: 'data1',
          args: '0x'.concat('0'.repeat(64)),
        },
      },
      data: bytes.hexify(CKBFSData.pack(ckbfsData)),
    },
    ckbfsWitnesses,
  }
}

export function calculateCkbfsCellSize(
  userAddress: Address,
  file: FileObject,
): bigint {
  return helpers.minimalCellCapacity(generateCkbfsCell(userAddress, file).cell)
}

export async function mintCkbfsCell(userAddress: Address, data: SporeMintArgs) {
  const file = data?.file

  if (!file) throw new Error('No File to Mint!')
  try {
    console.info('CKBFS_CODE_HASH', CKBFS_CODE_HASH)

    const { cell: fileCell, ckbfsWitnesses } = generateCkbfsCell(
      userAddress,
      file,
    )

    const neededCapacity = helpers.minimalCellCapacity(fileCell)

    fileCell.cellOutput.capacity = '0x'.concat(neededCapacity.toString(16))

    let txSkeleton = helpers.TransactionSkeleton({
      cellProvider: CkbController.indexer,
    })

    const feeRate = CkbController.calculateFee(
      BigInt(data.file?.buffer?.byteLength ?? 10),
      1500n,
    )

    txSkeleton = await common.injectCapacity(
      txSkeleton,
      [userAddress],
      neededCapacity + feeRate, // Fee rate
    )

    const firstInput = txSkeleton.get('inputs').first()

    if (!firstInput?.outPoint)
      throw new Error('No Cell found for Inputs to Mint CKBFS Cell!')

    const witnessIndex = txSkeleton.get('witnesses').size
    const typeId = generateTypeId(
      firstInput.outPoint,
      txSkeleton.get('witnesses').size,
    )

    console.info('New CKBFS TypeId', typeId)

    fileCell.cellOutput.type!.args = typeId

    txSkeleton = helpers.addCellDep(txSkeleton, CKBFS_CELL_DEP)
    txSkeleton = txSkeleton.update('outputs', (outputs) =>
      outputs.push(fileCell),
    )

    txSkeleton = txSkeleton.update('witnesses', (witnesses) =>
      witnesses.push(...ckbfsWitnesses),
    )

    const txHash = await CkbController.sendTransaction(
      await CkbController.signTx(txSkeleton, 'mint', data),
    )

    return {
      txHash,
      witnessIndex,
      typeId,
    }
    // 'CKBFS
  } catch (error) {
    console.error('Error generating CKBFS Cell', error)
    throw new Error('Error generating CKBFS Cell')
  }
}

function verifyChecksum(fileBuffer: Iterable<number>, checksum: number) {
  const calculatedChecksum = adler32.buf(new Uint8Array(fileBuffer))

  return (
    calculatedChecksum === checksum || calculatedChecksum === checksum >>> 0
  )
}
