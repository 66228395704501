import { Contract, formatUnits } from 'ethers'
import { ensureMillisecondsFormat } from '@imagination/common'
import { fileTypeFromBuffer } from 'file-type'

import { NETWORK } from './constants'
import { CONTRACTS_BY_NETWORK } from './contracts'

export function prettyFormat(value, unitName, points) {
  const p = points || 8
  return +(+formatUnits(value, unitName)).toFixed(p)
}

export function getShortAddress(address) {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
}

export function getUserName(obj) {
  let name = obj?.name
  if (!name) {
    if (!obj?.address) {
      if (typeof obj === 'string') return getShortAddress(obj)

      return obj
    }
    name = getShortAddress(obj.address)
  }

  return name
}

export function prettyCommaFormat(
  inputPrice,
  decimals = 0,
  isFormatted = false,
) {
  if (isFormatted) return inputPrice

  if (!inputPrice || isNaN(Number(inputPrice)) || Number(inputPrice) === 0)
    return 0
  inputPrice = BigInt(inputPrice).toString()

  const sliceAmount = decimals

  let decimalDigits = inputPrice.slice(-sliceAmount)

  decimalDigits =
    Number(decimalDigits) !== 0
      ? `.${decimalDigits.replace(/0+$/, '').substr(0, decimals)}`
      : ''

  decimalDigits = Number(decimalDigits) > 0 ? decimalDigits : ''

  inputPrice = inputPrice.substring(0, inputPrice.length - sliceAmount)

  const locale = navigator.language || 'en-US'
  const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 0 })
  const formattedPrice = formatter.format(inputPrice)

  if (formattedPrice.includes('.')) {
    decimalDigits = decimalDigits.replace('.', ',')
  }

  return `${formattedPrice}${decimalDigits}`
}

export const prettyDecimalFormatInput = (inputPrice, decimals) => {
  if (!inputPrice || isNaN(Number(inputPrice)) || Number(inputPrice) === 0)
    return 0
  inputPrice = BigInt(inputPrice).toString()

  const decimalPlaces = inputPrice.slice(-decimals)

  inputPrice = BigInt(inputPrice.substring(0, inputPrice.length - decimals))

  return parseFloat(`${inputPrice.toString()}.${decimalPlaces}`)
}

export const dateFormat = (timestamp) => {
  if (!timestamp) return
  timestamp = ensureMillisecondsFormat(timestamp)

  const dateString = new Date(timestamp).toDateString()

  return dateString
}

export function tryAlternateUri(uriToCheck, count) {
  const replacementUriArray = [
    'ipfs://',
    '//imagination.mypinata.cloud/ipfs/',
    '//nft-nation.mypinata.cloud/ipfs/',
    '//aquamarine-ready-mouse-695.mypinata.cloud/ipfs/',
    '//gateway.pinata.cloud/ipfs/',
    '//godwoken.mypinata.cloud/ipfs/',
    '//ipfs.io/ipfs/',
    '//gateway.ipfs.io/ipfs/',
    '//cloudflare-ipfs.com/ipfs/',
    '//dweb.link/ipfs/',
    '//godwoken.mypinata.cloud/ipfs/',
  ]

  let imageUri = '/404-image-not-found.jpg'

  if (uriToCheck && (uriToCheck !== '' || uriToCheck !== imageUri)) {
    const uriToReplace = replacementUriArray.find((uri) =>
      uriToCheck?.includes(uri),
    )

    if (count === 1) {
      imageUri = `${uriToCheck?.replace(uriToReplace, '//imagination.mypinata.cloud/ipfs/')}`
    } else if (count === 2) {
      imageUri = uriToCheck?.replace(
        uriToReplace,
        '//gateway.pinata.cloud/ipfs/',
      )
    } else if (count === 3) {
      imageUri = uriToCheck?.replace(uriToReplace, '//ipfs.io/ipfs/')
    } else if (count === 4) {
      imageUri = uriToCheck?.replace(uriToReplace, '//gateway.ipfs.io/ipfs/')
    } else if (count === 5) {
      imageUri = uriToCheck?.replace(
        uriToReplace,
        '//cloudflare-ipfs.com/ipfs/',
      )
    } else if (count === 6) {
      imageUri = uriToCheck?.replace(uriToReplace, '//cf-ipfs.com/ipfs/')
    } else if (count === 7) {
      imageUri = uriToCheck?.replace(uriToReplace, '//dweb.link/ipfs/')
    }
  }

  return imageUri
}

export async function getNftRoyalty(data, type, library) {
  const tempItem = { ...data }
  let royalty = Number(tempItem.royalty) || 0

  if (type === 'item' && tempItem?.itemCollection) {
    tempItem.version = tempItem?.collection?.version
    tempItem.address = tempItem?.itemCollection
  } else {
    tempItem.address = tempItem?.collection?.address
  }

  try {
    if (library && !royalty) {
      const nftContract = getNftContract(
        tempItem?.address,
        library,
        tempItem?.standard,
        tempItem.version,
      )

      if (nftContract) {
        royalty =
          type === 'item'
            ? Number(await nftContract.royalties(tempItem.tokenId))
            : Number(await nftContract.royalty())
      }
    }
  } catch {
    console.error('Error contacting NFT Contract for Royalty')
  }

  if (royalty === 0) return royalty

  if (Number(tempItem?.version) === Number(2)) {
    return royalty / 100
  } else {
    return royalty / 10
  }
}

export function getRenderReadyRoyalty(itemData: {
  version: any
  royalty: any
}): number {
  if (!itemData?.royalty || isNaN(itemData.royalty)) return 0

  if (
    typeof itemData.royalty === 'number' &&
    !Number.isInteger(itemData.royalty)
  ) {
    return itemData.royalty
  }

  if (Number(itemData?.version) === Number(1)) {
    return itemData.royalty / 10
  } else if (Number(itemData.royalty) > 0) {
    return itemData.royalty / 100
  }

  return 0
}

/**
 *
 * @param {{ version: any; royalty: number | string }} nft
 * @returns {number}
 */
export function getContractConvertedRoyalty(nft) {
  if (!nft?.royalty || isNaN(nft.royalty)) return 0

  if (Number(nft?.version) === Number(1)) {
    return nft.royalty * 10
  } else {
    return nft.royalty * 100
  }
}

export function ascii_to_hex(string) {
  var tempArray = []
  for (var n = 0, l = string.length; n < l; n++) {
    var hex = Number(string.charCodeAt(n)).toString(16)
    tempArray.push(hex)
  }
  return `0x${tempArray.join('')}`
}

export function getContractInfo(name) {
  const contracts = CONTRACTS_BY_NETWORK[NETWORK]

  if (contracts) {
    return contracts?.[name]
  } else {
    return null
  }
}

export function getContractObj(name, provider) {
  const info = getContractInfo(name)
  return !!info && new Contract(info.address, info.abi, provider)
}

export function getNftContract(address, provider, type, version = 1) {
  let name = `Nation${type}`

  if (version === 2) name = name.concat('V2')

  const info = getContractInfo(name)

  return !!info && new Contract(address, info.abi, provider)
}

export function getContractViaAddress(address, provider) {
  const contracts = CONTRACTS_BY_NETWORK[NETWORK]

  let contract

  for (const key in contracts) {
    if (contracts.hasOwnProperty(key)) {
      const contractObject = contracts[key]

      if (contractObject?.address === address) {
        contract = new Contract(
          contractObject.address,
          contractObject.abi,
          provider,
        )
        break
      }
    }
  }

  return contract
}

export function checkUserAddresses(userAddresses, addressToCheck) {
  if (typeof addressToCheck !== 'string' || !userAddresses?.length) return false

  addressToCheck = addressToCheck.toLowerCase()

  const addressFound = userAddresses.find(
    (userAddressObject) =>
      userAddressObject.address?.toLowerCase() === addressToCheck,
  )

  return !!addressFound
}

export async function getMimeType(data?: Uint8Array | ArrayBuffer) {
  if (!data) return 'image/png' // Assumption for now

  const checkData =
    typeof data === 'string' ? Buffer.from(data, 'base64') : data

  return (await fileTypeFromBuffer(checkData))?.mime ?? 'image/png'
}
