import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAuth } from '../context/authContext'
import {
  CONNECTOR_STORAGE_KEY,
  connectors,
  tryConnectToProvider,
} from '../utils/connectors'

export function useInactiveListener(suppress = false) {
  const web3React = useWeb3React()
  const { dispatch } = useAuth()
  const { connector, error } = web3React

  const [activateError, setActivateError] = useState()
  const connectorKey = window.localStorage.getItem(CONNECTOR_STORAGE_KEY)

  // @TODO: Properly update with new Web3-react version if logging out is not a desired outcome
  useEffect(() => {
    if (suppress) {
      return () => {}
    }
    const { ethereum } = window
    if (ethereum && ethereum.on && !error) {
      const handleChainChanged = (chainId) => {
        console.info('chainChanged', chainId)
        dispatch({ type: 'LOGOUT' })
      }

      const handleAccountsChanged = (accounts) => {
        console.info('accountsChanged', accounts)
        if (accounts.length > 0 && connectorKey) {
          tryConnectToProvider(
            connectors.find((connector) => connector.key === connectorKey),
            dispatch,
          )
        } else if (!connectorKey) {
          dispatch({ type: 'LOGOUT' })
        }
      }

      const handleNetworkChanged = (networkId) => {
        console.info('networkChanged', networkId)
        dispatch({ type: 'LOGOUT' })
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }

    if (error) {
      setActivateError(error)
    }

    return () => {}
  }, [dispatch, connector, error, suppress, connectorKey])

  return { activateError }
}
