import { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  ensureMillisecondsFormat,
  NERVAPE_GEAR_CLUSTER_IDS,
} from '@imagination/common'

import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'
import * as CS from '../../custom/componentStyle'
import timerClock from '../../assets/img/timerClock.png'
import { useAuth } from '../../context/authContext'

import {
  ACTIVITY_CONSTANTS,
  CHAIN_KEYS,
  ItemUiMetadata,
  NETWORK,
  NFT_STANDARDS,
  SYMBOL_KEYS,
} from '../../utils/constants'
import { getRenderReadyRoyalty, prettyCommaFormat } from '../../utils/helpers'
import SaveNft from './Save'
import MediaWrapper from '../Media/MediaWrapper'
// import NftChainLogo from './NftChainLogo'

import './nft.scss'

const NFT = (props: {
  cardType: any
  displayPrice: any
  item: ItemUiMetadata
  transfer: any
}) => {
  const { cardType, displayPrice, item: data, transfer } = props
  const { state: authState } = useAuth()
  const { user }: { user: any } = authState

  const [auctionStatus, setAuctionStatus] = useState('')
  const [auctionStatusMessage, setAuctionStatusMessage] = useState('')
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [pageUri, setPageUri] = useState('')
  const [collectionRoyalty, setCollectionRoyalty] = useState(0)

  const [cardButton, setCardButtonText] = useState(
    cardType === 'collection' ? (
      <>
        <i className="icon-notebook" />
        <span>View collection</span>
      </>
    ) : (
      <button className="btn btn-bordered btn-block">
        <i className="icon-eye" />
        <span>View Item</span>
      </button>
    ),
  )

  useEffect(() => {
    if (!data) return
    if (!data?.activities?.length) return

    const currentTimestamp = new Date().getTime()
    const startDate = ensureMillisecondsFormat(data?.startAt ?? data?.startTime)
    const endDate = ensureMillisecondsFormat(data?.endAt ?? data?.endTime)
    const auctionRunning =
      currentTimestamp > startDate && currentTimestamp < endDate

    const setNewTime = () => {
      const currentTimestamp = new Date().getTime()
      let countdownDate = 0

      if (auctionRunning) {
        setAuctionStatus('IN_PROGRESS')
        countdownDate = endDate
        setAuctionStatusMessage('Auction In Progress')
      } else if (currentTimestamp < startDate) {
        setAuctionStatusMessage('Auction Not Started')
        setAuctionStatus('NOT_STARTED')
      } else if (currentTimestamp > endDate) {
        setAuctionStatusMessage('Auction Ended')
        setAuctionStatus('ENDED')
      } else {
        setAuctionStatusMessage('')
        setAuctionStatus('UNKNOWN')
      }

      if (countdownDate) {
        const distanceToDate = countdownDate - currentTimestamp
        let days: string | number = Math.floor(
          distanceToDate / (1000 * 60 * 60 * 24),
        )
        let hours: string | number = Math.floor(
          (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        let minutes: string | number = Math.floor(
          (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
        )
        let seconds: string | number = Math.floor(
          (distanceToDate % (1000 * 60)) / 1000,
        )

        const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

        if (numbersToAddZeroTo.includes(days)) {
          days = `0${days}`
        }
        if (numbersToAddZeroTo.includes(hours)) {
          hours = `0${hours}`
        }
        if (numbersToAddZeroTo.includes(minutes)) {
          minutes = `0${minutes}`
        }
        if (numbersToAddZeroTo.includes(seconds)) {
          seconds = `0${seconds}`
        }

        setState({
          days: Number(days),
          hours: Number(hours),
          minutes: Number(minutes),
          seconds: Number(seconds),
        })
      }
    }

    const interval = setInterval(() => {
      if (auctionRunning) {
        setNewTime()
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [data])

  useEffect(() => {
    if (cardType === 'collection' && data?.address) {
      setPageUri(`/collection/${data.address}`)
    } else if (data?.itemCollection && data?.tokenId) {
      setPageUri(`/item-details/${data.itemCollection}/${data.tokenId}`)
    }
  }, [data, cardType])

  useEffect(() => {
    const royalty = data?.royalty
    // @TODO: refactor to use for items or remove
    if (!royalty || cardType !== 'collection') return

    setCollectionRoyalty(
      getRenderReadyRoyalty({ version: data?.version ?? 2, royalty }),
    )
  }, [cardType, data])

  const saleAmount = useMemo(() => {
    if (!data || !data.activities) return 0n
    if (!user?.address) return 0n

    const listedAmount =
      data?.activities
        ?.filter(
          (activity: { owner: { address: string }; type: number }) =>
            activity?.owner?.address?.toLowerCase() ===
              user?.address?.toLowerCase() &&
            activity.type === ACTIVITY_CONSTANTS.ListingCreated &&
            activity.type === ACTIVITY_CONSTANTS.AuctionCreated,
        )
        ?.reduce(
          (
            accum: string | number,
            auction: { amount: string | number | bigint | boolean },
          ) => BigInt(accum) + BigInt(auction.amount),
          0n,
        ) || 0n

    return listedAmount
  }, [data, user?.address])

  const ownedAmount = useMemo(() => {
    if (!data?.owners?.length) return 0n
    if (!user?.address) return 0n

    return data.owners
      .filter((owner: { address: any }) => owner.address === user.address)
      .reduce(
        (
          accum: string | number,
          owner: { amount: string | number | bigint | boolean },
        ) => BigInt(accum) + BigInt(owner.amount),
        0n,
      )
  }, [data?.owners, user?.address])

  const totalAmount = useMemo(() => {
    if (!data?.owners) return 0n

    return (
      data?.owners?.reduce(
        (
          accum: string | number,
          owner: { amount: string | number | bigint | boolean },
        ) => BigInt(accum) + BigInt(owner.amount),
        0n,
      ) ?? 0n
    )
  }, [data?.owners])

  const renderMedia = useMemo(() => {
    let image: string = data?.image ?? ''

    if (
      data?.assetType === 'audio' &&
      typeof data?.coverImageFile === 'string'
    ) {
      image = data?.coverImageFile
    }

    return (
      <div
        className={
          NERVAPE_GEAR_CLUSTER_IDS[NETWORK].includes(
            data?.itemCollection as `0x${string}`,
          )
            ? 'media is-nervape'
            : 'media'
        }
      >
        <MediaWrapper
          src={image}
          assetType={data?.assetType ?? 'image'}
          file={data.file}
          fileType={data?.fileType}
          item={data}
          isCard={true}
        />
      </div>
    )
  }, [data])

  function getStatus() {
    if (auctionStatus) return auctionStatus.toLowerCase()

    let status = 'none'

    // Logic if we bring an icon for fixed sales
    // const pairAmount = item.pairs
    //   ?.filter((pair) => pair.status === ACTIVITY_CONSTANTS.ListingCreated)
    //   ?.reduce((accum, pair) => (accum + BigInt(pair.amount)), 0n) || 0n
    // if(pairAmount > 0) status = 'sale'

    return status
  }

  const renderSaleInfo = useMemo(() => {
    const defaultInfo = <h5 className="nft-info">Not for sale</h5>
    if (!data || !data?.activities?.length) return defaultInfo

    const lowestValueObject =
      data.activities
        .slice()
        .filter(
          (activity: { type: number }) =>
            activity.type === ACTIVITY_CONSTANTS.AuctionCreated ||
            activity.type === ACTIVITY_CONSTANTS.ListingCreated,
        )
        .sort((a: { price: any }, b: { price: any }) => {
          const bigIntA = BigInt(a?.price || 0)
          const bigIntB = BigInt(b?.price || 0)

          if (bigIntA < bigIntB) {
            return -1
          } else if (bigIntA > bigIntB) {
            return 1
          } else {
            return 0
          }
        })[0] || null

    if (!lowestValueObject) return defaultInfo

    let label = 'Lowest Price'
    if (lowestValueObject?.type === ACTIVITY_CONSTANTS.AuctionCreated) {
      label = 'Starting Bid'

      if (data?.item?.bids?.length > 0) {
        label = 'Highest Bid'
      }
    }

    if (lowestValueObject.type === ACTIVITY_CONSTANTS.ListingCreated) {
      setCardButtonText(
        <button className="btn btn-block btn-outline-success">
          <i className="icon-handbag" />
          <span>View Sale</span>
        </button>,
      )
    } else if (lowestValueObject.type === ACTIVITY_CONSTANTS.AuctionCreated) {
      setCardButtonText(
        <button className="btn btn-block btn-outline-success">
          <i className="icon-handbag" />
          <span>View Auction</span>
        </button>,
      )
    }

    const decimals = data?.chain === CHAIN_KEYS.ckb ? 8 : 18

    return (
      <>
        <h4 className="label">{label}</h4>
        <h5
          className="nft-info price"
          title={`${data?.name} ${
            lowestValueObject?.type === ACTIVITY_CONSTANTS.AuctionCreated
              ? 'placed on Auction, starting at'
              : 'list for sale, priced at'
          } ${prettyCommaFormat(lowestValueObject.price, decimals)} ${SYMBOL_KEYS.godwoken}`}
        >
          {prettyCommaFormat(lowestValueObject.price, decimals)}{' '}
          {data?.chain === CHAIN_KEYS.ckb
            ? SYMBOL_KEYS.ckb
            : SYMBOL_KEYS.godwoken}
        </h5>
      </>
    )
  }, [data])

  return (
    <>
      {data && (
        <Link
          to={pageUri}
          className={`nft-card-wrapper ${cardType === 'collection' && data?.isFeatured ? 'isFeatured' : ''}`.trimEnd()}
        >
          <div className={`nft-card ${cardType ? cardType : ''}`.trimEnd()}>
            <div className="header">
              {cardType !== 'collection' && data.assetType !== 'image' && (
                <>
                  {/* <NftChainLogo standard={data?.item?.standard ?? data?.standard} /> */}

                  <span className="asset-type">
                    {
                      data.assetType === 'video' ? (
                        <CS.VideoIcon color="white" size={16} />
                      ) : data.assetType === 'audio' ? (
                        <CS.AudioIcon color="white" size={16} />
                      ) : (
                        <CS.ImageIcon color="white" size={16} />
                      )
                      // : <CS.ImageIcon color='white' size={16} />
                    }
                  </span>
                </>
              )}

              {data?.isFeatured && cardType === 'collection' && (
                <span className="featured-flag">
                  <StarTwoToneIcon /> <span>Featured</span>
                </span>
              )}

              {renderMedia}

              <SaveNft {...props} data={data} />

              <span className={`nft-sale-status ${getStatus()}`}>
                {auctionStatus === 'IN_PROGRESS' ? (
                  <>
                    <img
                      src={timerClock}
                      alt="TimerClock"
                      loading="lazy"
                      crossOrigin="anonymous"
                    />
                    <span>{`${state.days || '00'}:${state.hours || '00'}:${state.minutes || '00'}:${state.seconds || '00'}`}</span>
                  </>
                ) : (
                  `${auctionStatusMessage}`
                )}
              </span>
            </div>

            {/* Card Body */}
            <div className="body">
              <div className="title-wrapper">
                {data?.name && (
                  <>
                    <h3 className="title" title={data.name}>
                      {data.name.length > 40
                        ? data.name.substring(0, 40).concat('...')
                        : data.name}
                    </h3>

                    <hr />
                  </>
                )}
              </div>

              {cardType !== 'collection' && data?.collectionData?.name && (
                <h5 className="collection-info">{data.collectionData.name}</h5>
              )}

              {cardType !== 'collection' && displayPrice && (
                <div className="item-pricing d-flex flex-column">
                  {renderSaleInfo}
                </div>
              )}

              {/* Disabled for now, amount not always correct & makes card extra long for possibly bad data... */}
              {false &&
                cardType !== 'collection' &&
                data?.standard === NFT_STANDARDS.erc1155 &&
                ownedAmount !== 0 && (
                  <div className="item-pricing d-flex flex-column">
                    <span className="label">Owned Amount</span>
                    <span className="nft-info-amount">
                      {`${(saleAmount + ownedAmount).toString()} / ${totalAmount.toString()}`}
                    </span>
                  </div>
                )}

              {/* @TODO: Add Owner field for ERC721 */}

              {cardType === 'collection' && (
                <div className="collection-stats">
                  {/* <div><strong>Royalty</strong><div style={{ marginTop: '0.25rem' }}>{collectionRoyalty} %</div></div> */}
                  <div>
                    <strong>Standard</strong>
                    <div style={{ marginTop: '0.25rem' }}>{data.standard}</div>
                  </div>
                </div>
              )}
            </div>
            {/* end card body */}

            {cardType !== 'collection' && (
              <section className="footer">
                <div style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                  {cardButton}

                  {transfer && (
                    <>
                      <button
                        className="btn btn-primary btn-block mt-2"
                        data-toggle="modal"
                        data-target="#transfer-modal"
                        data-item={JSON.stringify(data)}
                      >
                        Transfer NFT
                      </button>
                      <button
                        className="btn btn-warn btn-block"
                        data-toggle="modal"
                        data-target="#transfer-modal"
                        data-item={JSON.stringify(data)}
                        data-burn="true"
                      >
                        Burn NFT
                      </button>
                    </>
                  )}
                </div>
              </section>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default NFT
