import React from 'react'
import MainNav from '../Header/MainNav'

const ModalMenu = () => {
  return (
    <div id="menu" className="modal fade p-0">
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            Menu <i className="far fa-times-circle icon-close" />
          </div>
          <div className="menu modal-body">
            <nav className="inner">
              <MainNav />

              <button
                className="nav-link search"
                data-toggle="modal"
                data-target="#search-modal"
                onClick={() => {
                  const searchModal = document?.querySelector('#search-modal')
                  if (!searchModal) return

                  searchModal.classList.add('show')
                  searchModal.style.display = 'block'
                }}
              >
                <i className="fa-solid fa-search" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalMenu
