import React, { Component, useMemo, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

interface SearchInputProps {
  formValue: string
  showSubmit: boolean
  onSearchTermChange: (term: string) => void
}

const SearchInput: React.FC<SearchInputProps & RouteComponentProps> = (props: {
  [key: string]: any
}) => {
  const { formValue, onSearchTermChange, showSubmit } = props
  const location = useLocation()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState(formValue ?? '')

  useMemo(() => {
    const searchQuery = new URLSearchParams(location?.search).get('searchTerm')

    if (searchQuery === null && !formValue) {
      setSearchTerm('')
      return
    }

    setSearchTerm(searchQuery || formValue)
  }, [])

  const submitSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    history.push(`/search?searchTerm=${searchTerm}`)

    const modalBackdrop: HTMLElement | null = document.querySelector(
      '.modal-backdrop.fade.show',
    )
    const searchModalHeader: HTMLElement | null = document?.querySelector(
      '#search-modal .modal-header',
    )

    if (searchModalHeader !== null) {
      searchModalHeader.click()
    }

    if (modalBackdrop !== null) {
      modalBackdrop.classList.remove('show')
      modalBackdrop.style.display = 'none'
    }

    document.getElementById('menu')?.click()
  }

  return (
    <form
      id="SearchInput"
      className={showSubmit !== false ? 'hasSubmit' : ''}
      onSubmit={submitSearch}
    >
      <input
        type="text"
        placeholder="Enter your keywords"
        onChange={(event) => {
          setSearchTerm(event.target.value)
          if (onSearchTermChange)
            onSearchTermChange('searchTerm', event.target.value)
        }}
        value={searchTerm}
      />

      {showSubmit !== false && (
        <button className="btn btn-success">Search</button>
      )}
    </form>
  )
}

export default withRouter(SearchInput)
