import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../context/authContext'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { Snackbar, IconButton, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import { helpers } from '@ckb-lumos/lumos'
import { getDexLockScript } from '@nervina-labs/ckb-dex'
import {
  ensureMillisecondsFormat,
  NERVAPE_GEAR_CLUSTER_IDS,
} from '@imagination/common'

import {
  ACTIVITY_CONSTANTS,
  CHAIN_KEYS,
  DEAD_ADDRESS,
  HIDDEN_ADDRESSES,
  NETWORK,
  NFT_STANDARDS,
  SYMBOL_KEYS,
} from '../../utils/constants'
import { getBalance, getItemCapacity } from '../../utils/CKB/CkbService'
import {
  getTokenBalance,
  delistItem as delistEvmItem,
  buy as buyEvmNft,
  finalizeAuction,
  bidOnAuction,
  getMinimumBidPercent,
  getAuctionBidsLength,
} from '../../utils/EVM/EvmService'
import 'react-datepicker/dist/react-datepicker.css'
import * as S from './styles'
import ClockItem from '../Item/ClockItem'
import {
  checkUserAddresses,
  getContractInfo,
  getContractViaAddress,
  getRenderReadyRoyalty,
  prettyCommaFormat,
  prettyDecimalFormatInput,
} from '../../utils/helpers'
import TransferModal from '../Modal/Transfer'
import XShareModal from '../Modal/XShare'
import AuthorAvatar from '../Author/AuthorAvatar'
import SaveNft from '../Item/Save'
import MediaWrapper from '../Media/MediaWrapper'
import NftActivity from '../Item/NftActivity'
import MarketModal from '../Modal/Market'
import { buySpore, delistSpore } from '../../utils/CKB/CkbMarket'

import './ItemDetail.scss'

const ItemDetails = (props) => {
  const { state } = useAuth()
  const { user, provider: providerName } = state
  const { collection: collectionAddress, id } = useParams()
  const { connector, provider } = useWeb3React()
  const history = useHistory()

  const [item, setItem] = useState(null)
  const [balance, setBalance] = useState(0n)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [showPlaceBidModal, setShowPlaceBidModal] = useState('')
  const [showBuyNowModal, setShowBuyNowModal] = useState('')
  const [showEndAuction, setShowEndAuction] = useState('')
  const [showUnlistMarketPlace, setShowUnlistMarketPlace] = useState('')
  const [showPutMarketPlace, setShowPutMarketPlace] = useState(false)
  const [bidPrice, setBidPrice] = useState(0n)

  const [cancelAuction, setCancelAuction] = useState(false)
  const [delistingStatus, setDelistingStatus] = useState(false)
  const [buyingStatus, setBuyingStatus] = useState(false)
  const [endingAuctionStatus, setEndingAuctionStatus] = useState(false)
  const [biddingStatus, setBiddingStatus] = useState(false)
  const [amount, setAmount] = useState(1n)
  const [currentPair, setCurrentPair] = useState([])
  const [allActivities, setAllActivities] = useState([])
  const [activeListings, setActiveListings] = useState([])
  const [collectionRoyalty, setCollectionRoyalty] = useState(0)
  const [xShare, setXShare] = useState({})
  const [auctionPayments, setAuctionPayments] = useState({})

  /*
        Repetitive code below assigning colours, add to vars and re-use:

        Modal overlay: rgba(60, 57, 56, 0.85)
        Modal bg: rgba(222, 218, 221, 0.975)
    */
  const modalOverlayBgColor = 'rgba(60, 57, 56, 0.85)'
  const modalBgColor = 'rgba(222, 218, 221, 0.975)'
  const modalTextColor = 'black'

  const activeAuctions = useMemo(() => {
    if (!item?.activities || item?.activities.length < 1) return []

    return item?.activities.filter(
      (auction) => auction?.type === ACTIVITY_CONSTANTS.AuctionCreated,
    )
  }, [item?.activities])

  const getAuctionStatus = useCallback(
    (auctionId) => {
      if (!activeAuctions) return [false, false]
      const auction = activeAuctions.find((auction) => auction.id === auctionId)
      if (!auction) return [false, false]

      const currentTimestamp = new Date().getTime()
      const startTime = ensureMillisecondsFormat(auction.startTime)
      const endTime = ensureMillisecondsFormat(auction.endTime)

      return [currentTimestamp > startTime, currentTimestamp > endTime]
    },
    [activeAuctions],
  )

  useEffect(() => {
    let isMounted = true

    async function mountFetch() {
      if (isMounted) await fetchItem(false)
    }

    mountFetch()

    return () => {
      isMounted = false
    }
  }, [])

  const decimals = useMemo(() => {
    return item?.chain === CHAIN_KEYS.ckb ? 8 : 18
  }, [item])

  const collection = useMemo(() => {
    if (!item?.collectionData && !item?.collection) return null

    return item?.collectionData || item.collection
  }, [item?.collectionData, item?.collection])

  const activePairs = useMemo(() => {
    if (!item?.activities) return null

    return item.activities.filter(
      (pair) => pair.type === ACTIVITY_CONSTANTS.ListingCreated,
    )
  }, [item?.activities])

  const activePairsOwned = useMemo(() => {
    if (!activePairs) return []
    if (!user?.addresses?.length) return []

    return activePairs.filter((pair) =>
      checkUserAddresses(user?.addresses, pair?.owner?.address),
    )
  }, [user?.addresses, activePairs])

  const ownerAuctions = useMemo(() => {
    if (!activeAuctions || !user?.addresses.length) return []

    return activeAuctions.filter((auction) =>
      checkUserAddresses(user?.addresses, auction?.owner?.address),
    )
  }, [activeAuctions, user?.addresses])

  const unlistedOwners = useMemo(() => {
    if (!item?.owners || !activeListings) return

    const owners = [
      ...item?.owners.filter((owner) => {
        const ownerAddress = owner?.address ?? owner?.account?.address

        let ownerCheck =
          HIDDEN_ADDRESSES.indexOf(ownerAddress?.toLowerCase()) < 0

        const ownerLock =
          !ownerAddress || ownerAddress?.startsWith('0x')
            ? false
            : helpers.parseAddress(ownerAddress)

        if (
          ownerLock?.codeHash ===
          getDexLockScript(NETWORK === 'mainnet').codeHash
        ) {
          ownerCheck = false
        }

        return ownerCheck
      }),
    ]

    owners.forEach((owner, index) => {
      activeListings
        .map((listing) => {
          return {
            amount: listing?.amount,
            address:
              listing?.owner?.address ??
              listing?.address ??
              listing?.interactingContractAddress,
            id: listing.id,
          }
        })
        .forEach((listing) => {
          if (
            listing.amount === owner.amount &&
            listing.address === owner.account.address &&
            listing.id === owner.id
          ) {
            owners.splice(index, 1)
          }
        })
    })

    return owners
  }, [item?.owners, activeListings])

  const ownedAmount = useMemo(() => {
    if (!user?.address || !item || !unlistedOwners) return 0
    let _owners = unlistedOwners
    let ownerCount = 0n

    const itemChain = item?.chain ?? CHAIN_KEYS.godwoken
    const chainAddress = user.addresses.findLast(
      (addressObject) => addressObject.chain === itemChain,
    )?.address

    if (_owners.length > 0)
      ownerCount = _owners
        .filter(
          (owner) =>
            owner?.address === chainAddress ||
            owner.account.address === chainAddress,
        )
        .reduce((accum, owner) => accum + BigInt(owner.amount), 0n)

    return ownerCount
  }, [user, item, unlistedOwners])

  const lowestPair = useMemo(() => {
    if (!item?.activities || !item?.activities?.length) return null

    return item.activities
      .filter((pair) => pair.type === ACTIVITY_CONSTANTS.ListingCreated)
      .sort((p1, p2) => Number(p1.price) - Number(p2.price))[0]
  }, [item?.activities])

  const lowestAuction = useMemo(() => {
    if (!item?.activities || !item?.activities?.length) return null

    const validPairs = item.activities.filter((pair) => {
      const startEndStatus = getAuctionStatus(pair?.id)

      return (
        pair.type === ACTIVITY_CONSTANTS.AuctionCreated &&
        startEndStatus[0] &&
        !startEndStatus[1]
      )
    })

    return validPairs.sort((p1, p2) => {
      const lowestPriceP1 =
        p1.bids.length > 0
          ? Math.min(...p1.bids.map((bid) => Number(bid.price)))
          : Number(p1.price)
      const lowestPriceP2 =
        p2.bids.length > 0
          ? Math.min(...p2.bids.map((bid) => Number(bid.price)))
          : Number(p2.price)

      if (lowestPriceP1 === lowestPriceP2) {
        return p1.bids.length - p2.bids.length
      }

      return lowestPriceP1 - lowestPriceP2
    })[0]
  }, [item?.activities])

  const getItemTotal = useMemo(() => {
    if (!item?.owners) return 0n

    return (
      item?.owners
        ?.filter((owner) => owner?.address?.toLowerCase() !== DEAD_ADDRESS)
        .reduce((accum, owner) => accum + BigInt(owner.amount), 0n) ?? 0n
    )
  }, [item?.owners])

  const activeBids = useMemo(() => {
    if (!activeAuctions) return null
    const activeBids = []

    activeAuctions.forEach((auction) => {
      if (auction?.bids.length > 0) {
        activeBids.push(...auction.bids)
      }
    })

    return activeBids.sort((b1, b2) => b2.createdAt - b1.createdAt)
  }, [activeAuctions])

  const collectionAsAuthor = useMemo(() => {
    if (!collection) return {}

    return {
      address: collection.address,
      profilePic: collection?.image,
      name: collection?.name,
    }
  }, [collection])

  const itemAttributes = useMemo(() => {
    if (
      !item?.propertyTypes?.length &&
      !item?.attributes?.length &&
      !item?.traits
    )
      return []

    let attributes = []

    // Handle legacy propertyTypes format
    if (item?.propertyTypes?.length > 0) {
      attributes =
        item.propertyTypes?.map((traitType, i) => ({
          trait_type: traitType,
          value: item?.propertyValues[i],
        })) ?? []
    }
    // Handle traits array format [[key, value]]
    else if (Array.isArray(item?.traits)) {
      attributes =
        item.traits?.map((trait) => ({
          trait_type: trait[0],
          value: trait[1],
        })) ?? []
    }
    // Handle traits object format with dynamic keys
    else if (typeof item?.traits === 'object' && item?.traits !== null) {
      attributes = Object.entries(item.traits).map(([key, value]) => ({
        trait_type: key,
        value: value,
      }))
    }

    return attributes
  }, [item])

  const assetType = useMemo(() => {
    return item?.assetType ?? 'image'
  }, [item?.assetType])

  const renderMedia = useMemo(() => {
    if (!item) return null
    let image = item?.image ?? ''

    if (item?.assetType === 'audio' && typeof item?.coverImage === 'string') {
      image = item?.coverImage
    }

    return (
      <div
        className={
          NERVAPE_GEAR_CLUSTER_IDS[NETWORK].includes(item?.itemCollection)
            ? 'media is-nervape'
            : 'media'
        }
      >
        {item?.assetType !== 'audio' ? (
          <MediaWrapper
            src={image}
            assetType={assetType}
            file={item.file}
            fileType={item.fileType}
            item={item}
          />
        ) : (
          <MediaWrapper
            src={item.coverImage}
            assetType={assetType}
            file={item.file}
            fileType={item.fileType}
            item={item}
          />
        )}

        {item?.assetType === 'audio' && (
          <MediaWrapper
            src={image}
            assetType={assetType}
            file={item.file}
            fileType={item.fileType}
            item={item}
          />
        )}
      </div>
    )
  }, [assetType, item])

  // const renderCollectionRoyalty = useMemo(() => {
  //   if (!collectionRoyalty) return null

  //   return (<div className='card no-hover' style={{ padding: '0.5rem' }}>
  //     <div className='seller-info text-center'>
  //       <div style={{ fontSize: 12 }}>Collection Royalty</div>
  //       <div style={{ color: 'black' }}>{collectionRoyalty} %</div>
  //     </div>
  //   </div>)
  // }, [collectionRoyalty])

  const renderName = useMemo(() => {
    let name = item?.name

    if (!name || name?.toLowerCase() === item?.tokenId?.toLowerCase())
      name = item?.standard ?? 'Unnamed NFT'

    return <h1 className="title">{name}</h1>
  }, [item])

  const renderEndAuctionButton = useCallback(
    (auction) => {
      if (!user?.addresses || !provider || !auction?.id) return null
      const splitId = auction?.id?.split('-')
      const auctionContractAddress = splitId[0]
      const auctionId = Number(splitId[1])
      const bidsLength = auction.bids?.length ?? 0
      const auctionStatus = getAuctionStatus(auction.id)
      const auctionEnded = auctionStatus[0] && auctionStatus[1]
      const ownerCheck = checkUserAddresses(
        user?.addresses,
        auction?.owner?.address,
      )
      const contractCheck =
        getContractInfo('NationAuctionV2').address === auctionContractAddress

      if (!contractCheck && !ownerCheck) return null

      let button

      if (auctionEnded) {
        button = (
          <button
            className="end-auction btn btn-solid-warn"
            key={auction.id}
            onClick={() => setShowEndAuction(auction.id)}
          >
            Finalize Auction (
            {`${bidsLength} Bid${bidsLength === 1 ? '' : 's'}`})
          </button>
        )
      } else if (!auction.bids.length && ownerCheck) {
        button = (
          <button
            className="end-auction btn btn-danger"
            key={auction.id}
            onClick={() => {
              setShowEndAuction(auction.id)
              setCancelAuction(true)
            }}
          >
            Cancel Auction {auctionId} (
            {`${bidsLength} Bid${bidsLength === 1 ? '' : 's'}`})
          </button>
        )
      }

      return button
    },
    [getAuctionStatus, provider, user?.addresses],
  )

  useEffect(() => {
    const checkProvider = connector || provider

    if (item && !!user?.addresses.length && checkProvider) {
      const activeAddress = user?.addresses?.findLast(
        (address) => address.chain === (item?.chain ?? CHAIN_KEYS.ckb),
      )?.address

      const callMethod = () =>
        activeAddress?.startsWith('0x')
          ? getTokenBalance(activeAddress, provider)
          : getBalance(activeAddress)

      callMethod()
        .then((balance) => {
          setBalance(balance || 0n)
        })
        .catch((e) => {
          console.error('get token balance error: ', e)
          setBalance(0n)
        })
    }
  }, [item, provider, user?.addresses])

  useEffect(() => {
    if (!activePairs && !activeAuctions) return

    const allListings = []
    const listings = []
    if (activePairs) allListings.push(...activePairs)
    if (activeAuctions) allListings.push(...activeAuctions)

    listings.push(
      ...allListings
        .filter((listing) => {
          const auctionStatus = getAuctionStatus(listing.id)

          return (auctionStatus[0] && !auctionStatus[1]) || !listing?.startPrice
        })
        .sort((a, b) => Number(a?.price) - Number(b?.price)),
    )

    listings.push(
      ...allListings
        .filter((listing) => {
          const auctionStatus = getAuctionStatus(listing.id)

          return (
            (listing?.startPrice && !auctionStatus[0] && !auctionStatus[1]) ||
            auctionStatus[1]
          )
        })
        .sort((a, b) => Number(a?.price) - Number(b?.price)),
    )

    setActiveListings(listings)
  }, [activeAuctions, activePairs, item])

  // useEffect(() => {
  //   if (!item) return

  //   // const setRoyalties = async () => {
  //   //   setCollectionRoyalty(getRenderReadyRoyalty(collection))
  //   // }

  //   setRoyalties()
  // }, [item, provider])

  const creatorRoyalty = useMemo(() => {
    if (!item) return 0

    if (String(item?.royalty).length === 1) return item.royalty

    if (item?.chain === CHAIN_KEYS.ckb) return item.royalty

    return getRenderReadyRoyalty({
      version:
        item.collectionData?.version ??
        collection?.version ??
        item?.version ??
        2,
      royalty: item?.royalty,
    })
  }, [collection, item])

  useEffect(() => {
    async function fetchAllAuctionPayments() {
      const payments = {}

      for (const listingItem of activeListings) {
        if (listingItem.type !== ACTIVITY_CONSTANTS.AuctionCreated) continue

        try {
          const firstBidCheck = listingItem.bids.length === 0
          const minimumIncrementPercent = firstBidCheck
            ? 0n
            : await getMinimumBidPercent(
                getContractViaAddress(
                  listingItem.interactingContractAddress,
                  provider,
                ),
                listingItem,
              )
          const lowestPricePossible =
            (BigInt(listingItem.price) * (100n + minimumIncrementPercent)) /
            100n

          payments[listingItem.id] = lowestPricePossible
        } catch (error) {
          console.error(
            'Error fetching auction payment for auction ID:',
            listingItem.id,
            error,
          )
        }
      }

      setAuctionPayments(payments)
    }

    if (activeListings && activeListings.length > 0) {
      fetchAllAuctionPayments()
    }
  }, [activeListings])

  function verifyListingId(data) {
    if (!data) return
    if (!isNaN(data?.listingId)) return Number(data.listingId)
    if (!isNaN(data?.pairId)) return Number(data.pairId)
    if (!isNaN(data?.id)) return Number(data.id)
    if (!isNaN(data?.id?.split('-')[1])) return Number(data.id.split('-')[1])
  }

  async function fetchItem(syncFirst) {
    let result
    let error
    let item

    try {
      result = await axios.get(`/item/${collectionAddress}/${id}`)

      item = result?.data?.item

      if (result.data?.isHidden) {
        return
      }

      if (Array.isArray(item) && !item?.length) throw Error('Empty item!')

      if (
        result.data?.item?.name === '' &&
        item?.chain === CHAIN_KEYS.godwoken
      ) {
        await axios.post(`/sync/Godwoken/item/${collectionAddress}/${id}`)
        result = await axios.get(`/item/${collectionAddress}/${id}`)
      }
    } catch (error) {
      console.error('Error fetching item!')
    } finally {
      if (item?.isHidden || !item?.itemStatus) {
        setItem({ hidden: true })
      } else if (item || item) {
        console.info('Fetch ITEM::FINAL', item)

        if (item.itemCollection === '0x0' && item?.isNation) {
          item.itemCollection = getContractInfo('COMMON_NATION').sporeAddress
        }

        if (item.chain === CHAIN_KEYS.ckb) {
          item.capacity = (await getItemCapacity(item)).toString()
        }

        setItem(item)

        const activities = item?.activities
          ?.sort((p1, p2) => Number(p2.updatedAt) - Number(p1.updatedAt))
          .map((activity) => ({ ...activity, item }))

        setAllActivities(activities)
      } else {
        console.error('Error fetching Item', error)
        setItem(null)
      }
    }
  }

  function openBuyModal(pair) {
    if (!user?.address) props.connectAccount()
    else {
      setCurrentPair(pair)
      setShowBuyNowModal(pair)
    }
  }

  function closeBuyModal() {
    setCurrentPair([])
    setAmount(1n)
    setShowBuyNowModal('')
  }

  const onListed = useCallback(
    (tokenId, xContent) => {
      if (!item || !tokenId) return

      setAmount(1n)
      setShowPutMarketPlace(false)
      setSnackBarMessage('Success')
      setOpenSnackbar(true)
      setXShare({
        name: item?.name ?? 'NFT',
        mainText: xContent.mainText,
        tweetText: xContent.tweetText,
      })

      setTimeout(() => fetchItem(), 5000)
    },
    [item],
  )

  async function endAuction(auctionId, cancelled = false) {
    if (!auctionId) return

    setEndingAuctionStatus(true)

    finalizeAuction(auctionId, await provider.getSigner(), cancelled)
      .then((finalized) => {
        axios
          .post(`/sync/Godwoken/item/${collectionAddress}/${id}`)
          .then((result) => {
            setEndingAuctionStatus(false)
            setShowEndAuction('')
            setSnackBarMessage('Success')
            setOpenSnackbar(true)
            setAmount(1n)
            if (!cancelled) {
              setXShare({
                name: item?.name ?? 'NFT',
                mainText: (
                  <p>
                    Auction for <strong>{item?.name ?? 'NFT'}</strong> on
                    imagiNation has ended!
                    <br /> <em>Show</em> it off on <em>X</em> now!
                  </p>
                ),
                tweetText: `An Auction has ended for ${item?.name ?? 'an NFT'} on @imagiNation_mkt. Come see what was won!`,
              })
            }

            setTimeout(() => fetchItem(), 2000)

            return true
          })
          .catch((error) => {
            if (error.response) {
              setEndingAuctionStatus(false)
              setSnackBarMessage(error.response.data.message)
              setOpenSnackbar(true)
            }
          })
      })
      .catch((error) => {
        if (error.reason) error.reason = error.reason.toLowerCase()

        if (error.reason && error.reason.includes('not active')) {
          setSnackBarMessage('Auction not running!')
          setOpenSnackbar(true)
        } else if (error.reason && error.reason.includes('too small')) {
          setSnackBarMessage('Price too low!')
          setOpenSnackbar(true)
        } else if (error.reason && error.reason.includes('starting')) {
          setSnackBarMessage('Auction not yet started!')
          setOpenSnackbar(true)
        } else {
          setSnackBarMessage('Failed Transaction')
          setOpenSnackbar(true)
        }

        setEndingAuctionStatus(false)
      })
  }

  async function unlistItem(pair) {
    if (!pair) return
    let signer
    if (connector?.isJoyId && pair?.chain === CHAIN_KEYS.ckb) {
      signer = connector
    } else if (provider && 'getSigner' in provider)
      signer = await provider?.getSigner()
    else signer = provider

    setDelistingStatus(true)

    const methodToCall = () =>
      pair?.chain === CHAIN_KEYS.ckb
        ? delistSpore(signer, {
            ...pair,
            activeWallet: user?.activeWallet,
            userAddress: pair.owner.addresses.findLast(
              (addressObject) => addressObject.chain === CHAIN_KEYS.ckb,
            )?.address,
            collectionAddress: pair.itemCollection,
          })
        : delistEvmItem(
            pair.interactingContractAddress,
            verifyListingId(pair),
            signer,
          )

    methodToCall()
      .then((delisted) => {
        if (delisted) {
          axios
            .post(
              `/sync/${item?.chain ?? CHAIN_KEYS.godwoken}/item/${collectionAddress}/${id}`,
              {
                standard: item?.standard ?? NFT_STANDARDS.unknown,
              },
            )
            .then(() => {
              setDelistingStatus(false)
              setShowUnlistMarketPlace('')
              setSnackBarMessage('Success')
              setOpenSnackbar(true)
              setAmount(1n)
              setTimeout(() => fetchItem(), 2000)

              return true
            })
            .catch((error) => {
              if (error.response) {
                setDelistingStatus(false)
                setSnackBarMessage(error.response.data.message)
                setOpenSnackbar(true)
              }
            })
        } else {
          setDelistingStatus(false)
          setSnackBarMessage('Something went wrong!')
          setOpenSnackbar(true)
        }
      })
      .catch((error) => {
        console.log('error', error)
        setDelistingStatus(false)
        setSnackBarMessage('Something went wrong!')
        setOpenSnackbar(true)
      })
  }

  async function buyItem(pair) {
    if (!pair) {
      closeBuyModal()
      setSnackBarMessage('Pair not set!')
      setOpenSnackbar(true)
      return
    }

    if (amount === 0n) {
      closeBuyModal()
      setSnackBarMessage('Amount could not be zero!')
      setOpenSnackbar(true)
      return
    }

    const price = BigInt(pair.price) * BigInt(amount)

    console.log('price check', price)
    console.log('balance check', balance)

    if (balance <= price) {
      closeBuyModal()
      setSnackBarMessage('Your available balance is less than the price!')
      setOpenSnackbar(true)
      return
    }

    if (BigInt(pair.amount) < BigInt(amount)) {
      closeBuyModal()
      setSnackBarMessage('Amount greater than pair!')
      setOpenSnackbar(true)
      return
    }

    setBuyingStatus(true)

    let signer
    if (connector?.isJoyId && pair?.chain === CHAIN_KEYS.ckb) {
      signer = connector
    } else if (provider && 'getSigner' in provider)
      signer = await provider?.getSigner()
    else signer = provider

    setDelistingStatus(true)

    try {
      const chainAddress = user.addresses.findLast(
        (addressObject) => addressObject.chain === item.chain,
      )?.address

      if (item.chain === CHAIN_KEYS.ckb) {
        const fees = {
          // listing: 0n,
          itemRoyalty: (price * BigInt(item?.royalty ?? 0)) / 10_000n,
          collectionRoyalty:
            (price * BigInt(item?.collectionData?.royalty ?? 0)) / 10_000n,
          dev: (price * 250n) / 10_000n,
        }

        await buySpore(signer, {
          ...item,
          ...pair,
          activeWallet: user?.activeWallet,
          userAddress: chainAddress,
          collectionAddress: pair.itemCollection,
          fees,
          itemCreator: item?.creator?.address,
          collectionCreator: item?.collectionData?.creator?.address,
          seller: pair.owner?.address,
        })
      } else {
        try {
          await buyEvmNft(
            pair?.market ?? pair?.interactingContractAddress,
            verifyListingId(pair),
            amount,
            price,
            await provider.getSigner(),
          )

          await axios.post(
            `/sync/${item?.chain ?? 'Godwoken'}/item/${collectionAddress}/${id}`,
          )
        } catch (error) {
          throw new Error(error.response.data.message)
        }
      }

      setBuyingStatus(false)
      closeBuyModal()
      setSnackBarMessage('Success')
      setOpenSnackbar(true)
      setXShare({
        name: item?.name ?? 'NFT',
        mainText: (
          <p>
            Purchase of{amount > 1n ? ` ${amount.toString()}` : ''}{' '}
            <strong>{item?.name ?? 'NFT'}</strong> for{' '}
            <strong>
              {prettyCommaFormat(price.toString(), decimals)}
              {item?.chain === CHAIN_KEYS.ckb
                ? SYMBOL_KEYS.ckb
                : SYMBOL_KEYS.godwoken}
            </strong>{' '}
            successful!
            <br /> <em>Share</em> your new purchase on <em>X</em> now!
          </p>
        ),
        tweetText: `I just purchased ${item?.name ?? 'an NFT'} for ${prettyCommaFormat(price, decimals)}${item?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken} on @imagiNation_mkt, come check it out!`,
      })

      setTimeout(() => fetchItem(), 2000)

      return true
    } catch (error) {
      setSnackBarMessage(`Failed Transaction: ${error?.message}`)
      setOpenSnackbar(true)
    } finally {
      setBuyingStatus(false)
    }
  }

  // @TODO UPDATE
  async function placeBid(auctionId) {
    if (!auctionId) {
      setSnackBarMessage('Auction not found!')
      setOpenSnackbar(true)
      return
    }
    const splitAddress = auctionId.split('-')
    const auctionContract = getContractViaAddress(
      splitAddress[0],
      await provider.getSigner(),
    )
    const currentBidsLength = await getAuctionBidsLength(
      auctionContract,
      splitAddress[1],
    )

    if (getCurrentAuction(auctionId).owner.id === user.id) {
      setSnackBarMessage('Auction creator can not bid')
      setOpenSnackbar(true)
      return
    }

    if (!bidPrice && currentBidsLength > 0) {
      setSnackBarMessage('Bid price not set!')
      setOpenSnackbar(true)
      return
    }

    const minimumIncrementPercent = await getMinimumBidPercent(auctionContract)

    if (bidPrice - auctionPayments[auctionId] < 0n && currentBidsLength > 0n) {
      setSnackBarMessage(
        `Your bid must be ${minimumIncrementPercent}% higher than the current bid!`,
      )
      setOpenSnackbar(true)
      return
    }

    if (balance < bidPrice) {
      setSnackBarMessage('Your available balance is less than the bid price!')
      setOpenSnackbar(true)
      return
    }

    setBiddingStatus(true)

    try {
      bidOnAuction(splitAddress[1], bidPrice, auctionContract)
        .then((bidPlaced) => {
          if (bidPlaced) {
            axios
              .post(`/sync/Godwoken/item/${collectionAddress}/${id}`)
              .then((result) => {
                closePlaceBidModal()
                setSnackBarMessage('Success')
                setXShare({
                  name: item?.name ?? 'NFT',
                  mainText: (
                    <p>
                      Show off your bid on{' '}
                      <strong>
                        <em>X</em>
                      </strong>{' '}
                      for <strong>{item?.name ?? 'this NFT'}</strong> and{' '}
                      <em>tempt others</em> to try and out bid you!
                    </p>
                  ),
                  tweetText: `I just placed a bid to get ${item?.name ?? 'this NFT'} on @imagiNation_mkt, think you can out-bid me? Come find out!`,
                })

                setTimeout(() => fetchItem(), 1500)

                return true
              })
              .catch((error) => {
                if (error.response) {
                  setSnackBarMessage(error.response.data.message)
                }
              })
          } else {
            setSnackBarMessage('Failed Transaction')
          }
          setOpenSnackbar(true)
        })
        .catch((error) => {
          throw error
        })
    } catch (error) {
      console.error('error', error)

      if (error?.reason) {
        if (error.reason?.includes('end')) {
          setSnackBarMessage('Auction is over!')
        } else if (error.reason?.includes('too small')) {
          setSnackBarMessage('Price too low!')
        } else if (error.reason?.includes('starting')) {
          setSnackBarMessage('Auction not yet started!')
        } else if (error.reason?.includes('Auction creator can not bid')) {
          // Fallback if it gets by because of id mismatch and error includes reason
          setSnackBarMessage('Auction creator can not bid!')
        }
      } else {
        setSnackBarMessage('Unknown Error!')
      }
      setOpenSnackbar(true)
    } finally {
      setBiddingStatus(false)
    }
  }

  function closePlaceBidModal() {
    setShowPlaceBidModal('')
    setBidPrice(0n)
    setBiddingStatus(false)
  }

  const handleCloseDialog = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  function setPrice(type, value) {
    let convertedValue = 0n

    if (value) {
      const decimalCheck = value.split('.')
      const value2 = !isNaN(Number(decimalCheck[1]))
        ? decimalCheck[1].padEnd(decimals, '0')
        : '0'.padEnd(decimals, '0')
      convertedValue = BigInt(decimalCheck[0] + value2)
    }

    switch (type) {
      case 'bid':
        setBidPrice(convertedValue)
        break
      case 'fixed':
        break

      case 'all':
      default:
        setBidPrice(convertedValue)
        break
    }
  }

  function fixAmountValidator(value, checkAmount) {
    if (!value || isNaN(Number(value))) return

    const amount = BigInt(value)
    if (BigInt(checkAmount) < amount) return
    setAmount(amount)
  }

  const highestBid = (auctionId) => {
    if (!activeAuctions || !auctionId) return null

    return activeAuctions
      .find((auction) => auction.id === auctionId)
      .bids.sort((b1, b2) => (b1.price - b2.price < 0 ? 1 : -1))[0]
  }

  function getMediaOpenGraphMeta() {
    const meta = []

    if (item?.image) {
      if (item?.assetType === 'art') {
        meta.push(<meta name="og:image" content={item.image} />)
      } else if (item?.assetType === 'video') {
        meta.push(<meta property="og:video" content={item.image} />)
      } else if (item?.assetType === 'music') {
        meta.push(<meta name="og:audio" content={item.image} />)
      }
    }

    if (item?.coverImage && item?.assetType !== 'art') {
      meta.push(<meta property="og:image" content={item.coverImage} />)
    }

    return meta
  }

  function getCurrentAuction(auctionId) {
    if (!activeAuctions || !auctionId) return null

    return activeAuctions.find((auction) => auction.id === auctionId)
  }

  const getCurrentAuctionPrice = (auctionId) => {
    const currentAuction = getCurrentAuction(auctionId)

    if (!currentAuction) return null
    if (!currentAuction.bids.length) return currentAuction.price

    const highestBid = currentAuction.bids.sort((b1, b2) =>
      b1.price - b2.price < 0 ? 1 : -1,
    )[0]

    return !isNaN(highestBid?.price) ? BigInt(highestBid?.price) : 0n
  }

  async function handleOnTransfer(transferTo) {
    try {
      if (transferTo === DEAD_ADDRESS)
        setTimeout(() => history.push(`/collection/${collectionAddress}`), 2000)
      else setTimeout(() => fetchItem(), 2000)
    } catch (error) {
      console.error('Error during Item Transfer!')
    }
  }

  function renderCardSaleDetails(listingItem) {
    const auctionStatus = getAuctionStatus(listingItem.id)
    let message = auctionStatus[1]
      ? ' waiting for Auction to end, final bid '
      : ' for upcoming Auction, starting at '

    if (listingItem?.type === ACTIVITY_CONSTANTS.ListingCreated) {
      message = ' on Sale for '
    } else if (auctionStatus[0] && !auctionStatus[1]) {
      message = ' on Auction, lowest possible bid '
    }

    return message
  }

  return (
    <>
      {' '}
      {item && item?.hidden !== true ? (
        <section id="ItemDetails" className="container">
          <div className="item-thumb text-center">{renderMedia}</div>

          <div className="royalties">
            {!isNaN(creatorRoyalty) && (
              <div className="card no-hover" style={{ padding: '0.5rem' }}>
                <div className="seller-info text-center">
                  <div style={{ fontSize: 12 }}>Creator Royalty</div>
                  <div style={{ color: 'black' }}>{creatorRoyalty} %</div>
                </div>
              </div>
            )}

            {/* {!isNaN(collectionRoyalty) && !isNaN(creatorRoyalty) && renderCollectionRoyalty} */}
          </div>

          {item?.standard !== NFT_STANDARDS.cota && (
            <section className="sales-area">
              <hr />

              <h2 className="mb-3">Sales</h2>

              <h3>
                {lowestPair ? (
                  <>
                    <span>Lowest Fixed Price:</span>
                    <strong>
                      {prettyCommaFormat(lowestPair.price, decimals)}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </strong>
                  </>
                ) : (
                  'No fixed sale'
                )}
              </h3>

              <h3>
                <span>Available Auctions:</span>
                <strong>{activeAuctions?.length ?? 0}</strong>
              </h3>

              <div className="buy-actions">
                {user?.address &&
                  lowestPair &&
                  !checkUserAddresses(
                    user?.addresses,
                    lowestPair.owner?.address,
                  ) && (
                    <button
                      className="d-block btn btn-solid-green"
                      onClick={() => openBuyModal(lowestPair)}
                    >
                      Buy Now
                    </button>
                  )}

                {user?.address &&
                  lowestAuction &&
                  !checkUserAddresses(
                    user?.addresses,
                    lowestAuction.owner?.address,
                  ) && (
                    <button
                      className="d-block btn btn-solid-green"
                      onClick={() => setShowPlaceBidModal(lowestAuction.id)}
                    >
                      Place Lowest Bid
                    </button>
                  )}

                {!user?.address &&
                  (lowestPair || activeAuctions) &&
                  item?.standard !== NFT_STANDARDS.cota && (
                    <button
                      className="connect-wallet btn-success"
                      onClick={props.connectAccount}
                    >
                      Connect Wallet to login and view purchasing options.
                    </button>
                  )}
              </div>

              {activeAuctions?.length > 0 && (
                <div id="AuctionHub">
                  <h2 className="mb-2">Auction Hub</h2>

                  {activeAuctions.length > 1 && (
                    <ul className="nav netstorm-tab">
                      {activeAuctions.map((auction, index) => (
                        <li key={auction.listingId}>
                          <a
                            href={`#auction-${auction.listingId}`}
                            key={`auction-${auction.listingId}`}
                            className={`${index === 0 ? 'active' : ''}`}
                            data-toggle="pill"
                          >
                            Auction {verifyListingId(auction)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}

                  {activeAuctions.map((auction, index) => (
                    <div
                      className={`clock-wrapper ${index === 0 ? 'active' : ''}`}
                      id={`auction-${auction.listingId}`}
                      key={`auction-${auction.listingId}`}
                    >
                      <div className="bid">
                        {highestBid(auction.id) ? (
                          <>
                            Highest Bid:{' '}
                            <strong>
                              {prettyCommaFormat(
                                highestBid(auction.id).price,
                                decimals,
                              )}{' '}
                              {item?.chain === CHAIN_KEYS.ckb
                                ? SYMBOL_KEYS.ckb
                                : SYMBOL_KEYS.godwoken}
                            </strong>{' '}
                            {user?.address &&
                              getAuctionStatus(auction.id)[0] &&
                              !getAuctionStatus(auction.id)[1] && (
                                <button
                                  className="btn branded btn-smaller"
                                  onClick={() =>
                                    setShowPlaceBidModal(auction.id)
                                  }
                                >
                                  Place Bid
                                </button>
                              )}
                          </>
                        ) : getAuctionStatus(auction.id)[0] &&
                          !getAuctionStatus(auction.id)[1] ? (
                          <>
                            <span>No bids at this moment</span>{' '}
                            {user?.address && (
                              <button
                                className="btn branded btn-smaller"
                                onClick={() => setShowPlaceBidModal(auction.id)}
                              >
                                Place Bid
                              </button>
                            )}
                          </>
                        ) : null}
                      </div>

                      <ClockItem key={auction.listingId} auction={auction} />

                      <h5 className="text-center mt-3">
                        <strong>
                          Auction{' '}
                          {Number(
                            typeof auction?.id === 'number'
                              ? auction?.id
                              : auction?.id?.split('-')[1],
                          )}
                        </strong>
                      </h5>

                      {renderEndAuctionButton(auction)}
                    </div>
                  ))}
                </div>
              )}
            </section>
          )}

          {/* Content */}
          <div className="title-wrapper">
            {renderName}

            <SaveNft {...props} data={item} />
          </div>

          {/* Item Info List */}
          <dl className="item-info-list">
            <div className="item-description">
              <dt>Description</dt>
              <dd>{item?.description}</dd>
            </div>

            {item?.totalAmount ? (
              <>
                <dt>In existence:</dt>{' '}
                <dd>
                  <strong>{getItemTotal.toString()}</strong>
                </dd>
              </>
            ) : (
              ''
            )}

            {item?.standard && (
              <div className="standard">
                <dt>Standard:</dt>
                <dd>{item.standard}</dd>
              </div>
            )}

            {item?.chain === CHAIN_KEYS.ckb && item?.capacity && (
              <div className="capacity">
                <dt>Capacity:</dt>
                <dd>{prettyCommaFormat(item.capacity, 8)} CKB</dd>
              </div>
            )}

            {item?.category?.name && (
              <div className="category">
                <dt>Category:</dt>
                <dd>{item.category.name}</dd>
              </div>
            )}
          </dl>

          <div className="social-media">
            <h4>Share:</h4>
            <Link
              to={`https://x.com/intent/tweet?url=${window.location.href}&text=Come check out ${encodeURIComponent(item?.name)} on @imagiNation_mkt!`}
              target="_blank"
              rel="noopener"
            >
              <svg
                style={{ width: '25px', height: '25px', color: '#000' }}
                width="1200"
                height="1227"
                viewBox="0 0 1200 1227"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                  fill="#000"
                />
              </svg>
            </Link>
          </div>

          {/* Netstorm Tab */}
          <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
            {itemAttributes?.filter(
              (attribute) => attribute.trait_type && attribute.value,
            ).length > 0 && (
              <li>
                <a
                  href="#nav-property"
                  id="nav-property-tab"
                  data-toggle="pill"
                >
                  <h5>Properties</h5>
                </a>
              </li>
            )}
            <li>
              <a
                href="#nav-owner"
                className="active"
                id="nav-owner-tab"
                data-toggle="pill"
              >
                <h5>Owners</h5>
              </a>
            </li>
            {item?.standard !== NFT_STANDARDS.cota ? (
              <>
                {activeAuctions && item?.standard !== NFT_STANDARDS.spore && (
                  <li>
                    <a href="#nav-bids" id="nav-bids-tab" data-toggle="pill">
                      <h5>Bids</h5>
                    </a>
                  </li>
                )}
                <li>
                  <a
                    href="#nav-activity"
                    id="nav-activity-tab"
                    data-toggle="pill"
                  >
                    <h5>History</h5>
                  </a>
                </li>
              </>
            ) : null}
          </ul>
          {/* Tab Content */}
          <div
            className={`tab-content ${activeAuctions?.length > 0 ? 'has-countdown' : ''}`}
            id="nav-tabContent"
          >
            <div className="tab-pane fade show" id="nav-property">
              <div className="attributes">
                {itemAttributes
                  ?.filter(
                    (attribute) => attribute.trait_type && attribute.value,
                  )
                  .map((attributes, pid) => (
                    <div
                      className="item"
                      key={`prop_type-${attributes.trait_type}-${pid}`}
                    >
                      <div className="card no-hover" style={{ padding: 16 }}>
                        <div className="single-seller d-flex align-items-center">
                          <div
                            className="seller-info ml-1"
                            style={{ textAlign: 'center', width: '100%' }}
                          >
                            <span
                              style={{ wordBreak: 'break-all', fontSize: 12 }}
                            >
                              {attributes.trait_type}
                            </span>
                            <span
                              style={{ wordBreak: 'break-all', color: 'black' }}
                            >
                              {attributes.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="tab-pane fade show active" id="nav-owner">
              {!item?.owners?.length > 0 && <p>Owners not found</p>}
              {activeListings?.map((listingItem, idx) => (
                <div
                  className="item-owner buy"
                  style={{ width: '100%' }}
                  key={`pair-link-${listingItem.owner?.address}-${idx}`}
                >
                  <AuthorAvatar
                    author={listingItem.owner}
                    className="xs"
                    displayName={false}
                  />

                  <h6 className="author-name">
                    <Link to={`/account/${listingItem.owner?.address}`}>
                      {listingItem.owner?.name ?? ''}
                    </Link>
                  </h6>

                  <h5 className="description">
                    {listingItem.amount}
                    {renderCardSaleDetails(listingItem)}
                    <strong>
                      {getAuctionStatus(listingItem.id)[0] &&
                      !getAuctionStatus(listingItem.id)[1]
                        ? prettyCommaFormat(
                            auctionPayments[listingItem.id],
                            decimals,
                          )
                        : prettyCommaFormat(listingItem.price, decimals)}{' '}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </strong>{' '}
                    {!listingItem?.startPrice && listingItem.amount > 1
                      ? 'each'
                      : ''}
                  </h5>

                  {user?.address &&
                    !checkUserAddresses(
                      user?.addresses,
                      listingItem.owner?.address,
                    ) && (
                      <button
                        className="btn branded"
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          display:
                            (listingItem?.type ===
                              ACTIVITY_CONSTANTS.ListingCreated &&
                              listingItem?.price) ||
                            (getAuctionStatus(listingItem.id)[0] &&
                              !getAuctionStatus(listingItem.id)[1])
                              ? 'block'
                              : 'none',
                        }}
                        onClick={() =>
                          listingItem?.type ===
                          ACTIVITY_CONSTANTS.ListingCreated
                            ? openBuyModal(listingItem)
                            : setShowPlaceBidModal(listingItem.id)
                        }
                      >
                        {listingItem?.type === ACTIVITY_CONSTANTS.ListingCreated
                          ? 'Buy'
                          : 'Bid'}
                      </button>
                    )}
                </div>
              ))}
              {/* TODO: Move Auctions out of itemOwners and add Bid Button, either here or merge above and sort by date */}
              {unlistedOwners?.map((owner, idx) => (
                <div
                  className="item-owner"
                  style={{ width: '100%' }}
                  key={`owned-link-${owner?.address}-${idx}`}
                >
                  <AuthorAvatar
                    author={owner?.account}
                    className="xs"
                    displayName={false}
                  />

                  <h6 className="author-name">
                    <Link to={`/account/${owner?.account?.address}`}>
                      {owner?.account?.name ?? ''}
                    </Link>
                  </h6>

                  <h5 className="description">
                    {owner?.account?.address === DEAD_ADDRESS ? 'Burned ' : ''}
                    {owner?.amount}{' '}
                    {BigInt(owner?.amount ?? 0) > 1 ? 'Editions' : 'Edition'}
                  </h5>
                </div>
              ))}
            </div>
            <div id="nav-bids" className="tab-pane fade show nft-activity mini">
              {allActivities?.length > 0 ? (
                allActivities
                  .filter((activity) =>
                    [
                      ACTIVITY_CONSTANTS.BidCreated,
                      ACTIVITY_CONSTANTS.BidReplaced,
                      ACTIVITY_CONSTANTS.BidWinner,
                    ].includes(activity.type),
                  )
                  .map((item, index) => (
                    <NftActivity key={index} data={item} tabView={true} />
                  ))
              ) : (
                <span key="noHistory" className="pl-2">
                  History not found
                </span>
              )}
            </div>
            <div
              id="nav-activity"
              className="tab-pane fade show nft-activity mini"
              style={{ maxHeight: 500, overflowX: 'auto' }}
            >
              {allActivities?.length > 0 ? (
                allActivities.map((item, index) => (
                  <NftActivity key={index} data={item} tabView={true} />
                ))
              ) : (
                <span key="noHistory" className="pl-2">
                  History not found
                </span>
              )}
            </div>
          </div>

          <div className="info-cards" style={{ width: '100%' }}></div>
          <div className="owner-cards">
            {item?.creator && (
              <div className="card no-hover d-grid">
                <h4>
                  <AuthorAvatar
                    author={item.creator}
                    displayName={true}
                    className="lg"
                    style={{ maxWidth: '150px', width: '55px', height: '55px' }}
                  />
                </h4>

                <h3
                  style={{
                    fontWeight: '500',
                    fontSize: '1.1rem',
                    textAlign: 'center',
                  }}
                >
                  Creator
                </h3>
              </div>
            )}

            <div className="card no-hover">
              {item.itemCollection !== '0x0' ? (
                <>
                  <h4
                    style={{
                      marginBottom: '0.75rem',
                      textAlign: !item?.collectionData?.name
                        ? 'center'
                        : 'left',
                    }}
                  >
                    {item?.collectionData?.name ? (
                      <AuthorAvatar
                        author={collectionAsAuthor}
                        displayName={true}
                        className="lg"
                        style={{ maxWidth: '55px', maxHeight: '55px' }}
                        collection={true}
                      />
                    ) : (
                      <Link
                        to={`/collection/${item?.itemCollection}`}
                        style={{ fontSize: '1.2rem' }}
                      >
                        View Collection
                      </Link>
                    )}
                  </h4>

                  <h3
                    style={{
                      fontWeight: '500',
                      fontSize: '1.1rem',
                      textAlign: 'center',
                    }}
                  >
                    Collection
                  </h3>
                </>
              ) : (
                <h3
                  style={{
                    fontWeight: '500',
                    fontSize: '1.1rem',
                    textAlign: 'center',
                  }}
                >
                  No Collection
                </h3>
              )}
            </div>

            {/* {!isNaN(collectionRoyalty) && isNaN(creatorRoyalty) && !item?.creator && renderCollectionRoyalty} */}
          </div>

          {/* User Actions */}
          {item && user ? (
            <div className="user-interaction">
              {ownedAmount > 0n && (
                <>
                  {item?.standard !== NFT_STANDARDS.cota && (
                    <button
                      className="d-block btn btn-solid-warn"
                      onClick={() => setShowPutMarketPlace(true)}
                    >
                      Put on marketplace
                    </button>
                  )}

                  <button
                    className="btn btn-success btn-block"
                    data-toggle="modal"
                    data-target="#transfer-modal"
                    data-item={JSON.stringify(item)}
                  >
                    Transfer NFT
                  </button>

                  <button
                    className="btn btn-warn btn-block"
                    data-toggle="modal"
                    data-target="#transfer-modal"
                    data-item={JSON.stringify(item)}
                    data-burn="true"
                  >
                    Burn NFT
                  </button>

                  <TransferModal afterTransferCallback={handleOnTransfer} />
                </>
              )}

              {item?.standard !== NFT_STANDARDS.cota &&
                activePairsOwned?.length > 0 &&
                activePairsOwned.map((pair, index) => (
                  <button
                    className="d-block btn btn-solid-warn"
                    key={index}
                    onClick={() => setShowUnlistMarketPlace(pair)}
                  >
                    Unlist fixed sale
                  </button>
                ))}
            </div>
          ) : (
            ''
          )}

          {user && (
            <MarketModal
              connector={connector}
              provider={provider}
              item={item}
              isOpen={showPutMarketPlace}
              onListed={onListed}
              onRequestClose={() => setShowPutMarketPlace(false)}
              ownedAmount={ownedAmount}
              setSnackBarMessage={setSnackBarMessage}
              setOpenSnackbar={setOpenSnackbar}
              user={user}
            />
          )}

          <Modal
            isOpen={!!showUnlistMarketPlace}
            onRequestClose={() => setShowUnlistMarketPlace('')}
            ariaHideApp={false}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: modalOverlayBgColor,
                zIndex: 99,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '500px',
                backgroundColor: modalBgColor,
                color: modalTextColor,
                borderRadius: '20px',
                zIndex: 9999,
              },
            }}
          >
            <S.ModalBody>
              <S.ModalHeader>
                <S.ModalCloseIcon
                  size={32}
                  onClick={() => setShowUnlistMarketPlace('')}
                />
              </S.ModalHeader>
              <S.ModalTitle>
                Unlist Item
                <div>
                  <p style={{ marginTop: '0.75rem' }}>
                    Remove listing with{' '}
                    <strong>amount {showUnlistMarketPlace?.amount}</strong>{' '}
                    listed at{' '}
                    <strong>
                      {prettyCommaFormat(
                        showUnlistMarketPlace?.price,
                        decimals,
                      )}{' '}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </strong>
                  </p>
                  <S.Price>Are you sure you want to unlist this item?</S.Price>
                </div>
              </S.ModalTitle>
              <S.ModalActions>
                <S.ButtonWrapper>
                  <button
                    className="btn btn-bordered w-100 mr-4"
                    onClick={() => setShowUnlistMarketPlace(false)}
                  >
                    Cancel
                  </button>
                  {delistingStatus ? (
                    <button className="btn btn-solid-green w-100 ml-2">
                      <CircularProgress
                        style={{
                          width: '16px',
                          height: '16px',
                          color: 'white',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn btn-solid-green w-100 ml-2"
                      onClick={() => unlistItem(showUnlistMarketPlace)}
                    >
                      Unlist
                    </button>
                  )}
                </S.ButtonWrapper>
              </S.ModalActions>
            </S.ModalBody>
          </Modal>

          {/* End Auction Modal */}
          <Modal
            isOpen={!!showEndAuction}
            onRequestClose={() => setShowEndAuction('')}
            ariaHideApp={false}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: modalOverlayBgColor,
                zIndex: 99,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '500px',
                backgroundColor: modalBgColor,
                color: modalTextColor,
                borderRadius: '20px',
                zIndex: 9999,
              },
            }}
          >
            <S.ModalBody>
              <S.ModalHeader>
                <S.ModalCloseIcon
                  size={32}
                  onClick={() => setShowEndAuction('')}
                />
              </S.ModalHeader>
              <S.ModalTitle>
                {cancelAuction ? 'Cancel' : 'End'} Auction{' '}
                {Number(showEndAuction.split('-')[1])} <br />
                {ownerAuctions?.map((auction, index) => {
                  if (auction.id === showEndAuction) {
                    return (
                      <p key={index}>
                        Amount in Auction: {auction.amount}
                        <br /> Starting at{' '}
                        {prettyCommaFormat(auction.startPrice, decimals)}{' '}
                        {item?.chain === CHAIN_KEYS.ckb
                          ? SYMBOL_KEYS.ckb
                          : SYMBOL_KEYS.godwoken}
                        <br /> Last bid was{' '}
                        {prettyCommaFormat(
                          auction?.bids.length > 0 ? auction.price : 0,
                          decimals,
                        )}
                      </p>
                    )
                  }
                })}
                <S.PayAmount>
                  <S.Price>
                    Are you sure you want to {cancelAuction ? 'cancel' : 'end'}{' '}
                    this auction ?
                  </S.Price>
                </S.PayAmount>
              </S.ModalTitle>
              <S.ModalActions>
                <S.ButtonWrapper>
                  <button
                    className="btn btn-bordered w-100 mr-4 "
                    onClick={() => setShowEndAuction('')}
                  >
                    Cancel
                  </button>
                  {endingAuctionStatus ? (
                    <button className="btn btn-solid-green w-100">
                      <CircularProgress
                        style={{
                          width: '16px',
                          height: '16px',
                          color: 'white',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn btn-solid-green w-100"
                      onClick={() => endAuction(showEndAuction, cancelAuction)}
                    >
                      {cancelAuction ? 'Cancel' : 'End'} Auction
                    </button>
                  )}
                </S.ButtonWrapper>
              </S.ModalActions>
            </S.ModalBody>
          </Modal>

          {/* Buy Now Modal */}
          <Modal
            isOpen={!!showBuyNowModal}
            onRequestClose={() => closeBuyModal()}
            ariaHideApp={false}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: modalOverlayBgColor,
                zIndex: 99,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '500px',
                backgroundColor: modalBgColor,
                color: modalTextColor,
                borderRadius: '20px',
                zIndex: 9999,
              },
            }}
          >
            <S.ModalBody>
              <S.ModalHeader>
                <S.ModalCloseIcon size={32} onClick={() => closeBuyModal()} />
              </S.ModalHeader>

              <S.ModalTitle>Buy {item?.name}</S.ModalTitle>

              <S.ModalRow>
                <S.ModalLabel>You will pay</S.ModalLabel>
                <S.PayAmount>
                  {/* <S.CoinImage src={"/img/nervos-text-white.png"} /> */}
                  <S.Price>
                    {prettyCommaFormat(
                      BigInt(currentPair?.price || 0) * BigInt(amount || 1),
                      decimals,
                    )}
                  </S.Price>
                  <S.Unit>
                    {item?.chain === CHAIN_KEYS.ckb
                      ? SYMBOL_KEYS.ckb
                      : SYMBOL_KEYS.godwoken}
                  </S.Unit>
                </S.PayAmount>
              </S.ModalRow>

              <S.ModalRow>
                <S.ModalLabel>Available</S.ModalLabel>
                <S.ModalPrice>
                  {prettyCommaFormat(balance, decimals)}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </S.ModalPrice>
              </S.ModalRow>
              {(item?.collectionData?.standard === 2 ||
                NFT_STANDARDS.erc1155 === item?.standard) && (
                <>
                  <S.ModalRow>
                    <S.ModalLabel>{`Amount (max: ${currentPair?.amount?.toString()})`}</S.ModalLabel>

                    <S.Input
                      type="number"
                      min="0"
                      step="1"
                      placeholder="Enter Amount"
                      onChange={(event) =>
                        fixAmountValidator(
                          event.target.value,
                          currentPair?.amount,
                        )
                      }
                      value={amount.toString()}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*?$/.test(amount + event.key)) {
                          event.preventDefault()
                        }
                      }}
                      disabled={currentPair?.amount <= 1n}
                    />
                  </S.ModalRow>
                </>
              )}
              <S.ModalActions>
                <S.ButtonWrapper>
                  <button
                    className="btn btn-bordered w-100 mr-4"
                    onClick={() => closeBuyModal()}
                  >
                    Cancel
                  </button>
                  {buyingStatus ? (
                    <button className="btn btn-solid-green w-100">
                      <CircularProgress
                        style={{
                          width: '16px',
                          height: '16px',
                          color: 'white',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn btn-solid-green w-100"
                      onClick={async () => await buyItem(showBuyNowModal)}
                    >
                      Confirm
                    </button>
                  )}
                </S.ButtonWrapper>
              </S.ModalActions>
            </S.ModalBody>
          </Modal>

          {/* Place a Bid Modal */}
          <Modal
            isOpen={!!showPlaceBidModal}
            onRequestClose={() => closePlaceBidModal()}
            ariaHideApp={false}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: modalOverlayBgColor,
                zIndex: 99,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '500px',
                backgroundColor: modalBgColor,
                color: modalTextColor,
                borderRadius: '20px',
                zIndex: 9999,
              },
            }}
          >
            <S.ModalBody>
              <S.ModalHeader>
                <S.ModalCloseIcon
                  size={32}
                  onClick={() => closePlaceBidModal()}
                />
              </S.ModalHeader>
              <S.ModalTitle>
                Place Bid{' '}
                {item?.name
                  ? `on ${item?.name} (Auction #${Number(showPlaceBidModal.split('-')[1])})`
                  : `on Auction #${Number(showPlaceBidModal.split('-')[1])}`}
              </S.ModalTitle>

              <S.ModalRow>
                <S.ModalLabel>NFT Quantity</S.ModalLabel>
                <S.ModalPrice>
                  {getCurrentAuction(showPlaceBidModal)?.amount}
                </S.ModalPrice>
              </S.ModalRow>

              <S.ModalRow>
                <S.ModalLabel>Bids Placed</S.ModalLabel>
                <S.ModalPrice>
                  {getCurrentAuction(showPlaceBidModal)?.bids?.length ?? 0}
                </S.ModalPrice>
              </S.ModalRow>

              <S.ModalRow>
                <S.ModalLabel>Latest Bid Price</S.ModalLabel>
                <S.ModalPrice>
                  {prettyCommaFormat(
                    getCurrentAuctionPrice(showPlaceBidModal),
                    decimals,
                  )}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </S.ModalPrice>
              </S.ModalRow>

              <S.ModalRow>
                <S.ModalLabel>Bid Increment Percent</S.ModalLabel>
                <S.ModalPrice>
                  {getCurrentAuction(showPlaceBidModal)
                    ? getCurrentAuction(showPlaceBidModal).bidPercentIncrement /
                      100
                    : 5}
                  %
                </S.ModalPrice>
              </S.ModalRow>

              <S.ModalRow>
                <S.ModalLabel>Lowest possible bid</S.ModalLabel>
                <S.ModalPrice>
                  {prettyCommaFormat(
                    auctionPayments[showPlaceBidModal],
                    decimals,
                  )}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </S.ModalPrice>
              </S.ModalRow>

              <S.BidPrice>
                <S.ModalLabel>Your bid</S.ModalLabel>
                <S.ModalMainPrice
                  type="number"
                  step="0.1"
                  min="0"
                  value={prettyDecimalFormatInput(bidPrice, decimals)}
                  onChange={(event) => setPrice('bid', event.target.value)}
                />
                <S.UnitContainer>
                  {/* <S.CoinImage src={"/img/nervos-text-white.png"} /> */}
                  <S.Unit>
                    {item?.chain === CHAIN_KEYS.ckb
                      ? SYMBOL_KEYS.ckb
                      : SYMBOL_KEYS.godwoken}
                  </S.Unit>
                </S.UnitContainer>
              </S.BidPrice>

              <S.ModalRow>
                <S.ModalLabel>Available</S.ModalLabel>
                <S.ModalPrice>
                  {prettyCommaFormat(balance, decimals)}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </S.ModalPrice>
              </S.ModalRow>
              <S.ModalActions>
                <S.ButtonWrapper>
                  <button
                    className="btn w-100 mr-4 btn-bordered"
                    onClick={() => closePlaceBidModal()}
                  >
                    Cancel
                  </button>
                  {biddingStatus ? (
                    <button className="btn w-100 btn-solid-green">
                      <CircularProgress
                        style={{
                          width: '16px',
                          height: '16px',
                          color: 'white',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn w-100 btn-solid-green"
                      onClick={() => placeBid(showPlaceBidModal)}
                    >
                      Place a Bid
                    </button>
                  )}
                </S.ButtonWrapper>
              </S.ModalActions>
            </S.ModalBody>
          </Modal>

          {/* Notification */}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseDialog}
            message={snackBarMessage}
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseDialog}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />

          <XShareModal data={xShare} />
        </section>
      ) : item?.hidden ? (
        <>
          <h1
            style={{
              textAlign: 'center',
              color: '#000',
              fontSize: '4rem',
              margin: '1rem 0',
            }}
          >
            Item No Longer Available!
          </h1>

          <a
            href="/explore/listed-items"
            className="btn branded d-inline-block"
            style={{
              margin: '1rem auto',
              maxWidth: '250px',
            }}
          >
            View Other Items
          </a>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default ItemDetails
