import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AuthorItem from './AuthorCard'

import './author-area.scss'

const AuthorGrid = (props) => {
  const [authors, setAuthors] = useState([])
  const [noItems, setNoItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialItemsLoaded, setInitialItemsLoaded] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (initialItemsLoaded) setPage(1)
    setLoading(true)
    axios
      .get(`/authors?page=${page}`)
      .then((res) => {
        if (page === 1) setAuthors(res.data.authors)
        else setAuthors(authors.concat(res.data.authors))

        if (res.data.authors.length === 0 || res.data.final) setNoItems(true)

        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setAuthors([...authors])
      })
  }, [page])

  function loadMore() {
    if (!loading) {
      setInitialItemsLoaded(false)
      setPage(page + 1)
    }
  }
  return (
    <section className="author-area py-3">
      <div className="authors container">
        {authors.map((item, idx) => (
          <AuthorItem author={item} key={idx} />
        ))}
      </div>
      <div
        className="load-more"
        style={{ display: noItems ? 'none' : '', textAlign: 'center' }}
      >
        <button onClick={() => loadMore()} className="btn branded mt-3 mt-sm-4">
          {loading ? 'Loading...' : 'Load more'}
        </button>
      </div>
    </section>
  )
}

export default AuthorGrid
