import axios from 'axios'
import { createContext, useReducer, useContext, Dispatch } from 'react'
import { APP_KEYS, CHAIN_KEYS } from '../utils/constants'
import { CONNECTOR_STORAGE_KEY } from '../utils/connectors'
import {
  JOYID_CKB_STORAGE_KEY,
  JOYID_EVM_STORAGE_KEY,
} from '../utils/JoyIdConnector'
import { getCkbAddress } from '../utils/CKB/CkbService'
import CkbController from '../utils/CKB/CkbController'
import CkbSync from '../utils/CKB/CkbSync'

const AUTH_TOKEN_KEY = 'nToken'
const CKB_LAST_SYNC_KEY = 'iNation:ckbLastSync'

const initialState: {
  user: { [key: string]: any } | null
  token: string | null
  loading: boolean
  errorMessage: string | null
  provider?: string
} = {
  user: null,
  token: localStorage.getItem(AUTH_TOKEN_KEY),
  loading: false,
  errorMessage: null,
}

const AuthContext = createContext<{
  state: typeof initialState
  dispatch: Dispatch<{ type: string; payload: any }>
}>({
  state: initialState,
  dispatch: () => null,
})

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export const AuthProvider = (props: { [key: string]: any }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  return <AuthContext.Provider value={{ state, dispatch }} {...props} />
}

export async function loginUser(
  dispatch: any,
  userInfo: {
    account: { [key: string]: string } | `0x${string}`
    appId: (typeof APP_KEYS)[keyof typeof APP_KEYS]
    chain?: (typeof CHAIN_KEYS)[keyof typeof CHAIN_KEYS]
  },
  syncCkb: boolean = false,
) {
  dispatch({ type: 'REQUEST_LOGIN' })

  try {
    const userAddress =
      typeof userInfo.account === 'string'
        ? userInfo?.account
        : userInfo.account.address
    const evmAddressCheck = userAddress.startsWith('0x')
    let ckbAddress = !evmAddressCheck ? userAddress : ''
    let activeChain = userInfo?.chain

    if (!activeChain)
      activeChain = evmAddressCheck ? CHAIN_KEYS.godwoken : CHAIN_KEYS.ckb

    const queryParams = {
      app: userInfo.appId,
      chain: activeChain,
      address: userAddress,
      secondaryAddress: '',
      secondaryApp: userInfo.appId,
      secondaryChain:
        activeChain === CHAIN_KEYS.godwoken
          ? CHAIN_KEYS.ckb
          : CHAIN_KEYS.godwoken,
    }

    if (typeof userInfo.account === 'object') {
      queryParams.address = userInfo.account.address
      queryParams.secondaryAddress = userInfo.account.ethAddress
      queryParams.secondaryChain = CHAIN_KEYS.godwoken
    } else {
      const userLock = CkbController.getLock(userInfo.account, true)
      ckbAddress = getCkbAddress(userLock)

      queryParams.secondaryAddress = ckbAddress
    }

    const { data } = await axios.post('/login', queryParams)

    const token = data?.token
    const user = data.user
    if (!token) throw new Error('Login token not valid!')
    if (!user) throw new Error('User not found!')

    localStorage.setItem(AUTH_TOKEN_KEY, token)

    // Fresh logins will sync, re-login after 1 day to force sync
    if (syncCkb && ckbAddress) {
      const lastSyncTime =
        Number(window.localStorage.getItem(CKB_LAST_SYNC_KEY)) || 0
      const currentTime = Date.now()
      const fifteenMinutes = 15 * 60 * 1000

      if (!lastSyncTime || currentTime - lastSyncTime >= fifteenMinutes) {
        // @info await avoided on purpose
        CkbSync.runUserSync(ckbAddress)
        window.localStorage.setItem(CKB_LAST_SYNC_KEY, String(currentTime))

        // @TODO: Use Service Worker to deal with Sync behind the scenes
        // if ('serviceWorker' in navigator) {
        //   navigator.serviceWorker.controller?.postMessage({
        //     type: 'SYNC_USER',
        //     payload: {
        //       user,
        //       address: ckbAddress,
        //       token,
        //     },
        //   })
        // }
      }
    }

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: {
        appId: userInfo.appId,
        user: await getUser(userAddress),
        token,
      },
    })
  } catch (err) {
    dispatch({ type: 'LOGIN_ERROR', payload: err })
    console.error(err)
  }
}

export async function getUser(address: string | { [key: string]: string }) {
  try {
    const result = await axios.get(`/user/${address}`)

    if (!result.data.user) throw new Error('Error fetching user')

    return result.data.user
  } catch (err) {
    throw new Error('Error getting user!')
  }
}

const authReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        loading: true,
      }

    case 'LOGIN_SUCCESS':
      window.localStorage.setItem(CONNECTOR_STORAGE_KEY, action.payload.appId)

      return {
        ...state,
        user: action.payload.user,
        provider: action.payload.appId,
        token: action.payload.token,
        loading: false,
      }
    case 'UPDATE_USER_UNREAD':
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case 'LOGOUT':
    case 'LOGIN_ERROR':
      window.localStorage.removeItem(AUTH_TOKEN_KEY)
      window.localStorage.removeItem(CONNECTOR_STORAGE_KEY)
      window.localStorage.removeItem(JOYID_CKB_STORAGE_KEY)
      window.localStorage.removeItem(JOYID_EVM_STORAGE_KEY)

      return {
        ...state,
        user: null,
        token: null,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

// Use with Authentication layer later for front-end to back-end comms
// import * as ucans from "@ucans/ucans"

// export async function generateUCAN() {
//   // in-memory keypair
//   const keypair = await ucans.EdKeypair.create()

// const ucan = await ucans.build({
//   audience: "did:key:zabcde...", // recipient DID
//   issuer: keypair, // signing key
//   capabilities: [ // permissions for ucan
//     {
//       with: {scheme: "wnfs", hierPart: "//boris.fission.name/public/photos/"},
//       can: {namespace: "wnfs", segments: ["OVERWRITE"]}
//     },
//     // ... more capabilities ...
//   ]
// })

//   const token = ucans.encode(ucan) // base64 jwt-formatted auth token

//   return token
// }
