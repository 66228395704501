import React from 'react'
import { NavLink } from 'react-router-dom'

const MainNav = () => {
  return (
    <div className="navbar-nav main">
      <NavLink className="nav-link" to="/" exact={true}>
        <span>Home</span>
      </NavLink>

      <span className="nav-item dropdown">
        <button className="nav-link">
          <span>Explore</span>{' '}
          <i className="fa-solid fa-angle-down ml-1 prevent" />
        </button>

        <ul className="dropdown-menu">
          <li className="nav-item">
            <NavLink to="/explore/authors" className="nav-link">
              <span>Authors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/explore/collections" className="nav-link">
              <span>Collections</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/explore/live-auctions" className="nav-link">
              <span>Live Auctions</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/explore/listed-items" className="nav-link">
              <span>Listed Items</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/search" className="nav-link">
              <span>Search Items</span>
            </NavLink>
          </li>
        </ul>
      </span>

      <NavLink className="nav-link" to="/activity">
        <span>Activity</span>
      </NavLink>
    </div>
  )
}

export default MainNav
