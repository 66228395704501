import { ethers } from 'ethers'
import {
  getNftContract,
  getContractInfo,
  getContractObj,
  getContractViaAddress,
} from '../helpers'
// import multicallAbi from '../contracts/multicall3.json'
import { getIpfsHashFromFile, getIpfsHashFromJson } from '../storage/ipfs'
import {
  COLLECTION_FEE,
  EVM_FEE,
  EVM_FEE_ADDRESS,
  NFT_STANDARDS,
  NODES,
  TRANSFER_FEE,
} from '../constants'

/**
 * Governance Token Contract Management
 */
export async function getCollectionItemRoyalties(
  collectionAddress: string,
  library: any,
  type: string,
  version: number,
): Promise<[BigInt, BigInt]> {
  const collectionContract = getNftContract(
    collectionAddress,
    library,
    type,
    version,
  )

  if (!collectionContract) return [0n, 0n]

  try {
    let min = 0n
    let max = 0n
    try {
      min = await collectionContract.itemRoyaltyMin()
    } catch {
      min = await collectionContract.FEE_MIN_PERCENT()
    }

    try {
      max = await collectionContract.itemRoyaltyMax()
    } catch {
      max = await collectionContract.FEE_MAX_PERCENT()
    }

    if (version === 2) {
      min = min / 100n
      max = max / 100n
    } else {
      min = min / 10n
      max = max / 10n
    }

    return [min, max]
  } catch (error) {
    console.error('Error getting item royalties', error)
    return [0n, 0n]
  }
}

export async function updateCollectionData(
  data: { [key: string]: any },
  collectionAddress: string,
  library: any,
  type: string,
  version: number,
) {
  let newUriHash

  try {
    let bannerHash = data?.banner
    let thumbHash = data?.thumb

    if (typeof bannerHash === 'object')
      bannerHash = `ipfs://${await getIpfsHashFromFile(data?.banner)}`

    if (typeof thumbHash === 'object')
      thumbHash = `ipfs://${await getIpfsHashFromFile(data?.thumb)}`

    const collectionMetadata: Partial<CollectionUiMetadata> = {
      name: data?.collectionName,
      description: data?.collectionDescription,
      image: thumbHash,
      bannerImage: bannerHash,
      creator: data.creator.address,
      symbol: data?.collectionSymbol,
      royalty: data?.collectionRoyalty,
    }

    newUriHash = `ipfs://${await getIpfsHashFromJson(collectionMetadata)}`

    if (data.collectionUri !== newUriHash) data.updateUri = true
  } catch (error) {
    console.error('Error getting IPFS hash', error)
    return false
  }

  let signer: ethers.JsonRpcSigner

  try {
    signer = await library.getSigner()
  } catch (error) {
    console.error('Error getting signer', error)
    return false
  }
  const collectionContract = getNftContract(
    collectionAddress,
    signer,
    type,
    version,
  )

  if (!collectionContract) return false

  const methodData = []

  if (data.updateUri) {
    methodData.push(
      collectionContract.interface.encodeFunctionData('setCollectionURI', [
        newUriHash,
      ]),
    )
  }

  if (data.updateName) {
    methodData.push(
      collectionContract.interface.encodeFunctionData('setName', [
        data.collectionName,
      ]),
    )
  }

  if (data?.updatePublic) {
    methodData.push(
      collectionContract.interface.encodeFunctionData('setPublic', [
        data.collectionPublic,
      ]),
    )
  }

  if (data?.updateCollectionRoyalty) {
    methodData.push(
      collectionContract.interface.encodeFunctionData('setRoyalty', [
        data.collectionRoyalty,
      ]),
    )
  }

  if (data?.updateCollectionSymbol) {
    methodData.push(
      collectionContract.interface.encodeFunctionData('setSymbol', [
        data.collectionSymbol,
      ]),
    )
  }

  if (data.updateItemRoyaltyRange) {
    if (data.version === 1) {
      methodData.push(
        collectionContract.interface.encodeFunctionData('setFeeMin', [
          data.itemRoyaltyMin * 10,
        ]),
      )
      methodData.push(
        collectionContract.interface.encodeFunctionData('setFeeMax', [
          data.itemRoyaltyMax * 10,
        ]),
      )
    } else {
      methodData.push(
        collectionContract.interface.encodeFunctionData('setItemFeeRange', [
          data.itemRoyaltyMin * 100,
          data.itemRoyaltyMax * 100,
        ]),
      )
    }
  }

  const transactions: Array<{ [key: string]: any }> = []

  methodData.forEach((method) =>
    transactions.push({
      target: collectionAddress,
      callData: method,
    }),
  )

  try {
    // Initial code attempt with Multicall
    // Moving on to get other features out
    // const multicallAddress = '0xcA11bde05977b3631167028862bE2a173976CA11'
    // const multicallContract = new ethers.Contract(multicallAddress, multicallAbi, signer)

    // const transaction = {
    //   to: multicallAddress,
    //   data: multicallContract.interface.encodeFunctionData('aggregate', [transactions]),
    //   gasLimit: 20000000
    // }
    // console.log('transaction 1', transaction)

    // const signedTransaction = await signer.signTransaction(transaction)
    // const signedTransaction = await signer.populateTransaction(transaction)
    // console.log('Signed Transaction:', signedTransaction)

    let tx
    for await (const transaction of transactions) {
      tx = await signer.sendTransaction({
        to: transaction.target,
        data: transaction.callData,
      })

      console.info('Verifying Block for updated Collection Data')
      await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    }

    return tx?.hash ?? true
  } catch (error) {
    console.error('Error updating collection contract', error)
    return false
  }
}

export function isAddress(address: string) {
  try {
    ethers.getAddress(address)
  } catch (e) {
    return false
  }
  return true
}

export function toEth(amount: any) {
  return ethers.formatEther(String(amount))
}

export function toWei(amount: any) {
  return ethers.parseEther(String(amount))
}
/**
 * Governance Token Contract Management
 */
export async function getTokenBalance(
  account: any | ethers.Overrides,
  library: any,
) {
  try {
    return await library.getBalance(account)
  } catch {
    return 0n
  }

  // Generic grab for ERC20 Tokens
  // const Token = getContractObj(tokenName, library.getSigner())
  // if (Token) {
  //   const balance = await Token.balanceOf(account)
  //   return ethers.formatUnits(balance, tokenDecimal)
  // }
}

/**
 * NFT Factory Management
 */
export async function mintGodwokenCollection(
  name: any,
  uri: any,
  royalty: any,
  bPublic: any,
  provider: any,
  standard: string,
) {
  const contractToGet =
    standard === NFT_STANDARDS.erc721 ? 'ERC721Factory' : 'ERC1155Factory'

  const factoryContract = getContractObj(contractToGet, provider)

  // Appease the linter, but bigger problems if it fails here
  if (!factoryContract) throw Error('Factory Contract Missing!')

  try {
    const tx = await factoryContract.createCollection(
      name,
      name
        .split(' ')
        .map((string: string) =>
          string.length > 2 ? string.substring(0, 1) : string,
        )
        .join('')
        .substring(0, 7),
      uri,
      royalty,
      bPublic,
      { value: BigInt(COLLECTION_FEE * 10 ** 18) },
    )

    const receipt = await new ethers.JsonRpcProvider(
      NODES[0],
    ).waitForTransaction(tx.hash, 1)

    const logs = receipt?.logs ?? []
    const logsLength = logs?.length ?? 0

    for (let index = 0; index < logsLength; index++) {
      const parsedLog = factoryContract.interface.parseLog(logs[index])
      const topicHash =
        factoryContract.interface?.getEvent('CollectionCreated')?.topicHash

      if (topicHash === parsedLog?.topic) {
        const collectionAddress = factoryContract.interface.parseLog(
          logs[index],
        )?.args[1]

        return collectionAddress
      }
    }

    return false
  } catch (error: any) {
    console.error(error)
    console.error('Message::', error?.message)

    return false
  }
}

/**
 * NFT Contract Management
 */
export async function isNFTApprovedForMarket(
  collection: any,
  account: any | ethers.Overrides,
  provider: any,
  standard: any,
) {
  try {
    const marketContractInfo = getContractInfo('NationMarketV2')
    const nftToken = getNftContract(collection, provider, standard)

    if (!nftToken) return

    return await nftToken.isApprovedForAll(account, marketContractInfo.address)
  } catch (error) {
    console.error('Error checking approval status for Fixed Sales', error)
    return false
  }
}

export async function isNFTApprovedForAuction(
  collection: any,
  account: any | ethers.Overrides,
  provider: any,
  standard: any,
) {
  try {
    const nftToken = getNftContract(collection, provider, standard)

    if (!nftToken) return
    const auctionContractInfo = getContractInfo('NationAuctionV2')

    return await nftToken.isApprovedForAll(account, auctionContractInfo.address)
  } catch (error) {
    console.error('Error checking approval status for Auction', error)
    return false
  }
}

export async function setNFTApprovalForMarket(
  collection: any,
  provider: any,
  item: {
    standard: any
    collectionData: { standard: any; version: any }
    version: any
  },
) {
  const marketContractInfo = getContractInfo('NationMarketV2')
  const type =
    item?.standard ?? item?.collectionData?.standard ?? NFT_STANDARDS.erc721
  const version = item?.version ?? item?.collectionData?.version ?? 1
  const nftToken = getNftContract(collection, provider, type, version)

  if (!nftToken) return

  try {
    const tx = await nftToken.setApprovalForAll(
      marketContractInfo.address,
      true,
    )
    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    return tx.hash
  } catch (e) {
    console.error(e)
  }
  return false
}

export async function setNFTApprovalForAuction(
  collection: any,
  provider: any,
  item: {
    standard: any
    collectionData: { standard: any; version: any }
    version: any
  },
) {
  const auctionContractInfo = getContractInfo('NationAuctionV2')
  const type =
    item?.standard ?? item?.collectionData?.standard ?? NFT_STANDARDS.erc721
  const version = item?.version ?? item?.collectionData?.version ?? 1

  const nftToken = getNftContract(collection, provider, type, version)

  if (!nftToken) return

  try {
    const tx = await nftToken.setApprovalForAll(
      auctionContractInfo.address,
      true,
    )
    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    return tx.hash
  } catch (e) {
    console.error(e)
  }
  return false
}

export async function mintItems(
  currentCollection: any,
  items: any[],
  provider: any,
) {
  const collectionContract = getNftContract(
    currentCollection.address,
    provider,
    currentCollection.standard,
    currentCollection.version,
  )

  if (!collectionContract) return

  const mintedItems = []
  try {
    let tx
    if (currentCollection.version === 2) {
      if (currentCollection.standard === NFT_STANDARDS.erc721) {
        for await (const item of items) {
          tx = await collectionContract.mintWithFee(
            item.tokenURI,
            item.royalty,
            item.amount,
            {
              value: BigInt(EVM_FEE * 10 ** 18) * BigInt(item.amount),
            },
          )

          await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(
            tx.hash,
            1,
          )

          mintedItems.push(tx)
        }
      } else {
        tx = await collectionContract.mintWithFee(
          items.map((item) => item.tokenURI),
          items.map((item) => item.royalty),
          items.map((item) => item.amount),
          {
            value: BigInt(EVM_FEE * 10 ** 18) * BigInt(items.length),
          },
        )

        mintedItems.push(tx)
      }
    } else {
      if (currentCollection.standard === NFT_STANDARDS.erc721) {
        tx = await collectionContract.addItem(
          items[0].tokenURI,
          items[0].royalty,
        )
      } else {
        tx = await collectionContract.addItem(
          items[0].tokenURI,
          items[0].amount,
          items[0].royalty,
        )
      }

      mintedItems.push(tx)
    }

    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

    return mintedItems
  } catch (e) {
    // console.error(e)
    throw new Error('Error minting ERC NFT')
  }
}

/**
 * Market Contract Management
 */
export async function listItem(
  { collection, tokenId, amount, price }: any,
  provider: any,
) {
  const marketContract = getContractObj('NationMarketV2', provider)

  if (!marketContract) return false
  try {
    const tx = await marketContract.list(collection, tokenId, amount, price)

    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    return tx.hash
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function delistItem(
  marketAddress: string,
  listingId: any | ethers.Overrides,
  provider: ethers.ContractRunner | null | undefined,
) {
  const marketContract = getContractViaAddress(marketAddress, provider)

  if (!marketContract) return false
  try {
    const tx = await marketContract.delist(listingId)
    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    return tx.hash
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function buy(
  marketAddress: string,
  id: any | ethers.Overrides,
  amount: any | ethers.Overrides,
  price: any,
  provider: ethers.ContractRunner | null | undefined,
) {
  const marketContract = getContractViaAddress(marketAddress, provider)

  if (!marketContract) return false

  try {
    const tx = await marketContract.buy(id, amount, { value: price })
    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

    return tx.hash
  } catch (e) {
    console.error(e)
    return false
  }
}

/**
 * Auction Contract Management
 */
export async function createAuction(data: any, library: any) {
  const auctionContract = getContractObj('NationAuctionV2', library)

  if (!auctionContract) return false

  try {
    let tx = await auctionContract.createAuction(
      data.item.itemCollection,
      data.item.tokenId,
      data.amount,
      data.startPrice,
      data.startTime,
      data.endTime,
      data.minBidIncrementPercent,
    )

    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

    return tx.hash
  } catch (e) {
    console.error('Create Auction Error::', e)
    return false
  }
}

export async function finalizeAuction(
  auctionId: string,
  provider: ethers.ContractRunner | null | undefined,
  cancelled: any,
) {
  const splitAddress = auctionId.split('-')
  if (!splitAddress) {
    console.error('Can not resolve Auction Address')
    return false
  }

  const auctionContract = getContractViaAddress(splitAddress[0], provider)

  if (!auctionContract) return false

  let version = 2
  try {
    version = Number(await auctionContract.VERSION())
  } catch {
    version = 2
  }

  try {
    const auctionId = splitAddress[1]
    const methodCall =
      !cancelled || version === 1
        ? auctionContract.finalizeAuction
        : auctionContract.cancelAuction
    const tx = await methodCall(auctionId)

    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

    return tx.hash
  } catch (e) {
    console.error('Error Finalizing Auction::', e)

    throw e
  }
}

export async function bidOnAuction(
  id: any,
  price: any,
  auctionContract: { bidOnAuction: (arg0: any, arg1: { value: any }) => any },
) {
  if (!auctionContract) return false

  try {
    const tx = await auctionContract.bidOnAuction(id, { value: price })
    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    return tx.hash
  } catch (error) {
    throw error
  }
}

export async function getMinimumBidPercent(
  auctionContract: {
    minBidIncrementPercent: () => bigint | PromiseLike<bigint>
  },
  item: { bidPercentIncrement: number },
) {
  // 5 = minimum set on Auction Contract Deploy
  let percent = 5n

  try {
    percent = await auctionContract.minBidIncrementPercent()

    if (percent > 0n) percent = percent / 100n
  } catch (error) {
    console.info('Using listing fallback percent')
    percent = BigInt(item?.bidPercentIncrement / 100) ?? percent
  } finally {
    return percent
  }
}

export async function getAuctionBidsLength(
  auctionContract: any,
  auctionId: any,
) {
  try {
    const bidsAmount = await auctionContract.getBidsAmount(auctionId)

    return bidsAmount
  } catch (error) {
    console.error('Error getting Minimum Percent', error)

    return 0n
  }
}

export async function payTransferFee(provider: any) {
  try {
    const pckbContract = getContractObj('pCKB', provider)
    const paidFee = localStorage.getItem('userPaidFee')

    if (!pckbContract) throw Error('No pCKB Contract found!')
    if (paidFee === 'true') return true

    let tx = await pckbContract.transfer(
      EVM_FEE_ADDRESS,
      BigInt(TRANSFER_FEE * 10 ** 18),
    )

    localStorage.setItem('userPaidFee', 'true')

    await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

    return tx.hash
  } catch (error) {
    console.error('Error with Paying Transfer Fee::', error)

    localStorage.removeItem('userPaidFee')
    return false
  }
}

export async function transferNFT(
  collection: any,
  tokenId: any,
  fromAddress: any,
  toAddress: any,
  amount: any | ethers.Overrides,
  standard: string,
  version: number | undefined,
  provider: any,
) {
  const nftContract = getNftContract(collection, provider, standard, version)

  if (!nftContract) throw new Error('NFT Contract not found!')

  try {
    let tx

    if (!tokenId) {
      try {
        tx = await nftContract.transferCollection(toAddress)
      } catch {
        try {
          tx = await nftContract.transferCreator(toAddress)
        } catch {
          throw Error('Trouble transferring collection!')
        }
      }
      // receipt = await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)
    } else {
      // @ts-ignore
      if (standard === NFT_STANDARDS.erc1155) {
        tx = await nftContract.safeTransferFrom(
          fromAddress,
          toAddress,
          tokenId,
          amount,
          ethers.ZeroHash,
        )
      } else {
        tx = await nftContract.safeTransferFrom(fromAddress, toAddress, tokenId)
      }

      await new ethers.JsonRpcProvider(NODES[0]).waitForTransaction(tx.hash, 1)

      localStorage.removeItem('userPaidFee')
      return tx.hash
    }
  } catch (error) {
    console.error('Problem with Transfer', error)
    return false
  }
}
