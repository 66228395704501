import React, { useState, useEffect } from 'react'
import axios from 'axios'
import NFT from '../Item/NftCard'

const Collections = (props) => {
  const { isHome, showMore } = props
  const [collections, setCollections] = useState([])
  const [noCollections, setNoCollections] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    let query = `?page=${page}`

    if (isHome) query += '&pageLimit=8'

    axios
      .get(`/collections${query}`)
      .then((res) => {
        if (page === 1) setCollections(res.data.collections)
        else setCollections(collections.concat(res.data.collections))

        if (!res.data.collections.length || res.data.final)
          setNoCollections(true)

        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setCollections([...collections])
      })
  }, [page])

  function loadMore() {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  return (
    <section className="collection-cards">
      {collections?.length > 0 ? (
        <article className="container py-3">
          {isHome && (
            <div className="intro" style={{ columnGap: '1.5rem' }}>
              <div className="intro-content">
                <span>Most Popular</span>
                <h3 className="mt-1 mb-2">Popular Collections</h3>
              </div>
              <div className="intro-btn mb-2">
                <a
                  className="btn content-btn text-left"
                  href="/explore/collections"
                >
                  View All <i className="fa-solid fa-caret-right" />
                </a>
              </div>
            </div>
          )}
          <div className="grid items container mx-auto">
            {collections.map((collection, index) => {
              // @ts-ignore
              if (collection?.adminOverwrites) {
                // @ts-ignore
                collection = {
                  // @ts-ignore
                  ...collection,
                  // @ts-ignore
                  ...collection.adminOverwrites,
                }
              }

              return (
                <NFT
                  {...props}
                  item={collection}
                  cardType="collection"
                  key={index}
                />
              )
            })}
          </div>
          {showMore && (
            <div
              className="load-more"
              style={{
                display: noCollections ? 'none' : '',
                textAlign: 'center',
              }}
            >
              <button
                onClick={() => loadMore()}
                className="btn branded mt-3 mt-sm-4"
              >
                {loading ? 'Loading...' : 'Load more'}
              </button>
            </div>
          )}
        </article>
      ) : !isHome ? (
        <article className="text-center mb-4">
          <p>{!loading ? 'No Items Currently Listed' : 'Loading...'}</p>
        </article>
      ) : null}
    </section>
  )
}

export default Collections
