import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { SYMBOL_KEYS } from '../../utils/constants'
import { getUserName, prettyCommaFormat } from '../../utils/helpers'
import AuthorAvatar from '../Author/AuthorAvatar'

const TopBuyers = () => {
  const [items, setItems] = useState([])

  useEffect(() => {
    axios
      .get('/buyers?page=1&pageLimit=8')
      .then((res) => {
        setItems(res.data.buyers)
      })
      .catch((e) => {
        console.error(e)
        setItems([])
      })
  }, [])

  return (
    items.length > 0 && (
      <section id="TopBuyers">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro">
                <div className="intro-content">
                  <span>CREATIVE ARIST</span>
                  <h3 className="mt-1 mb-2">Top Buyers</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="grid items" style={{ gap: '0.5rem' }}>
            {items
              .filter((item) => item.buyer !== null)
              .map((item, idx) => {
                return (
                  <div key={`ts_${idx}`} className="item">
                    {/* Single buyer */}
                    <div className="card no-hover">
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 2.5fr',
                          gap: '1rem',
                        }}
                      >
                        <AuthorAvatar author={item.buyer} displayName={false} />
                        {/* Seller Info */}
                        <div className="seller-info">
                          <Link
                            className="seller mb-2"
                            to={`/account/${item?.buyer?.address}`}
                          >
                            {getUserName(item?.buyer)}
                          </Link>
                          <span>
                            {prettyCommaFormat(
                              BigInt(item?.totalAmount)?.toString() || 0,
                              18,
                            )}
                            &nbsp;{SYMBOL_KEYS.godwoken}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    )
  )
}

export default TopBuyers
