import React from 'react'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import ContactSection from '../components/Contact/Contact'

const Contact = () => {
  return (
    <div className="main">
      <Breadcrumb title="Contact" subpage="Pages" page="Contact" />
      <ContactSection />
    </div>
  )
}

export default Contact
