import { BaseSyntheticEvent, useState, useEffect, ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Alert, IconButton, Snackbar } from '@mui/material'
import { IPFS_URL } from '../utils/constants'
import { getIpfsHashFromFile } from '../utils/storage/ipfs'
import axios from 'axios'

import MediaWrapper from '../components/Media/MediaWrapper'
import {
  getCollectionItemRoyalties,
  updateCollectionData,
} from '../utils/EVM/EvmService'
import { getRenderReadyRoyalty } from '../utils/helpers'

import '../assets/css/updateCollection.scss'

const UpdateCollection = (props: { [key: string]: any }) => {
  const { account, chainId, provider } = useWeb3React()
  const { collectionAddress }: { [key: string]: any } = useParams()

  const [collection, setCollection] = useState<{ [key: string]: any }>({})
  const [bannerImageLoading, setBannerImageLoading] = useState(false)
  const [bannerImage, setBannerImage] = useState('')
  const [thumbImageLoading, setThumbImageLoading] = useState(false)
  const [thumbImage, setThumbImage] = useState('')
  const [collectionOwner, setCollectionOwner] = useState<{
    [key: string]: any
  }>({})
  const [collectionName, setCollectionName] = useState('')
  const [collectionSymbol, setCollectionSymbol] = useState('')
  const [collectionDescription, setCollectionDescription] = useState('')
  const [collectionRoyalty, setCollectionRoyalty] = useState(0)
  const [itemRoyaltyMin, setItemRoyaltyMin] = useState('')
  const [itemRoyaltyMax, setItemRoyaltyMax] = useState('')
  const [collectionPublic, setCollectionPublic] = useState('false')

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState(<></>)

  useEffect(() => {
    fetchCollection()
  }, [])

  useEffect(() => {
    setRoyalties()
  }, [chainId, collection])

  async function setRoyalties() {
    if (!chainId) return

    const itemRoyalties = await getCollectionItemRoyalties(
      collectionAddress,
      chainId,
      provider,
      collection?.standard ?? 'ERC721',
      collection?.version ?? 1,
    )

    setItemRoyaltyMin(itemRoyalties[0].toString())
    setItemRoyaltyMax(itemRoyalties[1].toString())
  }

  async function fetchCollection() {
    try {
      const { data } = await axios.get(`/collection/${collectionAddress}`)

      const collectionData = data?.collection
      if (!collectionData) return

      setCollection(collectionData)
      setBannerImage(collectionData?.bannerImage)
      setThumbImage(collectionData?.image)
      setCollectionOwner(collectionData?.owner)
      setCollectionName(collectionData?.name)
      setCollectionSymbol(collectionData?.symbol)
      setCollectionDescription(collectionData?.description)
      setCollectionRoyalty(
        getRenderReadyRoyalty({
          royalty: Number(collectionData.royalty),
          version: collectionData?.version,
        }),
      )
      setCollectionPublic(collectionData.isPublic)
    } catch (error) {
      console.error('Error fetching Collecting', error)
    }
  }

  const updateImageFile = async (
    type: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target?.files
    const fileType = file?.length ? file[0].type.split('/')[0] : ''

    if (fileType !== 'image' || !file) return

    if (type === 'banner') {
      setBannerImage('')
      setBannerImageLoading(true)
      setBannerImage(`${IPFS_URL}${await getIpfsHashFromFile(file[0])}`)
      setBannerImageLoading(false)
    } else if (type === 'thumb') {
      setThumbImage('')
      setThumbImageLoading(true)
      setThumbImage(`${IPFS_URL}${await getIpfsHashFromFile(file[0])}`)
      setThumbImageLoading(false)
    }
  }

  // const setNewValue = async (type, event) => {

  // }

  async function submitForm(event: BaseSyntheticEvent<SubmitEvent>) {
    event.preventDefault()

    if (!chainId) return

    const form = event.target
    const inputs = form.elements
    const data: { [key: string]: any } = {}

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i]

      if (input.type === 'file') {
        data[input.name] = input.files[0]
      } else if (input.type !== 'submit') {
        data[input.name] = input.value
      }
    }

    data.creator = collection?.creator
    data.version = collection?.version ?? 1
    data.collectionUri = collection?.uri
    data.collectionPublic = data.collectionPublic === 'true' ? true : false

    if (!data.banner) data.banner = bannerImage

    if (!data.thumb) data.thumb = thumbImage

    if (collectionName !== data.collectionName) data.updateName = true

    if (collectionPublic !== data.collectionPublic) data.updatePublic = true

    if (collectionRoyalty !== Number(data.collectionRoyalty))
      data.updateCollectionRoyalty = true

    if (collectionSymbol !== data.collectionSymbol)
      data.updateCollectionSymbol = true

    if (
      itemRoyaltyMin !== data.itemRoyaltyMin ||
      itemRoyaltyMax !== data.itemRoyaltyMax
    ) {
      data.updateItemRoyaltyRange = true
    }

    await updateCollectionData(
      data,
      collectionAddress,
      chainId,
      provider,
      collection?.standard ?? 'ERC721',
      collection?.version ?? 1,
    )
  }

  if (!account && account !== collectionOwner?.address)
    return (
      <h1 className="text-center mt-4" style={{ fontSize: '2rem' }}>
        Please{' '}
        <button className="btn btn-text" onClick={props.connectAccount}>
          login
        </button>{' '}
        to verify ownership of collection
      </h1>
    )

  return (
    <>
      <form id="UpdateCollection" onSubmit={submitForm}>
        <div className={`wrapper ${!!bannerImage ? 'has-image' : ''}`}>
          {bannerImage !== '' && (
            <MediaWrapper src={bannerImage} className="banner" />
          )}

          <div className="image-upload">
            <input
              type="file"
              id="cover_input_file"
              accept="image/*"
              multiple={false}
              onChange={(event) => updateImageFile('banner', event)}
              name="banner"
            />
            {bannerImageLoading ? (
              <span className="btn btn-bordered">Uploading ...</span>
            ) : (
              <span className="btn btn-bordered">
                Upload {bannerImage ? 'New ' : ' '}Banner image
              </span>
            )}
          </div>
        </div>

        <div className="details container">
          <h1>Update Collection</h1>

          <a
            href={`/collection/${collectionAddress}`}
            className="btn btn-primary view"
          >
            View Collection
          </a>

          <div className={`wrapper ${!!thumbImage ? 'has-image' : ''}`}>
            {thumbImage !== '' && (
              <MediaWrapper src={thumbImage} className="thumbnail" />
            )}

            <div className="image-upload">
              <input
                type="file"
                id="cover_input_file"
                accept="image/*"
                multiple={false}
                onChange={(event) => updateImageFile('thumb', event)}
                name="thumb"
              />
              {thumbImageLoading ? (
                <span className="btn btn-bordered">Uploading ...</span>
              ) : (
                <span className="btn btn-bordered">
                  Upload {thumbImage ? 'New ' : ' '}Thumbnail image
                </span>
              )}
            </div>
          </div>

          <div className="content">
            <span className="form-group">
              <label>Collection Name</label>
              <input
                type="text"
                className="form-control"
                defaultValue={collectionName}
                name="collectionName"
              />
            </span>

            <span className="form-group">
              <label>Collection Symbol</label>
              <input
                type="text"
                className="form-control"
                defaultValue={collectionSymbol}
                name="collectionSymbol"
              />
            </span>

            <span className="form-group">
              <label>Collection Description</label>
              <textarea
                className="form-control"
                defaultValue={collectionDescription}
                name="collectionDescription"
              />
            </span>

            <span className="form-group">
              <label>Collection Royalty</label>
              <input
                type="number"
                className="form-control"
                value={collectionRoyalty}
                onChange={(event) =>
                  setCollectionRoyalty(Number(event.target.value))
                }
                name="collectionRoyalty"
              />
            </span>

            <span className="form-group">
              <label>Item Royalty Range</label>
              <input
                type="number"
                className="form-control"
                defaultValue={itemRoyaltyMin}
                name="itemRoyaltyMin"
              />
              <input
                type="number"
                className="form-control"
                defaultValue={itemRoyaltyMax}
                name="itemRoyaltyMax"
              />
            </span>

            {/* Currently not enabled, may bring back or add to certain User Group
              <span className='form-group'>
              <label>Collection Public</label>
              <select name="collectionPublic" value={String(collectionPublic)} onChange={event => setCollectionPublic(event.target.value)}>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </span> */}
          </div>

          <div className="footer">
            <p>
              <em>
                *Requires transacting with the NFT Smart Contract for each item
                updated and will cost pCKB to cover gas fees.
              </em>{' '}
              <br />{' '}
              <em>
                **Updating all fields in 1 transaction is a possible feature to
                come.
              </em>
            </p>

            <button type="submit" className="submit btn btn-success">
              Submit Updates
            </button>
          </div>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={(_, reason) => {
          if (reason === 'clickaway') return
          setOpenSnackbar(false)
        }}
      >
        <Alert
          severity="warning"
          color="info"
          variant="filled"
          sx={{
            fontSize: '1.1rem !important',
            fontWeight: '500',
            fontStyle: 'italic',
          }}
        >
          {snackBarMessage}{' '}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={(): void => {
              setOpenSnackbar(false)
            }}
          >
            <i className="icon-close" />
          </IconButton>
        </Alert>
      </Snackbar>
    </>
  )
}

export default UpdateCollection
