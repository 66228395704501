import { useEffect, useState } from 'react'
import { useAuth } from '../../context/authContext'
import { useParams } from 'react-router'
import axios from 'axios'
import NftActivity from '../Item/NftActivity'
import { useHistory } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb/Breadcrumb'

const Notifications = (props: { [key: string]: any }) => {
  const { state, dispatch } = useAuth()
  const { user } = state
  const { address } = useParams<{ address: string }>()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [buttonLabel, setButtonLabel] = useState('Load More')
  const [pageLoadCheck, setPageLoadCheck] = useState(false)
  const [isFinalCheck, setIsFinalCheck] = useState([false, false])
  const [pageLimit, setPageLimit] = useState(window.innerWidth <= 767 ? 2 : 5)
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (!user) return

    if (user?.address && user.address !== address) {
      history.push(`/account/${user.address}/notifications`)
    }

    setLoading(true)
    setOffset(user?.unreadActivities?.length ?? 0)
    setPage(user?.unreadActivities?.length > 0 ? 0 : 1)

    let isMounted = true

    if (!pageLoadCheck && isMounted) loadMore()

    return () => {
      isMounted = false
    }
  }, [user])

  useEffect(() => {
    if (isFinalCheck[0]) {
      setButtonLabel('Load Old Activities')
    } else {
      setButtonLabel('Load More')
    }
  }, [isFinalCheck])

  const fetchItems = async (query: string) => {
    try {
      const result = await axios.get(`/activities/${user?.address}?${query}`)

      setItems((prevItems) => prevItems.concat(result.data.items))

      setIsFinalCheck(result?.data?.final)

      if (user?.unreadActivities?.length > 0) await clearReadItems()
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const clearReadItems = async () => {
    await axios.post(`/activities/${user?.address}/clear?offset=${pageLimit}`)

    user?.unreadActivities?.splice(0, pageLimit)

    if (user?.undreadActivities?.length === 0) setPage(0)

    dispatch({ type: 'UPDATE_USER_UNREAD', payload: user })
  }

  const loadMore = async () => {
    let query = new URLSearchParams()
    query.set('pageLimit', String(pageLimit))

    if (isFinalCheck[0]) {
      query.set('page', String(page + 1))
      query.set('offset', String(offset))
      setPage(page + 1)
    }

    await fetchItems(decodeURIComponent(query.toString()))

    if (!pageLoadCheck) setPageLoadCheck(true)
  }

  if (!user) {
    return (
      <h1 className="text-center mt-4" style={{ fontSize: '2rem' }}>
        Please{' '}
        <button className="btn btn-text" onClick={props.connectAccount}>
          login
        </button>{' '}
        to see new notifications
      </h1>
    )
  }

  return (
    <div id="Notifications">
      <Breadcrumb {...props} title="Your latest activity" />

      {items.length > 0 && (
        <div className="nft-activity container smaller my-4">
          {items.map((item: { [key: string]: any }, index: number) => (
            <NftActivity
              data={item}
              key={`${item?.typeId ?? item?.id}-${index}`}
            />
          ))}
        </div>
      )}

      {(!isFinalCheck[0] || !isFinalCheck[1]) && !loading && (
        <button
          className="btn btn-primary d-block my-4 mx-auto"
          onClick={() => loadMore()}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  )
}

export default Notifications
