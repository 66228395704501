import { useMemo } from 'react'
import { CHAIN_KEYS, NFT_STANDARDS } from '../../utils/constants'

import NervosLogoBlack from '../../assets/img/nervos-logo-black.png'
import GodwokenLogo from '../../assets/img/godwoken-logo.png'

const ChainLogo = (props: { standard: string; title: string }) => {
  const { standard, title } = props

  const nftStandardLogoSource = useMemo(() => {
    let image
    if (!standard) return image

    if (
      standard === CHAIN_KEYS.ckb ||
      standard === NFT_STANDARDS.spore ||
      standard === NFT_STANDARDS.cota
    ) {
      image = NervosLogoBlack
    } else if (
      standard === CHAIN_KEYS.godwoken ||
      standard === NFT_STANDARDS.erc721 ||
      standard === NFT_STANDARDS.erc1155
    ) {
      image = GodwokenLogo
    }

    return image
  }, [standard])

  return !!standard ? (
    <img
      className="nft-standard-logo"
      src={nftStandardLogoSource}
      title={
        title ||
        `${standard === CHAIN_KEYS.ckb ? 'CKB' : 'Godwoken'} Blockchain Logo`
      }
    />
  ) : null
}

export default ChainLogo
