import { Link } from 'react-router-dom'
import HighlightIcon from '@mui/icons-material/Highlight'

import NervapeGearSpotlight from '../../assets/img/spotlight/nervape-gear-nervos-ckb-layer-1.webp'
import NervapeGearSpotlightMobile from '../../assets/img/spotlight/nervape-gear-nervos-ckb-layer-1-mobile.webp'

import DragonEggBanner from '../../assets/img/spotlight/world3-dragon-eggs-on-imagination-market.webp'
import DragonEggBannerMobile from '../../assets/img/spotlight/world3-dragon-eggs-on-imagination-market-mobile.webp'

import Elementals from '../../assets/img/spotlight/elementals-banner-imagiNation-spotlight-nervos-ckb-spore-cluster-collection.jpg'
import ElementalsMobile from '../../assets/img/spotlight/elementals-thumb-imagiNation-spotlight-nervos-ckb-spore-cluster-collection.jpg'

const HeroSpotlight = () => {
  // Add your component logic here

  return (
    <div id="Spotlight">
      <h2>
        <HighlightIcon />
        <span>Spotlight</span>
        <HighlightIcon />
      </h2>
      <div className="content">
        <div
          id="Spotlight--Carousel"
          className="carousel slide"
          data-ride="carousel"
          data-interval="15000"
          data-pause="hover"
        >
          <ol className="carousel-indicators">
            <li
              className="active"
              data-target="#Spotlight--Carousel"
              data-slide-to="0"
            ></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="1"></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="2"></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            {/* SPOTLIGHT #1 */}
            <div className="carousel-item active">
              <div className="collection">
                <span className="image-wrapper full">
                  <img
                    src={Elementals}
                    className="d-none d-md-inline"
                    alt="A Journey through the elements and the forging of a new dawn."
                    title="A Journey through the elements and the forging of a new dawn."
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                  <img
                    src={ElementalsMobile}
                    className="d-inline d-md-none"
                    alt="A Journey through the elements and the forging of a new dawn."
                    title="A Journey through the elements and the forging of a new dawn."
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                </span>

                <h4
                  className="title sr-only"
                  title="A Journey through the elements and the forging of a new dawn."
                >
                  Elementals
                </h4>

                <Link
                  to="/collection/0x16de0347761939d9a7f33eb5f7f260ad140b29e7a70e350ec65693992c18f503"
                  className="btn btn-block btn-danger mb-0"
                  style={{ fontWeight: 'bold' }}
                >
                  Secure an Element!
                </Link>
              </div>
            </div>
            {/* END SPOTLIGHT #1 */}

            {/* SPOTLIGHT #2 */}
            <div className="carousel-item">
              <div className="collection">
                <span className="image-wrapper full">
                  <img
                    src={NervapeGearSpotlight}
                    className="d-none d-md-inline"
                    alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market"
                    title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market"
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                  <img
                    src={NervapeGearSpotlightMobile}
                    className="d-inline d-md-none"
                    alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market"
                    title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market"
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                </span>

                <h4 className="title sr-only" title="Nervape Gear">
                  Nervape Gear
                </h4>

                <Link
                  to="/collection/nervape-gear"
                  className="btn btn-block btn-success"
                  style={{ fontWeight: 'bold' }}
                >
                  Explore Nervape Gear!
                </Link>
              </div>
            </div>
            {/* END SPOTLIGHT #2 */}

            {/* SPOTLIGHT #3 */}
            <div className="carousel-item">
              <div className="collection has-content">
                <span className="image-wrapper full">
                  <img
                    src={DragonEggBanner}
                    className="d-none d-md-inline"
                    alt="World3 Dragon Eggs on imagiNation Market"
                    title="World3 Dragon Eggs on imagiNation Market"
                    crossOrigin="anonymous"
                  />
                  <img
                    src={DragonEggBannerMobile}
                    className="d-inline d-md-none"
                    alt="World3 Dragon Eggs on imagiNation Market"
                    title="World3 Dragon Eggs on imagiNation Market"
                    crossOrigin="anonymous"
                  />
                </span>

                <div className="content">
                  <h4 className="title" title="World3 Dragon DOB Eggs">
                    World3 Dragon Eggs
                  </h4>
                  <p>
                    The <strong>next</strong> and <em>cutest</em>{' '}
                    <u>AI-companion</u> is waiting for you!
                  </p>
                </div>

                <Link
                  to="/collection/0xf717625c0161f88007e187743f05e2ec8493e22ccdffb1de561c42b6b5c64cf8"
                  className="btn btn-block btn-success"
                  style={{ fontWeight: 'bold' }}
                >
                  View Eggs
                </Link>
              </div>
            </div>
            {/* END SPOTLIGHT #3 */}

            {/* SPOTLIGHT #4 */}
            <div className="carousel-item">
              <div className="collection nation">
                <span className="image-wrapper">
                  <img
                    src="//ipfs.io/ipfs/QmVQM1TePXpdAiWTsRWSatdfxivCcxuysnJnfrq1vKe6VF"
                    alt=""
                    title=""
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                </span>

                <h4 className="title" title="Final Tom-a-bot Battlers">
                  Spore Nation
                </h4>

                <div className="description contained">
                  <p>
                    A collection of Spores <em>sprouted</em> by the community.
                    From your stick-man drawings to 3D Renderings, explore the
                    possibilities of CKB with your <strong>imagination</strong>{' '}
                    today!
                  </p>

                  <Link
                    to="/collection/0x1"
                    className="btn btn-block btn-danger"
                  >
                    View Sprouted Spores
                  </Link>

                  <p>
                    <small></small>
                  </p>
                </div>

                <span className="image-wrapper extra">
                  <img
                    src="//ipfs.io/ipfs/QmQdWw27xnzhU8Pt3N6Qc7jkXLqSQRNwezbJjF5yPhhGBL"
                    alt=""
                    title=""
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                </span>
              </div>
            </div>
            {/* END SPOTLIGHT #4 */}
          </div>{' '}
          {/* end carousel inner */}
        </div>
      </div>
    </div>
  )
}

export default HeroSpotlight
