import { CONTRACTS_BY_NETWORK } from './contracts'

export * from '@imagination/common'

export const EVM_TESTNET_CHAIN_ID = 71401
export const EVM_MAINNET_CHAIN_ID = 71402

// Random IDs for internal use
export const CKB_TESTNET_CHAIN_ID = 71393
export const CKB_MAINNET_CHAIN_ID = 71394

export const CHAIN_NETWORK_MAP = {
  testnet: [EVM_TESTNET_CHAIN_ID, CKB_TESTNET_CHAIN_ID],
  mainnet: [EVM_MAINNET_CHAIN_ID, CKB_MAINNET_CHAIN_ID],
}

export const NETWORK_ID = Number(
  process.env?.REACT_APP_NETWORK_ID ?? EVM_TESTNET_CHAIN_ID,
)
export const NETWORK = process.env?.REACT_APP_NETWORK ?? 'testnet'
export const EVM_NETWORK_NAME =
  process.env?.REACT_APP_NETWORK_NAME ?? 'Godwoken Network'
export const PROXY_HOST =
  process.env?.REACT_APP_API_HOST ?? 'https://api.nft-nation.live'
export const NODES = [
  process.env?.REACT_APP_NODE_1 ?? '',
  process.env?.REACT_APP_NODE_2 ?? '',
  process.env?.REACT_APP_NODE_3 ?? '',
]
export const EXPLORER_URL =
  process.env?.REACT_APP_EXPLORER_URL ?? 'https://gwscan.com'
export const IPFS_URL = process.env?.REACT_APP_IPFS_URL ?? 'ipfs://'

export const INDEXED_DB_NAME = 'iNation-Market'
export const CREATE_ITEM_STORE_NAME = 'CreateItems'
export const CREATE_COLLECTION_STORE_NAME = 'CreateCollection'
export const USER_DB_STORE_NAME = 'UserActions'

// Keys inside a Store
export const COTA_REGISTRY_PAID_DB_KEY = 'CotaRegistryPaid'

const networkAddresses = Object.entries(CONTRACTS_BY_NETWORK[NETWORK]).flatMap(
  (contractObject) => {
    const addresses = Object.values(contractObject[1]).filter((value) =>
      typeof value === 'string' ? value.toLocaleLowerCase() : false,
    )

    return addresses?.length > 0 ? addresses : []
  },
)

const extraNetworkAddresses = {
  testnet: [
    '0x239418e1084ade89bF8fb3F42964a66dB65Ea108'.toLowerCase(), // Factory V0
    '0xb227849f7D3352E50A7892406C6e85c8Cf3A80aA'.toLowerCase(), // Factory V1
  ],

  mainnet: [
    '0x40cca4eb64757e7ce80600a50eb08c0ec4d96ed3'.toLowerCase(), // Old Common Nation
    '0xf08a422da1b83a78263180f83573b08b6e24b93e'.toLowerCase(),
    '0x21ef00CA1EaFDD0da6e7a8C1b5a55085e92725Ac'.toLowerCase(),
    '0x92d5aa4aD01ABDB620FB3E411CF40cC90f47dA94'.toLowerCase(),
    '0x78c47bf6daf128a6c8e16c2dc42fe38d5a6f4421'.toLowerCase(),
    '0x122c39e3c49896d67b1554765bcc7cb08c9c34fb'.toLowerCase(),
  ],
}

networkAddresses.push(...extraNetworkAddresses[NETWORK])

export const HIDDEN_ADDRESSES = networkAddresses
