import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Work from '../Work/Work'

class Footer extends Component {
  render() {
    return (
      <>
        <Work />

        <footer>
          <div className="footer-area container">
            <section className="tagline">
              {/* Footer Items */}
              {/* Logo */}
              <Link className="navbar-brand" to="/">
                <img
                  className="logo"
                  src="/imagiNation-logo-white.svg"
                  alt="imagiNation Marketplace - White Logo"
                />
              </Link>
              <p className="tagline">
                <em className="d-block">
                  Create, Buy &amp; Sell Digital Collectibles.
                </em>
                <strong>Unleash Your Imagination!</strong>
              </p>
            </section>

            <section className="footer-links">
              <h4 className="footer-title">Useful Links</h4>
              <ul>
                <li>
                  <Link to="/explore/collections">All Collections</Link>
                </li>
                <li>
                  <Link to="/create">Create</Link>
                </li>
                <li>
                  <Link to="/explore/listed-items">Explore</Link>
                </li>
                {/* <li><a href="#">Privacy & Terms</a></li> */}
                {/* Update with new Gitbook deploy */}
                <li>
                  <a
                    href="https://guide.imagination.to/"
                    target="_blank"
                    rel="noopener"
                  >
                    Help & Information
                  </a>
                </li>
                <li>
                  <Link to="/bridge">EVM Cross-Chain Bridge</Link>
                </li>
              </ul>
            </section>

            <section className="social-icons">
              {/* Social Icons */}
              <a
                className="twitter"
                href="https://x.com/imagiNation_mkt/"
                target="_blank"
                rel="noopener"
              >
                <svg
                  style={{ width: '25px', height: '100%', color: '#000' }}
                  width="1200"
                  height="1227"
                  viewBox="0 0 1200 1227"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="#fff"
                  />
                </svg>
                <svg
                  style={{ width: '25px', height: '100%' }}
                  width="1200"
                  height="1227"
                  viewBox="0 0 1200 1227"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                className="discord"
                href="https://discord.gg/2DBVH3qgMf"
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-discord" />
                <i className="fab fa-discord" />
              </a>
              <a
                className="telegram"
                href="https://t.me/NervosNation"
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-telegram" />
                <i className="fab fa-telegram" />
              </a>
              <a
                className="youtube"
                href="http://youtube.com/NervosNation"
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-youtube" />
                <i className="fab fa-youtube" />
              </a>
              <a
                className="medium"
                href=" https://medium.com/nervos-nation"
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-medium" />
                <i className="fab fa-medium" />
              </a>
            </section>

            <section className="copyright-area">
              {/* Copyright Area */}
              {/* Copyright Left */}
              <span className="copy">
                &copy; imagiNation. All Rights Reserved.
              </span>
              {/* Copyright Right */}
              <span className="powered-by">
                <span>
                  Built on{' '}
                  <a
                    href="https://nervos.org/"
                    target="_blank"
                    style={{ fontSize: '0.9rem', textDecoration: 'underline' }}
                    rel="noopener"
                  >
                    Nervos Network
                  </a>
                </span>{' '}
                &bull;{' '}
                <span>
                  Powered by{' '}
                  <a
                    href="https://nervosnation.com/"
                    target="_blank"
                    style={{ fontSize: '0.9rem', textDecoration: 'underline' }}
                    rel="noopener"
                  >
                    Nervos Nation
                  </a>
                </span>
              </span>
            </section>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
