import { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
// import { useWeb3React } from '@web3-react/core'
import axios from 'axios'

import { Tag } from '@styled-icons/fa-solid/Tag'
import { AccessTimeFilled } from '@styled-icons/material'
import { CircularProgress } from '@mui/material'
import { ensureMillisecondsFormat } from '@imagination/common'

import {
  CHAIN_KEYS,
  NFT_STANDARDS,
  SYMBOL_KEYS,
  USER_DB_STORE_NAME,
} from '../../utils/constants'
import {
  listItem as listEvmItem,
  createAuction as createEvmAuction,
  isNFTApprovedForMarket,
  isNFTApprovedForAuction,
  setNFTApprovalForMarket,
  setNFTApprovalForAuction,
} from '../../utils/EVM/EvmService'
import {
  prettyCommaFormat,
  prettyDecimalFormatInput,
} from '../../utils/helpers'
import NationDB from '../../utils/IndexedDB'
import { getSporeListPackagePrice, listSpore } from '../../utils/CKB/CkbMarket'

import './modals.scss'

const MarketModal = ({
  connector,
  item,
  isOpen,
  onListed,
  onRequestClose,
  ownedAmount,
  provider,
  setOpenSnackbar,
  setSnackBarMessage,
  user,
}: any) => {
  // const { chainId, provider } = useWeb3React()
  const [amount, setAmount] = useState(1n)
  const [putType, setPutType] = useState('')
  const [store, setStore] = useState<typeof NationDB | null>(null)
  const [creatingAuctionStatus, setCreatingAuctionStatus] = useState(false)
  const [startType, setStartType] = useState('now')
  const [endType, setEndType] = useState('1')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [listingStatus, setListingStatus] = useState(false)
  const [prettyDecimalFormatFixedPrice, setPrettyDecimalFormatFixedPrice] =
    useState(0)
  const [price, setPrice] = useState(0n)
  const [approvedForMarket, setApprovedForMarket] = useState(false)
  const [approvedForAuction, setApprovedForAuction] = useState(false)
  const [approving, setApproving] = useState(false)
  const [warning, setWarning] = useState('')

  const decimals = item?.chain === CHAIN_KEYS.ckb ? 8 : 18

  const onListedCallback = (
    tokenId: string,
    xContent: { [key: string]: any },
  ) => {
    onListed(tokenId, xContent)

    setCreatingAuctionStatus(false)
    setPrice(0n)
    setAmount(1n)
  }

  const itemRoyalties = useMemo(() => {
    let royalty = 0n
    let divider = 10_000n

    // Help render a few items where Royalty was saved as a FLOAT
    if (item?.royalty)
      royalty = BigInt(Number(item.royalty).toString().split('.').join(''))
    if (
      item?.chain !== CHAIN_KEYS.ckb &&
      item?.collectionData?.version !== 2 &&
      item?.version !== 2
    )
      divider = 1000n

    return royalty ? (price * royalty) / divider : 0n
  }, [item, price])

  const collectionRoyalties = useMemo(() => {
    let royalty = 0n
    let divider = 10_000n

    if (item?.collectionData?.royalty)
      royalty = BigInt(item.collectionData.royalty)

    if (
      item?.chain !== CHAIN_KEYS.ckb &&
      item?.collectionData?.version !== 2 &&
      item?.version !== 2
    )
      divider = 1000n

    return royalty ? (price * royalty) / divider : 0n
  }, [item, price])

  const calculatedRoyalties = useMemo(() => {
    let royalty = 0n
    let divider = 10_000n

    if (collectionRoyalties > 0n) royalty = BigInt(item.collectionData.royalty)
    if (itemRoyalties > 0n) royalty += BigInt(item.royalty)

    if (
      item?.chain !== CHAIN_KEYS.ckb &&
      item?.collectionData?.version !== 2 &&
      item?.version !== 2
    )
      divider = 1000n

    return royalty ? (price * royalty) / divider : 0n
  }, [item, price, collectionRoyalties, itemRoyalties])

  const calculatedTotalReturns = useMemo(() => {
    if (!price || price <= 0) return 0n

    if (!calculatedRoyalties && item?.chain === CHAIN_KEYS.ckb) {
      return price - getSporeListPackagePrice(item?.owners[0]?.account.address)
    }

    const devFee = (price * 250n) / 10_000n

    return price - calculatedRoyalties - devFee
  }, [calculatedRoyalties, price])

  useEffect(() => {
    if (!item || !user?.address || !provider) return

    async function checkApprovalStatus() {
      const signer = await provider.getSigner()
      const address =
        signer?.address ?? (await signer?.getAddress()) ?? user?.address

      if (!address) return

      const itemStandard = !!item?.standard
        ? item?.collectionData?.standard
        : NFT_STANDARDS.erc721

      const isApprovedForMarket = await isNFTApprovedForMarket(
        item.itemCollection,
        address,
        signer,
        itemStandard,
      )

      if (isApprovedForMarket) setApprovedForMarket(true)

      const isApprovedForAuction = await isNFTApprovedForAuction(
        item.itemCollection,
        address,
        signer,
        itemStandard,
      )

      if (isApprovedForAuction) setApprovedForAuction(true)
    }

    checkApprovalStatus()
  }, [item, user?.address, provider])

  useEffect(() => {
    let isMounted = true

    async function setupStore() {
      if (!isMounted) return

      const newStore = await NationDB.initDB(USER_DB_STORE_NAME)

      setStore(newStore)
    }

    if (!store) setupStore()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (price >= 0n)
      setPrettyDecimalFormatFixedPrice(
        prettyDecimalFormatInput(price, decimals),
      )

    if (price !== 0n && item?.capacity && price < BigInt(item.capacity)) {
      setWarning(
        'Listing price set lower than inherent CKB value. You are losing CKB!',
      )
    } else {
      setWarning('')
    }
  }, [decimals, item, price])

  const renderListButton = useMemo(() => {
    let preText = ''

    if (
      item.standard === NFT_STANDARDS.erc1155 ||
      item.standard === NFT_STANDARDS.erc721
    ) {
      preText = isApproved(putType) ? '2. ' : '1. '
    }

    let buttonElement = isApproved(putType) ? (
      <button className="btn branded" onClick={() => putOnMarketPlace()}>
        {preText}Confirm{' '}
        {putType === 'fixed' ? 'Fixed Sale' : 'Auction Listing'}
      </button>
    ) : (
      <button
        className="btn btn-solid-warn"
        onClick={putType === 'fixed' ? approveMarket : approveAuction}
      >
        {preText}Approve {putType === 'fixed' ? 'Market' : 'Auction'}
      </button>
    )

    return buttonElement
  }, [approvedForMarket, approvedForAuction, putType, price])

  function fixAmountValidator(value: string, checkAmount: BigInt) {
    if (!value || isNaN(Number(value))) return

    const amount = BigInt(value)
    if (checkAmount < amount) return
    setAmount(amount)
  }

  function isApproved(saleType: string) {
    if (
      (item.standard !== NFT_STANDARDS.erc1155 &&
        item.standard !== NFT_STANDARDS.erc721) ||
      (saleType === 'fixed' && approvedForMarket) ||
      (saleType === 'timed' && approvedForAuction)
    ) {
      return true
    }

    return false
  }

  async function approveMarket() {
    if (!user?.address || !provider) return
    setApproving(true)
    const approved = await setNFTApprovalForMarket(
      item.itemCollection,
      await provider.getSigner(),
      item,
    )

    if (approved) setApprovedForMarket(true)
    setApproving(false)
  }

  async function approveAuction() {
    if (!user?.address || !provider) return

    setApproving(true)
    const approved = await setNFTApprovalForAuction(
      item.itemCollection,
      await provider.getSigner(),
      item,
    )

    if (approved) setApprovedForAuction(true)
    setApproving(false)
  }

  async function putOnMarketPlace() {
    setListingStatus(true)

    try {
      const fees = {
        listing: '0',
        itemRoyalty: BigInt(item?.royalty ?? 0).toString(),
        creatorRoyalty: BigInt(item.collectionData?.royalty ?? 0).toString(),
        dev: ((price * 250n) / 10_000n).toString(),
      }

      let signer
      if (connector?.isJoyId) {
        signer = connector
      } else if (provider && 'getSigner' in provider)
        signer = await provider?.getSigner()
      else signer = provider

      let txHash = ''
      if (putType === 'fixed') {
        switch (item.standard) {
          case NFT_STANDARDS.erc1155:
          case NFT_STANDARDS.erc721:
            txHash = await listEvm(price)

            if (txHash)
              await axios.post(
                `/sync/Godwoken/item/${item.itemCollection}/${item.tokenId}`,
              )

            break

          case NFT_STANDARDS.spore:
            const ckbAddress = user.addresses.findLast(
              (addressObject: any) => addressObject.chain === CHAIN_KEYS.ckb,
            ).address

            fees.listing = getSporeListPackagePrice(ckbAddress).toString()

            txHash = await listSpore(signer, {
              userAddress: ckbAddress,
              collectionAddress: item.itemCollection,
              tokenId: item.tokenId,
              listPrice: price,
              activeWallet: user?.activeWallet,
              fees,
              activityCount: item?.activities?.length ?? 1,
            })

            break

          default:
            throw new Error('Item Standard not set or recognized!')
        }

        if (txHash) {
          await onListedCallback(item.tokenId, {
            mainText: (
              <p>
                <strong>{item?.name ?? 'NFT'}</strong> successfully listed for{' '}
                <strong>
                  {prettyCommaFormat(price.toString(), decimals)}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </strong>
                !<br /> Let others know by <em>sharing it on X</em> now!
              </p>
            ),
            tweetText: `I just listed my NFT ${item?.name ?? ''} for ${prettyCommaFormat(price, decimals)} ${item?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken} on @imagiNation_mkt, make sure to check it out!`,
          })
        }
      } else if (putType === 'timed') {
        await createAuction(price)
      }
    } catch (error: any) {
      setSnackBarMessage(
        `Error placing item on market!${error?.message ? ` ${error.message}` : ''}`,
      )
      setOpenSnackbar(true)
    } finally {
      setListingStatus(false)
    }
  }

  async function listEvm(price: number | bigint) {
    if (!item) return

    if (price <= 0n) {
      setSnackBarMessage('Please input price correctly!')
      setOpenSnackbar(true)
      return
    }
    if (NFT_STANDARDS.erc1155 === item?.standard && !amount) {
      setSnackBarMessage('Please specify amount greater than 0')
      setOpenSnackbar(true)
      return
    }

    const signer = await provider?.getSigner()

    return await listEvmItem(
      {
        collection: item.itemCollection,
        tokenId: item.tokenId,
        amount,
        price,
      },
      signer,
    )
  }

  async function createAuction(price: number | bigint) {
    if (price <= 0n) {
      setSnackBarMessage('Please input price correctly!')
      setOpenSnackbar(true)
      return
    }
    if (NFT_STANDARDS.erc1155 === item?.standard && !amount) {
      setSnackBarMessage('Please specify amount greater than 0')
      setOpenSnackbar(true)
      return
    }
    const currentTime = new Date().getTime()

    let startTimeStamp = ensureMillisecondsFormat(currentTime)

    if (startType === 'specific') {
      if (!startDate) {
        setSnackBarMessage('Please select start time.')
        setOpenSnackbar(true)
        return
      }
      const startTime = startDate.getTime()

      if (currentTime >= startTime) {
        setSnackBarMessage('The start time must be after the current time.')
        setOpenSnackbar(true)
        return
      }
      startTimeStamp = ensureMillisecondsFormat(startTime)
    } else {
      // Remove 30 seconds so Auctions are immediately available.
      startTimeStamp -= 30
    }

    let endTimeStamp = 0
    if (endType === 'specific') {
      if (!endDate) {
        setSnackBarMessage('Please select end time.')
        setOpenSnackbar(true)
        return
      }
      const endTime = endDate.getTime()
      endTimeStamp = ensureMillisecondsFormat(endTime)
      if (currentTime >= endTime) {
        setSnackBarMessage('The end time must be after the current time.')
        setOpenSnackbar(true)
        return
      }
      if (startTimeStamp >= endTimeStamp) {
        setSnackBarMessage('The end time must be after the start time.')
        setOpenSnackbar(true)
        return
      }
    } else {
      const later = Number(endType)
      endTimeStamp = startTimeStamp + 86400 * later
    }

    setCreatingAuctionStatus(true)
    createEvmAuction(
      {
        item,
        amount: amount.toString(),
        startPrice: price.toString(),
        startTime: startTimeStamp,
        endTime: endTimeStamp,
        minBidIncrementPercent: '500',
      },
      await provider?.getSigner(),
    ).then((tokenId) =>
      onListedCallback(tokenId, {
        mainText: (
          <p>
            Auction for <strong>{item?.name ?? 'NFT'}</strong> successfully
            created!
            <br /> <em>Share</em> the glory with others on <em>X</em> now!
          </p>
        ),
        tweetText: `I just started an Auction for ${item?.name ?? 'an NFT'} on @imagiNation_mkt starting at ${prettyCommaFormat(price, decimals)}${item?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}, make sure to check it out!`,
      }),
    )
  }

  function onSetPrice(value: string) {
    let convertedValue = 0n

    if (value) {
      const decimalCheck = value.split('.')
      const value2 = !isNaN(Number(decimalCheck[1]))
        ? decimalCheck[1].padEnd(decimals, '0')
        : '0'.padEnd(decimals, '0')

      convertedValue = BigInt(decimalCheck[0] + value2)
    }

    setPrice(convertedValue)
  }

  return (
    <Modal
      id="market-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(60, 57, 56, 0.85)',
          zIndex: 1100,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: 'auto',
          maxHeight: '100%',
          maxWidth: '500px',
          borderRadius: '20px',
          backgroundColor: 'rgba(222, 218, 221, 0.975)',
          color: 'black',
          zIndex: 9999,
        },
      }}
    >
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2>Create Market Listing</h2>

          <div className="market-type">
            <div
              onClick={() => setPutType('fixed')}
              className={putType === 'fixed' ? 'active' : ''}
            >
              <Tag size={32} />
              <h4>Fixed Price</h4>
            </div>

            {item.standard !== NFT_STANDARDS.spore && (
              <div
                onClick={() => setPutType('timed')}
                className={putType === 'timed' ? 'active' : ''}
              >
                <AccessTimeFilled size={36} />
                <h4>Timed Auction</h4>
              </div>
            )}
          </div>
        </div>

        {item?.image && item.image?.startsWith('ckbfs') && (
          <p style={{ color: 'red', textAlign: 'center' }}>
            <small>
              <strong>
                CKBFS File Cells are separate from Spore Cells and are currently
                kept with the existing owner until bundling is implemented.
              </strong>
            </small>
          </p>
        )}

        {putType !== '' && (
          <>
            <div className="modal-body">
              <div className="field">
                <label>
                  {putType === 'timed' ? 'Minimum bid' : 'Price'} (
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                  )
                </label>
                <input
                  type="number"
                  step="0.1"
                  min="0"
                  max="999999999999999"
                  placeholder={putType === 'timed' ? 'Minimum bid' : 'Price'}
                  onChange={(event) => onSetPrice(event.target.value)}
                  value={prettyDecimalFormatFixedPrice}
                  autoFocus

                  // onKeyPress={(event) => {
                  //   if (!/^[0-9]*(\.[0-9]{0,18})?$/.test(fixedPrice + event.key)) {
                  //     event.preventDefault()
                  //   }
                  // }}
                />
              </div>

              <div className="field">
                <label>{`Amount (max: ${ownedAmount.toString()})${item?.standard === NFT_STANDARDS.erc1155 || item?.collectionData?.standard === NFT_STANDARDS.erc1155 ? '*' : ''}`}</label>
                <input
                  type="number"
                  step="1"
                  min="0"
                  placeholder="Enter Amount"
                  onChange={(event) =>
                    fixAmountValidator(event.target.value, ownedAmount)
                  }
                  value={amount.toString()}
                  disabled={ownedAmount < 2n}
                  onKeyPress={(event) => {
                    if (!/^[0-9]*?$/.test(amount + event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>

              {putType === 'timed' && (
                <>
                  <div className="field date-wrapper">
                    <div className="date">
                      <label>Starting Date</label>
                      <select
                        name="starting_date"
                        defaultValue={startType}
                        onChange={(event) => setStartType(event.target.value)}
                      >
                        <option value="now">Right after listing</option>
                        <option value="specific">Pick specific date</option>
                      </select>
                      {startType === 'specific' && (
                        <DatePicker
                          selected={startDate}
                          onChange={(value) => setStartDate(value)}
                          className="input-picker"
                          showTimeSelect
                          dateFormat="Pp"
                        />
                      )}
                    </div>
                    <div className="date">
                      <label>Expiration Date</label>
                      <select
                        name="expiration_date"
                        defaultValue={endType}
                        onChange={(event) => setEndType(event.target.value)}
                      >
                        <option value="1">1 day</option>
                        <option value="3">3 days</option>
                        <option value="5">5 days</option>
                        <option value="7">7 days</option>
                        <option value="specific">Pick specific date</option>
                      </select>
                      {endType === 'specific' && (
                        <DatePicker
                          selected={endDate}
                          onChange={(value) => setEndDate(value)}
                          className="input-picker"
                          showTimeSelect
                          dateFormat="Pp"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}

              <dl className="listing-details">
                {item?.chain === CHAIN_KEYS.ckb && item?.capacity && (
                  <>
                    <dt>CKB Capacity in Cell:</dt>{' '}
                    <dd>{prettyCommaFormat(item.capacity, 8)} CKB</dd>
                    <dt>Listing Fee***:</dt>{' '}
                    <dd>
                      {prettyCommaFormat(
                        getSporeListPackagePrice(
                          user.addresses.findLast(
                            (addressObject: any) =>
                              addressObject.chain === CHAIN_KEYS.ckb,
                          )?.address,
                        ),
                        decimals,
                      )}{' '}
                      CKB
                    </dd>
                  </>
                )}
                <dt>Listing Price:</dt>{' '}
                <dd>
                  {prettyCommaFormat(price.toString(), decimals)}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </dd>
                {/* Bring back to CKB with new contract */}
                {item?.chain !== CHAIN_KEYS.ckb && (
                  <>
                    <dt>Item Royalties:</dt>{' '}
                    <dd>
                      {prettyCommaFormat(itemRoyalties, decimals)}{' '}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </dd>
                  </>
                )}
                {item?.chain !== CHAIN_KEYS.ckb && collectionRoyalties > 0n && (
                  <>
                    <dt>Collection Royalties:</dt>{' '}
                    <dd>
                      {prettyCommaFormat(collectionRoyalties, decimals)}{' '}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </dd>
                  </>
                )}
                {item?.chain !== CHAIN_KEYS.ckb && (
                  <>
                    <dt>Dev Fee (2.5%):</dt>{' '}
                    <dd>
                      {prettyCommaFormat((price * 250n) / 10_000n, decimals)}{' '}
                      {item?.chain === CHAIN_KEYS.ckb
                        ? SYMBOL_KEYS.ckb
                        : SYMBOL_KEYS.godwoken}
                    </dd>
                  </>
                )}
                <hr />
                <dt>Total from sale**:</dt>{' '}
                <dd>
                  {prettyCommaFormat(calculatedTotalReturns, decimals)}{' '}
                  {item?.chain === CHAIN_KEYS.ckb
                    ? SYMBOL_KEYS.ckb
                    : SYMBOL_KEYS.godwoken}
                </dd>
              </dl>
            </div>

            <div className="modal-footer">
              <div className="disclaimers">
                {(item?.standard === NFT_STANDARDS.erc1155 ||
                  item?.collectionData?.standard === NFT_STANDARDS.erc1155) && (
                  <p>
                    *Total amount priced together{' '}
                    <small>(eg: Amount = 5; The price is for all 5)</small>
                  </p>
                )}
                <p>** All Royalties deducted, including any you may receive</p>
                {item?.chain === CHAIN_KEYS.ckb && (
                  <p>
                    *** CKB is required to facilitate holding the Digital Asset
                    with a custom Lock on CKB and is given to the Development
                    Team upon a sale or refunded upon a cancelation.
                  </p>
                )}
              </div>

              {!!warning && <p className="warning">{warning}</p>}

              <div className="button-wrapper">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => onRequestClose()}
                >
                  Cancel
                </button>
                {listingStatus || creatingAuctionStatus || approving ? (
                  <button className="btn branded">
                    <CircularProgress
                      style={{ width: '16px', height: '16px', color: 'white' }}
                    />
                  </button>
                ) : (
                  renderListButton
                )}
              </div>

              {item?.standard === NFT_STANDARDS.erc1155 && (
                <>
                  <p className="m-0 mt-2">
                    There may be <strong>2 Transactions</strong> for listings:
                  </p>

                  <ul>
                    <li>
                      <em>1. Approve</em>
                    </li>{' '}
                    <li>
                      <em>2. Confirm</em>
                    </li>
                  </ul>

                  <p className="m-0">
                    The first TX grants the Market access to receive the NFT,
                    while the second TX places your NFT into a Sale or Auction.
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default MarketModal
