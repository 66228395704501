import ReactDOM from 'react-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { AuthProvider } from './context/authContext'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { NETWORK_ID } from './utils/constants'
import TagManager from 'react-gtm-module'

import { connectors } from './utils/connectors'
import { Connector } from '@web3-react/types'

// TagManager.initialize({
//   gtmId: 'GTM-NXR483Z9',
//   preview: NETWORK_ID === 71402 ? 'live' : 'stage'
// })

ReactDOM.render(
  <Web3ReactProvider
    connectors={connectors.map((connector) => [
      connector.connection as Connector,
      connector.hooks,
    ])}
  >
    <AuthProvider>
      <App />
    </AuthProvider>
  </Web3ReactProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
