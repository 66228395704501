import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import approvedBadge from '../../assets/img/approvedBadge.svg'
import {
  DEAD_ADDRESS,
  DEFAULT_USER_NAME,
  HIDDEN_ADDRESSES,
} from '../../utils/constants'
import MediaWrapper from '../Media/MediaWrapper'

import './author-avatar.scss'

const AuthorAvatar = (props) => {
  const {
    author,
    className,
    collection,
    displayName,
    displayImage,
    src,
    style,
  } = props

  const uriBase = useMemo(() => {
    if (collection) return '/collection'

    return '/account'
  }, [])

  if (!author) return null
  if (HIDDEN_ADDRESSES.includes(author?.address)) return null

  return (
    <Link
      className={`author ${className ?? ''} ${displayImage !== false ? 'has-image' : ''}`}
      to={`${uriBase}/${author.address}`}
    >
      <span className="image-wrapper">
        {displayImage !== false && (
          <MediaWrapper src={src ?? author?.profilePic} alt="" style={style} />
        )}
        {author.isApproved && (
          <img className="verified" src={approvedBadge} alt="approved" />
        )}
      </span>
      {displayName !== false && (
        <span className="author-name">
          {author.address?.toLowerCase() === DEAD_ADDRESS
            ? 'BURNED'
            : (author?.name ?? DEFAULT_USER_NAME)}
        </span>
      )}
    </Link>
  )
}

export default AuthorAvatar
