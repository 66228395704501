import React, { useEffect, useState } from 'react'
import axios from 'axios'
import NFT from '../Item/NftCard'
import { ACTIVITY_CONSTANTS } from '../../utils/constants'

const Auctions = (props) => {
  const { isHome } = props
  const [items, setItems] = useState([])
  const [noItems, setNoItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    setLoading(true)

    axios
      .get(
        `/items/?page=${page}&pageLimit=8&type=${ACTIVITY_CONSTANTS.AuctionCreated}`,
      )
      .then((res) => {
        const mappedItems =
          res.data?.items?.map((item) => ({
            ...item,
            ...item.activities[0],
          })) ?? []

        if (page === 1) setItems(mappedItems)
        else setItems((prevItems) => [...prevItems, ...mappedItems])

        if (!res.data.items.length || res.data.final) setNoItems(true)

        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setItems((prevItems) => [...prevItems])
      })
      .finally(() => setLoading(false))
  }, [page])

  function loadMore() {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  return (
    <section className="live-auctions-area">
      {items?.length > 0 ? (
        <article className="container">
          {isHome && (
            <div className="intro">
              <div className="intro-content">
                <span>Auctions</span>
                <h3 className="mt-1 mb-2">Live Auctions</h3>
              </div>
              <div className="intro-btn mb-2">
                <a
                  className="btn content-btn text-left"
                  href="/explore/live-auctions"
                >
                  View All <i className="fa-solid fa-caret-right" />
                </a>
              </div>
            </div>
          )}
          <div className="grid items">
            {items.map((item, idx) => (
              <NFT
                {...props}
                item={item}
                key={`auct_${idx}`}
                displayPrice={true}
              />
            ))}
          </div>
          {!isHome && (
            <div
              className="load-more"
              style={{ display: noItems ? 'none' : '', textAlign: 'center' }}
            >
              <button
                onClick={() => loadMore()}
                className="btn branded mt-3 mt-sm-4"
              >
                {loading ? 'Loading...' : 'Load more'}
              </button>
            </div>
          )}
        </article>
      ) : !isHome ? (
        <article className="text-center mb-4">
          <p>{!loading ? 'No Live Auctions Found' : 'Loading...'}</p>
        </article>
      ) : null}
    </section>
  )
}

export default Auctions
