import React from 'react'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import CollectionsComponent from '../components/Collections/List'

const Collections = (props) => {
  return (
    <div>
      <Breadcrumb
        {...props}
        title="Explore Collections"
        subpage="Explore"
        page="Explore Style 3"
      />
      <CollectionsComponent showMore={true} />
    </div>
  )
}

export default Collections
