import React from 'react'

const Scrollup = () => {
  return (
    <div id="scroll-to-top" className="scroll-to-top">
      <a className="smooth-anchor">
        <i className="fa-solid fa-arrow-up" />
      </a>
    </div>
  )
}

export default Scrollup
