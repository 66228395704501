import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../context/authContext'
import axios from 'axios'
import DOMPurify from 'dompurify'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'

import NFT from '../components/Item/NftCard'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import TransferModal from '../components/Modal/Transfer'
import Analytics from '../components/Analytics/Analytics'
import SaveNft from '../components/Item/Save'
import MediaWrapper from '../components/Media/MediaWrapper'
import { CHAIN_KEYS } from '../utils/constants'

const CollectionDetails = (props: any) => {
  const { collection: collectionAddress } = useParams<{ collection: string }>()
  const { state } = useAuth()
  const { user } = state

  const [items, setItems] = useState([])
  const [noMoreItems, setNoMoreItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialItemsLoaded, setInitialItemsLoaded] = useState(true)
  const [page, setPage] = useState(1)
  const [collectionName, setCollectionName] = useState('')
  const [collectionOwner, setCollectionOwner] = useState('')
  const [collectionBanner, setCollectionBanner] = useState('')
  const [collection, setCollection] = useState<{ [key: string]: any } | null>(
    null,
  )
  const [featured, setFeatured] = useState(false)

  const renderDescription = useMemo(() => {
    if (!collection?.description) return null

    let description = collection?.description
    let parsedDescription

    // Attempt to parse the description as JSON
    try {
      parsedDescription = JSON.parse(description)
    } catch (err) {
      // If parsing fails, it's not a JSON string
      parsedDescription = null
    }

    // If parsedDescription is an object and has a description key, use it
    if (parsedDescription && parsedDescription.description) {
      description = parsedDescription.description
    }

    // Render the description
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description.replace(/\r\n/g, '<br>')),
        }}
      ></p>
    )
  }, [collection])

  useEffect(() => {
    if (initialItemsLoaded) setPage(1)

    setLoading(true)

    axios
      .get(`/collection/${collectionAddress}?page=${page}`)
      .then((result) => {
        let collection = result.data.collection

        if (collection?.adminOverwrites) {
          collection = {
            ...collection,
            ...collection.adminOverwrites,
          }
        }

        if (collection.isHidden) {
          setCollectionName('Collection No Longer Available')
          setLoading(false)
          setNoMoreItems(true)
          return
        }

        setCollection(collection)
        setCollectionName(collection?.name ?? '')
        setCollectionOwner(collection?.owner?.address ?? '')
        setCollectionBanner(collection?.bannerImage ?? '')
        setFeatured(collection?.isFeatured ?? false)

        if (collection?.items && page === 1) setItems(collection.items)
        else if (collection?.items) setItems(items.concat(collection.items))

        if (!collection.items?.length || result.data?.final) {
          setNoMoreItems(true)
          return
        }

        setLoading(false)
      })
      .catch((e) => {
        console.error('Error fetching Collection', e)
        setLoading(false)
        setItems([...items])
        setNoMoreItems(true)
      })
  }, [page, user])

  function loadMore() {
    if (!loading) {
      setInitialItemsLoaded(false)
      setPage(page + 1)
    }
  }

  return (
    <>
      <section id="CollectionDetails" className="collection-details">
        {collection?.bannerImage ? (
          <MediaWrapper
            src={collection.bannerImage}
            className="banner"
            alt={`${collectionName} on imagiNation by Nervos Nation built on Nervos CKB`}
          />
        ) : (
          <Breadcrumb
            {...props}
            // title={collectionName}
            // itemData={collection}
            background={collectionBanner}
          />
        )}

        <div className="container">
          {collectionAddress &&
            collectionOwner &&
            collectionOwner?.toLowerCase() === user?.address?.toLowerCase() && (
              <div className="userControls">
                <button
                  className="btn btn-primary mb-2"
                  data-toggle="modal"
                  data-target="#transfer-modal"
                  data-item={JSON.stringify(collection)}
                >
                  Transfer Collection
                </button>

                <a
                  href={`/create?collectionAddress=${collection?.address}`}
                  className="btn btn-success mb-2"
                >
                  Add Item to Collection
                </a>

                {collection?.chain !== CHAIN_KEYS.ckb && (
                  <>
                    <button
                      className="btn btn-warn mb-2"
                      data-toggle="modal"
                      data-target="#transfer-modal"
                      data-item={JSON.stringify(collection)}
                      data-burn="true"
                    >
                      Burn Collection
                    </button>

                    <a
                      href={`/update-collection/${collection?.address}`}
                      className="btn btn-danger mb-2"
                      style={{ border: '2px solid var(--tertiary-color)' }}
                    >
                      Modify Collection
                    </a>
                  </>
                )}
              </div>
            )}

          <div
            className={`details ${collection?.image ? 'has-image' : ''}`.trimEnd()}
          >
            <div className="title-wrapper">
              {featured && (
                <span className="featured-flag">
                  <StarTwoToneIcon /> <span>Featured</span>
                </span>
              )}

              <h1 className="title">{collectionName}</h1>
              <SaveNft {...props} data={collection} />
            </div>

            {collection?.image && (
              <MediaWrapper
                src={collection?.image}
                alt={`${collectionName} on imagiNation by Nervos Nation built on Nervos CKB`}
              />
            )}

            {renderDescription}

            <div className="socials">
              <h5>Share:</h5>
              <a
                href={`https://x.com/intent/tweet?url=${window.location.href}&text=Check out this Digital Collection ${collection?.name ?? ''} on @imagiNation_mkt!`}
                target="_blank"
                rel="noopener"
              >
                <svg
                  style={{ width: '25px', height: '25px', color: '#000' }}
                  width="1200"
                  height="1227"
                  viewBox="0 0 1200 1227"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="#000"
                  />
                </svg>
              </a>
            </div>

            <Analytics data={collection} />
          </div>

          <h2 className="text-center" style={{ color: '#000' }}>
            Collection Items
          </h2>

          <div className="grid items explore-items" id="account_owned">
            {items && items.length > 0 ? (
              items.map((item, index) => (
                <NFT
                  {...props}
                  item={item}
                  key={index}
                  className="item"
                  displayPrice={true}
                />
              ))
            ) : (
              <p className="mb-3">No Items in Collection</p>
            )}
          </div>

          <div
            className="load-more"
            style={{ display: noMoreItems ? 'none' : '', textAlign: 'center' }}
          >
            <button
              onClick={() => loadMore()}
              className="btn branded mt-3 mt-sm-4"
            >
              {loading ? 'Loading...' : 'Load more'}
            </button>
          </div>
        </div>
      </section>

      <TransferModal
        afterTransferCallback={(newOwnerAddress) =>
          setCollectionOwner(newOwnerAddress || collectionOwner)
        }
      />
    </>
  )
}

export default CollectionDetails
